import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Redirect } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import DashBoardGraphs from "./DashBoardGraphs";
import DashboardCabs from "./DashboardCabs";
import International from "./International";
import AllCabs from "./AllCabs";
import AllSiteSeen from "./AllSiteSeen";
import AddSiteSeen from "./AddSiteSeen";
import CabCreationForm from "./CabCreationForm";
import CabOrder from "./CabOrder";
import ViewOrder from "./ViewOrder";
import useMediaQuery from "@mui/material/useMediaQuery";
import CabPool from "./CabPool";
import AllPoolCab from "./AllPoolCab";
import RateModifier from "./controlEntry/rateModifier/RateModifier";
import AirportPageAdmin from "./controlEntry/rateModifier/AirRateModifire";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, ListItem, Divider, List, ListItemText } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ViewCab from "./ViewCabAdmin/ViewCab";
import OnwayScreen from "./controlEntry/rateModifier/OnWayModifier";
import AirportEdit from "./controlEntry/rateModifier/AirportEdit";
import OneWaymodifier from "./controlEntry/rateModifier/OneWaymodifier";
import AirportCabAll from "./AirportCab";
import OnewayCab from "./OnewayCab";
import ViewCabAirAndOneway from "./ViewCabAdmin/ViewCabAirAndOneway";

const DashboardScreen = () => {
  const [currentPage, setcurrentPage] = useState("Dashboard");
  const [drawerOpen, setdrawer] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:1264px)");

  useEffect(() => {
    let paramName = location.pathname.split("/");

    if (location.pathname == "/dashboard/allcabs") {
      setcurrentPage("All Outstation & Hourly Cabs");
    } else if (location.pathname == "/dashboard/reports") {
      setcurrentPage("Report");
    } else if (location.pathname == "/dashboard/cabs") {
      setcurrentPage("Add New Cab");
    } else if (location.pathname == "/dashboard/allsiteseen") {
      setcurrentPage(" All Sightseeing");
    } else if (location.pathname == "/dashboard/addsiteseen") {
      setcurrentPage("Add Sightseeing");
    } else if (location.pathname == "/dashboard/caborder") {
      setcurrentPage("All Order");
    } else if (location.pathname == "/dashboard/Cabpool") {
      setcurrentPage("Cab Master");
    } else if (location.pathname == "/dashboard/allpoolcab") {
      setcurrentPage("Cab Master List");
    } else if (location.pathname == "/dashboard/airpost-rateModifier") {
      setcurrentPage("Cab Charges Master Airport");
    } else if (location.pathname == "/dashboard/RateModifier") {
      setcurrentPage("Cab Charges Master Outstation/Hourly");
    } else if (location.pathname == "/dashboard/oneway-rateModifier") {
      setcurrentPage("Cab Charges Master One Way");
    } else if (location.pathname == "/dashboard/airport-cab") {
      setcurrentPage("All Airport Cabs");
    } else if (location.pathname == "/dashboard/oneway-cabs") {
      setcurrentPage("All One Way Cabs");
    } else if (location.pathname == "/dashboard") {
      navigate("/dashboard/allcabs");
      setcurrentPage("Dashboard");
    } else if (paramName[2] == "RateModifier" && paramName[3]) {
      setcurrentPage("Cab Charges Master  Edit Cab ");
    } else if (paramName[2] == "vieworder" && paramName[3]) {
      setcurrentPage("View Order");
    } else if (paramName[2] == "view-cab" && paramName[3]) {
      setcurrentPage("View Cab");
    } else if (paramName[2] == "view-cab-air-oneway" && paramName[3]) {
      setcurrentPage("View Cab");
    } else if (paramName[2] == "aiport-edit" && paramName[3]) {
      setcurrentPage("Cab Charges Master  Edit Airport Cab");
    } else if (paramName[2] == "oneway-edit" && paramName[3]) {
      setcurrentPage("Cab Charges Master  Edit One Way Cab");
    }
  }, [location]);

  const toggleDrawer = () => {
    setdrawer(!drawerOpen);
  };

  return (
    <>
      <div className="dashboard">
        {matches ? (
          <>
            {" "}
            <div className="navbar__ghost">
              <MenuIcon
                sx={{
                  color: "white",
                }}
                className="svg_icons"
                onClick={() => {
                  toggleDrawer();
                }}
              />
            </div>
            <Drawer anchor="left" openSecondary={true} open={drawerOpen}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-label="mailbox folders"
              >
                <ListItem className="minusdiv">
                  <HighlightOffIcon
                    className="svg_iconsminus"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  />
                </ListItem>
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/allcabs"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      All Out & Hourly Cabs
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/airport-cab"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      All Airport Cabs
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/oneway-cab"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      All One Way Cabs
                    </h3>
                  </Link>
                </ListItem>
                {/* <Divider light />
                <ListItem>
                  {" "}
                  <Link
                    to="/dashboard/cabs"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      {" "}
                      Add Cab
                    </h3>
                  </Link>
                </ListItem> */}
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/allsiteseen"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      {" "}
                      All Sightseeing
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  {" "}
                  <Link
                    to="/dashboard/addsiteseen"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      {" "}
                      Add Sightseeing
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/caborder"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      {" "}
                      Order
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/Cabpool"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      Cab Master
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/allpoolcab"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      Cab Master List
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  {" "}
                  <Link
                    to="/dashboard/RateModifier"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      Cab Charges Master Outstation/Hourly
                    </h3>
                  </Link>
                </ListItem>
                <Divider light />
                <ListItem>
                  <Link
                    to="/dashboard/airpost-rateModifier"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      Cab Charges Master Airport
                    </h3>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/dashboard/oneway-rateModifier"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h3 className="h3 h3--2" style={{ color: "black" }}>
                      Cab Charges Master Onway
                    </h3>
                  </Link>
                </ListItem>
              </List>
            </Drawer>
          </>
        ) : (
          <>
            <div
              style={{
                padding: "0px",
                background: "#001d3d",
              }}
            >
              <div
                className="navbar"
                style={{
                  position: "sticky",
                  top: "100px",
                  height: "300px",
                }}
              >
                <div
                  className="navbar__info"
                  style={{
                    position: "relative",
                    top: "13px",
                  }}
                >
                  <div className="navbar__image">
                    <img
                      src="https://cdn4.vectorstock.com/i/1000x1000/07/33/taxi-driver-avatar-character-vector-12750733.jpg"
                      alt="user"
                    />
                  </div>

                  <h3 className="h3 h3--2">
                    Hi , {JSON.parse(localStorage.getItem("userData")).name}
                  </h3>
                </div>

                <div
                  className="navbar__container"
                  style={{
                    position: "relative",
                    top: "0px",
                    marginLeft: "30px",
                  }}
                >
                  {/* <Link to="/dashboard/reports">
              <h3 className="h3 h3--2">Reports</h3>
            </Link> */}
                  <Link to="/dashboard/allcabs">
                    <h3 className="h3 h3--2"> All Out & Hourly Cabs</h3>
                  </Link>
                  <Link to="/dashboard/airport-cab">
                    <h3 className="h3 h3--2"> All Airport Cabs</h3>
                  </Link>
                  <Link to="/dashboard/oneway-cabs">
                    <h3 className="h3 h3--2"> All One Way Cabs</h3>
                  </Link>
                  {/* <Link to="/dashboard/cabs">
                    <h3 className="h3 h3--2"> Add Cab</h3>
                  </Link> */}
                  <Link to="/dashboard/allsiteseen">
                    <h3 className="h3 h3--2"> All Sightseeing </h3>
                  </Link>
                  <Link to="/dashboard/addsiteseen">
                    <h3 className="h3 h3--2"> Add Sightseeing</h3>
                  </Link>
                  <Link to="/dashboard/caborder">
                    <h3 className="h3 h3--2"> Order</h3>
                  </Link>
                  <Link to="/dashboard/Cabpool">
                    <h3 className="h3 h3--2">Cab Master</h3>
                  </Link>
                  <Link to="/dashboard/allpoolcab">
                    <h3 className="h3 h3--2">Cab Master List</h3>
                  </Link>
                  <Link to="/dashboard/RateModifier">
                    <h3 className="h3 h3--2">
                      Cab Charges Master Outstation/Hourly
                    </h3>
                  </Link>
                  <Link to="/dashboard/airpost-rateModifier">
                    <h3 className="h3 h3--2">Cab Charges Master Airport</h3>
                  </Link>
                  <Link to="/dashboard/oneway-rateModifier">
                    <h3 className="h3 h3--2">Cab Charges Master Onway</h3>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="navbar__ghost"></div>

        <div className="data">
          <div className="data__title">
            <h2 className="h2 h2--1">{currentPage}</h2>

            <Routes>
              <Route path="/reports" element={<DashBoardGraphs />} />
              <Route path="/allcabs" element={<AllCabs />} />
              <Route path="/cabs" element={<CabCreationForm />} />
              <Route path="/allsiteseen" element={<AllSiteSeen />} />
              <Route path="/addsiteseen" element={<AddSiteSeen />} />
              <Route path="/caborder" element={<CabOrder />} />
              <Route path="/vieworder/:id" element={<ViewOrder />} />
              <Route path="/cabpool" element={<CabPool />} />
              <Route path="/AllPoolCab" element={<AllPoolCab />} />
              <Route path="/RateModifier" element={<RateModifier />} />
              <Route path="/aiport-edit/:id" element={<AirportEdit />} />
              <Route path="/oneway-edit/:id" element={<OneWaymodifier />} />
              <Route path="/airport-cab" element={<AirportCabAll />} />
              <Route path="/oneway-cabs" element={<OnewayCab />} />
              <Route
                path="/airpost-rateModifier"
                element={<AirportPageAdmin />}
              />
              <Route path="/oneway-rateModifier" element={<OnwayScreen />} />
              <Route path="/RateModifier/:id" element={<RateModifier />} />
              <Route path="/view-cab/:id" element={<ViewCab />} />
              <Route
                path="/view-cab-air-oneway/:id"
                element={<ViewCabAirAndOneway />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardScreen;
