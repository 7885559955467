import "./RateModifier.css";
import React, { useState, useEffect } from "react";
import { RichTextEditor } from "@mantine/rte";
import indianCitiesDatabase from "indian-cities-database";
import pmlAPI from "../../../api/pmlAPI";
import AirportData from "../../airport";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import airportlist from "../df";
import airportJson from "../airportJson";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
const RateModifier = () => {
  const navigate = useNavigate();
  const citess = indianCitiesDatabase.cities;

  let allstate = [
    {
      abbreviation: "nn",
      name: "Please Select State",
    },
    {
      abbreviation: "AN",
      name: "Andaman and Nicobar Islands",
    },
    {
      abbreviation: "AP",
      name: "Andhra Pradesh",
    },
    {
      abbreviation: "AR",
      name: "Arunachal Pradesh",
    },
    {
      abbreviation: "AS",
      name: "Assam",
    },
    {
      abbreviation: "BR",
      name: "Bihar",
    },
    {
      abbreviation: "CG",
      name: "Chandigarh",
    },
    {
      abbreviation: "CH",
      name: "Chhattisgarh",
    },
    {
      abbreviation: "DH",
      name: "Dadra and Nagar Haveli",
    },
    {
      abbreviation: "DD",
      name: "Daman and Diu",
    },
    {
      abbreviation: "DL",
      name: "Delhi",
    },
    {
      abbreviation: "GA",
      name: "Goa",
    },
    {
      abbreviation: "GJ",
      name: "Gujarat",
    },
    {
      abbreviation: "HR",
      name: "Haryana",
    },
    {
      abbreviation: "HP",
      name: "Himachal Pradesh",
    },
    {
      abbreviation: "JK",
      name: "Jammu and Kashmir",
    },
    {
      abbreviation: "JH",
      name: "Jharkhand",
    },
    {
      abbreviation: "KA",
      name: "Karnataka",
    },
    {
      abbreviation: "KL",
      name: "Kerala",
    },
    {
      abbreviation: "LD",
      name: "Lakshadweep",
    },
    {
      abbreviation: "MP",
      name: "Madhya Pradesh",
    },
    {
      abbreviation: "MH",
      name: "Maharashtra",
    },
    {
      abbreviation: "MN",
      name: "Manipur",
    },
    {
      abbreviation: "ML",
      name: "Meghalaya",
    },
    {
      abbreviation: "MZ",
      name: "Mizoram",
    },
    {
      abbreviation: "NL",
      name: "Nagaland",
    },
    {
      abbreviation: "OR",
      name: "Odisha",
    },
    {
      abbreviation: "PY",
      name: "Puducherry",
    },
    {
      abbreviation: "PB",
      name: "Punjab",
    },
    {
      abbreviation: "RJ",
      name: "Rajasthan",
    },
    {
      abbreviation: "SK",
      name: "Sikkim",
    },
    {
      abbreviation: "TN",
      name: "Tamil Nadu",
    },
    {
      abbreviation: "TS",
      name: "Telangana",
    },
    {
      abbreviation: "TR",
      name: "Tripura",
    },
    {
      abbreviation: "UP",
      name: "Uttar Pradesh",
    },
    {
      abbreviation: "UK",
      name: "Uttarakhand",
    },
    {
      abbreviation: "WB",
      name: "West Bengal",
    },
  ];

  const [cabData, setcabData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).id,
    carModel: "",
    luggage: "",
    seats: "",
    cabImage: "",
    region: "",
    city: "",
    fule_type: "",
    cab_type: "",
    airport: true,
    airportdata: {},
  });

  const [addAirport, setAddAirort] = useState({
    airType: "pick",
    base_fare: "",
    gst: "",
    term_and_condition: "",
    cancellation: "",
    pickUp_location: "",
    dropOff_location: "",
    state: "",
    city: "",
  });

  const [citeies, setciteies] = useState([]);
  const [AllCabPool, setAllCabPool] = useState([]);
  const [cabName, setCabName] = useState([]);
  const [airportState, setAirPortState] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [airType, setAirtype] = useState("pick");
  const [pickLocation, setPickLocation] = useState({
    pickUp_location: "",
    dropOff_location: "",
    state: "",
    city: "",
  });
  const [dropoffLocation, setdropOffLocation] = useState({
    pickUp_location: "",
    dropOff_location: "",
    state: "",
    city: "",
  });

  useEffect(() => {
    let data = citess?.filter((w) => {
      return (
        w.state == pickLocation?.state || w.state == dropoffLocation?.state
      );
    });

    if (data?.length > 0) {
      let addvalue = {
        city: "Please Select City ",
        state: "Please Select City",
      };
      data?.unshift(addvalue);
    }

    setciteies(data);
  }, [pickLocation, dropoffLocation]);

  useEffect(() => {
    pmlAPI
      .get(`/api/cabpool`)
      .then((res) => {
        setAllCabPool(res.data);
        let selectData = res?.data?.map((w) => {
          return {
            carName: w?.carModel,
            id: w?._id,
          };
        });

        setCabName(selectData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const SelectCarModal = (id) => {
    let singleCabFormPool = AllCabPool?.filter((value) => {
      return value._id == id;
    });
    let singleData = singleCabFormPool[0];

    setcabData({
      ...cabData,
      carModel: singleData?.carModel,
      luggage: singleData?.luggage,
      seats: singleData?.seats,
      cabImage: singleData?.cabImage,
      fule_type: singleData?.fule_type,
      cab_type: singleData?.cab_type,
    });
  };

  const MainCheckBox = (e) => {
    const { name, checked } = e.target;
    setcabData({
      ...cabData,
      [name]: checked,
    });
  };

  //Aiport

  const SingleFiledAirport = (e) => {
    const { name, value } = e.target;
    setAddAirort({
      ...addAirport,
      [name]: value,
    });
  };

  useEffect(() => {
    let value = AirportData?.filter((w) => {
      return w?.country == "India";
    }).map((e, i) => {
      return {
        id: i,
        city: e?.city,
        name: e?.name,
        _geoloc: e?._geoloc,
        iata_code: e?.iata_code,
        value: ` ${e?.name} ${e?.city}`,
      };
    });

    setAirPortState(value);
  }, []);

  const handleOnSearch = (string, results) => {
    //console.log({ string }, { results });
  };

  const handleOnSelect = (item) => {
    //  localStorage.setItem("AirPort", JSON.stringify(item));
    //  setairportPickup(item);
  };

  const downloadFile = async () => {
    console.log(dataArray);
  };
  ///// handle Sweet Alert
  const handleSweeyAlert = (title, text, icon) => {
    swal({
      title: title,
      text: text,
      icon: icon,
    });
  };
  const pickSubButton = () => {
    let dataPush = {
      airType: airType,
      base_fare: "",
      gst: "",
      term_and_condition: "",
      cancellation: "",
      pickUp_location: pickLocation?.pickUp_location,
      dropOff_location: pickLocation?.dropOff_location,
      state: pickLocation?.state,
      city: pickLocation?.city,
    };

    if (
      pickLocation?.pickUp_location !== "" &&
      pickLocation?.dropOff_location !== ""
    ) {
      let Arrayspread = [...dataArray];
      Arrayspread.push(dataPush);

      if (
        pickLocation?.state !== "" &&
        citeies?.length > 0 &&
        pickLocation?.city == ""
      ) {
        handleSweeyAlert("Error", "Please Select Pick Up City", "error");
      } else {
        setDataArray(Arrayspread);
        setPickLocation({
          ...pickLocation,
          dropOff_location: "",
          state: "",
          city: "",
        });
      }
    } else {
      let errmassage = "";
      if (pickLocation?.pickUp_location == "") {
        errmassage = "Please Select Pick Up Airprot";
      } else {
        errmassage = "Please Select Drop off Up State";
      }
      handleSweeyAlert("Error", errmassage, "error");
    }
  };
  const pickSubButtonDrop = () => {
    let dataPush = {
      airType: airType,
      base_fare: "",
      gst: "",
      term_and_condition: "",
      cancellation: "",
      pickUp_location: dropoffLocation?.pickUp_location,
      dropOff_location: dropoffLocation?.dropOff_location,
      state: dropoffLocation?.state,
      city: dropoffLocation?.city,
    };

    if (
      dropoffLocation?.pickUp_location !== "" &&
      dropoffLocation?.dropOff_location !== ""
    ) {
      let Arrayspread = [...dataArray];
      Arrayspread.push(dataPush);

      if (
        dropoffLocation?.state !== "" &&
        citeies?.length > 0 &&
        dropoffLocation?.city == ""
      ) {
        handleSweeyAlert("Error", "Please Select Pick Up City", "error");
      } else {
        setDataArray(Arrayspread);
        setdropOffLocation({
          ...dropoffLocation,
          pickUp_location: "",
          state: "",
          city: "",
        });
      }
    } else {
      let errmassage = "";
      if (dropoffLocation?.pickUp_location == "") {
        errmassage = "Please Select Pick Up State";
      } else {
        errmassage = "Please Select Drop off Airport";
      }
      handleSweeyAlert("Error", errmassage, "error");
    }
  };

  const HandleFinlaSubmit = async () => {
    if (cabData?.carModel == "") {
      handleSweeyAlert("Error", `Please Select Cab`, "error");
    } else {
      try {
        let arraIndd = [...dataArray];
        let dataSetArray = [];
        await arraIndd?.map(async (w) => {
          cabData.city = w.pickUp_location;
          cabData.region = w.pickUp_location;
          cabData.airportdata = w;
          if (
            w?.base_fare !== "" &&
            w?.gst !== "" &&
            w?.term_and_condition !== "" &&
            w?.cancellation !== ""
          ) {
            await pmlAPI.post("/api/cabs/", cabData);
          } else {
            dataSetArray.push(w);
          }
        });

        if (dataSetArray?.length > 0) {
          handleSweeyAlert(
            "Warning",
            `Please Add Data To remain Cabs`,
            "warning"
          );
        } else {
          handleSweeyAlert("Success", `Cab Added Successfully`, "success");
        }

        setDataArray(dataSetArray);
        if (dataSetArray?.length == 0) {
          navigate("/dashboard/airport-cab");
        }
      } catch (error) {
        console.log(error);
        handleSweeyAlert("Success", `There Is Error Try again`, "success");
      }
    }
  };
  return (
    <div className="modifyWrapper">
      <div className="filter_section">
        {/* Car */}
        <div>
          <label>Car</label>
          <select
            onChange={(e) => {
              SelectCarModal(e.target.value);
            }}
          >
            <option value="none">Please Select Cab</option>;
            {cabName?.map((w) => {
              return <option value={w.id}>{w.carName}</option>;
            })}
          </select>
        </div>

        {/* State */}
        <div>
          <input
            type="radio"
            className="inp_radio_btn_chag"
            name="radio_btn"
            checked={airType == "pick" ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                setAirtype("pick");
                setciteies([]);
                setDataArray([]);
              }
            }}
          />
          <label className="airlable_main">Airport PickUp</label>
        </div>

        {/* City */}
        <div>
          <input
            type="radio"
            className="inp_radio_btn_chag"
            name="radio_btn"
            checked={airType == "drop" ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                setAirtype("drop");
                setciteies([]);
                setDataArray([]);
              }
            }}
          />
          <label className="airlable_main">Airport Drop</label>
        </div>

        {/* Button */}
        <div className="buildBtn">
          <button
            onClick={() => {
              HandleFinlaSubmit();
            }}
          >
            Build Changes
          </button>
        </div>
      </div>

      {airType == "pick" ? (
        <>
          {/* AirPort PickUp */}
          <div className="div_container_second_main">
            <div className="div_main_autocomplete">
              <ReactSearchAutocomplete
                placeholder="Search Airport By Name Or IATA Code ...."
                items={airportJson}
                fuseOptions={{
                  keys: ["city", "Name", "Type", "Code"],
                }} // Search on both fields
                resultStringKeyName="Name" // String to display in the results
                onSearch={handleOnSearch}
                value={pickLocation.pickUp_location}
                onSelect={(item) => {
                  setPickLocation({
                    ...pickLocation,
                    pickUp_location: item?.Name,
                  });
                }}
                showIcon={true}
                styling={{
                  height: "25px",
                  borderRadius: "3px",
                  backgroundColor: "white",
                  fontSize: "12px",
                  clearIconMargin: "3px 8px 0 0",
                }}
              />
            </div>

            <div></div>

            <div className="div_main_autocomplete_two">
              {/* State */}
              <div className="div_innner_state_wise">
                <label className="lable_inner_auto_comaple">State</label>
                <select
                  className="select_btn_csss_is_here"
                  value={pickLocation.state}
                  onChange={(e) => {
                    setPickLocation({
                      ...pickLocation,
                      state: e.target.value,
                      dropOff_location: e.target.value,
                    });
                  }}
                >
                  {allstate?.map((w) => {
                    return <option value={w.name}>{w.name}</option>;
                  })}
                </select>
              </div>

              {/* City */}

              {citeies?.length > 0 && (
                <div className="div_innner_state_wise">
                  <label className="lable_inner_auto_comaple">City</label>
                  <br />
                  <select
                    className="select_btn_csss_is_here"
                    value={pickLocation.city}
                    onChange={(e) => {
                      setPickLocation({
                        ...pickLocation,
                        city: e.target.value,
                        dropOff_location: `${e.target.value} ${pickLocation?.state} `,
                      });
                    }}
                  >
                    {citeies?.map((w) => {
                      return (
                        <option value={`${w.city}  ${w.state}`}>
                          {w.city}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>

            <div className="div_main_autocomplete_two">
              {/* State */}
              <div className="div_innner_button_wise">
                <button
                  className="btn_inner_class_name"
                  onClick={() => {
                    pickSubButton();
                  }}
                >
                  Add Location
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* AirPort Drop Off */}
          <div className="div_container_second_main">
            <div className="div_main_autocomplete_two">
              {/* State */}
              <div className="div_innner_state_wise">
                <label className="lable_inner_auto_comaple">State</label>
                <select
                  className="select_btn_csss_is_here"
                  value={dropoffLocation.state}
                  onChange={(e) => {
                    setdropOffLocation({
                      ...dropoffLocation,
                      state: e.target.value,
                      pickUp_location: e.target.value,
                    });
                  }}
                >
                  {allstate?.map((w) => {
                    return <option value={w.name}>{w.name}</option>;
                  })}
                </select>
              </div>
              {/* City */}
              {citeies?.length > 0 && (
                <div className="div_innner_state_wise">
                  <label className="lable_inner_auto_comaple">City</label>
                  <br />
                  <select
                    className="select_btn_csss_is_here"
                    value={dropoffLocation.city}
                    onChange={(e) => {
                      setdropOffLocation({
                        ...dropoffLocation,
                        city: e.target.value,
                        pickUp_location: `${e.target.value} ${dropoffLocation?.state} `,
                      });
                    }}
                  >
                    {citeies?.map((w) => {
                      return (
                        <option value={`${w.city}  ${w.state}`}>
                          {w.city}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div></div>
            <div className="div_main_autocomplete">
              <ReactSearchAutocomplete
                placeholder="Search Airport By Name Or IATA Code ...."
                items={airportJson}
                fuseOptions={{
                  keys: ["city", "Name", "Type", "Code"],
                }} // Search on both fields
                resultStringKeyName="Name" // String to display in the results
                onSearch={handleOnSearch}
                value={dropoffLocation?.dropOff_location}
                onSelect={(item) => {
                  setdropOffLocation({
                    ...dropoffLocation,
                    dropOff_location: item?.Name,
                  });
                }}
                showIcon={true}
                styling={{
                  height: "25px",
                  borderRadius: "3px",
                  backgroundColor: "white",
                  fontSize: "12px",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 999,
                }}
              />
            </div>

            <div className="div_main_autocomplete_two">
              {/* State */}
              <div className="div_innner_button_wise">
                <button
                  className="btn_inner_class_name"
                  onClick={pickSubButtonDrop}
                >
                  Add Location
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="name_view_div_here">
        <div className="div_for_zindex">
          {dataArray?.length > 0 && (
            <>
              {dataArray?.map((w, i) => {
                return (
                  <Accordion key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        {w?.pickUp_location} - {w?.dropOff_location}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="form_box div_form_acord_innner_color">
                          <form>
                            <div className="div_inner_main_div">
                              <div className="base_fare div_form_inner_inpt_flex ">
                                <label className="lable_css_airport_modification_basedare">
                                  Base Fare :-
                                </label>

                                <input
                                  type="number"
                                  id="basefare"
                                  name="base_fare"
                                  value={dataArray[i]?.base_fare}
                                  onChange={(e) => {
                                    let arra = [...dataArray];
                                    arra[i].base_fare = e.target.value;
                                    setDataArray(arra);
                                  }}
                                />
                              </div>

                              <div className="base_fare div_form_inner_inpt_flex">
                                <label className="lable_css_airport_modification_basedare">
                                  GST :-
                                </label>
                                <div className="inner_button_gst">
                                  <p className="percentage">% age</p>
                                </div>
                                <input
                                  type="number"
                                  id="gst"
                                  name="gst"
                                  min="0"
                                  max="100"
                                  value={dataArray[i]?.gst}
                                  onChange={(e) => {
                                    let arra = [...dataArray];
                                    arra[i].gst = e.target.value;
                                    setDataArray(arra);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="div_inner_div_for_term_cancle">
                              <div className="userUpdateItem">
                                <label className="user__label">
                                  Terms & Conditions
                                </label>

                                <RichTextEditor
                                  style={{
                                    width: "450px",
                                  }}
                                  value={dataArray[i]?.term_and_condition}
                                  controls={[
                                    ["bold", "italic", "underline"],
                                    ["unorderedList"],
                                  ]}
                                  onChange={(e) => {
                                    let arra = [...dataArray];
                                    console.log(e);
                                    arra[i].term_and_condition = e;
                                    setDataArray(arra);
                                  }}
                                />
                              </div>

                              <div className="userUpdateItem">
                                <label className="user__label">
                                  Cancellation Policy
                                </label>

                                <RichTextEditor
                                  style={{
                                    width: "450px",
                                  }}
                                  value={dataArray[i]?.cancellation}
                                  controls={[
                                    ["bold", "italic", "underline"],
                                    ["unorderedList"],
                                  ]}
                                  onChange={(e) => {
                                    let arra = [...dataArray];
                                    arra[i].cancellation = e;
                                    setDataArray(arra);
                                  }}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RateModifier;
