import React from "react";

import "./Allcss/finalPayment.css";
import jsPDF from "jspdf";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { useState, useEffect, useCallback } from "react";
import "./Allcss/finalPayment.css";
import { useParams } from "react-router-dom";
import pmlAPI from "../api/pmlAPI";
import "jspdf-autotable";
import pmlLogo from "../imges/pml_logo.png";
import html2canvas from "html2canvas";
import { nanoid } from "nanoid";
import ImgeLogo from "../imges/newlogo.svg";

const FinalPayment = () => {
  const { id, orderid } = useParams();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState([]);
  const [refresh, setrefresh] = useState();
  const [check, setCheck] = useState(2);
  const [show, setShow] = useState(false);

  useEffect(async () => {
    try {
      let getOrderDetails = await pmlAPI.get(
        `/api/payment/getorder/${orderid}`
      );
      let getallDta = await pmlAPI.get("/api/caboreder");
      let filData = getallDta?.data?.filter((e) => {
        return e._id == id;
      });

      setData(getOrderDetails?.data);
      setDetails(filData[0]);

      if (
        getOrderDetails?.data?.order_status == "PAID" &&
        filData[0].order_details?.status !== "PAID"
      ) {
        let DataValues = filData[0];

        try {
          let DataValuwwe = {
            data: DataValues?.data,
            useremail: DataValues?.useremail,
            cabid: DataValues?.cabid,
            userid: DataValues?.userid,
            cab_status: DataValues?.cab_status,
            driver_name: DataValues?.driver_name,
            cab_no: DataValues?.cab_no,
            driver_mobile_no: DataValues?.driver_mobile_no,
            order_details: {
              id: DataValues?.order_details?.id,
              amount: DataValues?.order_details?.amount,
              status: getOrderDetails?.data?.order_status,
              token: DataValues?.order_details?.token,
              link: DataValues?.order_details?.link,
            },
          };

          await pmlAPI.patch(`/api/caborder/${id}`, DataValuwwe);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [orderid, id, refresh]);

  const Refresh = () => {
    setrefresh(Math.random());
  };

  const save = () => {
    html2canvas(document.querySelector("#divToPrint")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      pdf.addImage(imgData, "PNG", -100, 20);
      pdf.save("PaymentSlip.pdf");
    });
  };

  useEffect(async () => {
    const interval = setInterval(async () => {
      setrefresh(nanoid());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-2">
            <button class="btn btn-primary" onClick={save}>
              Export To PDF
            </button>
          </div>
          <div className="col-sm-2 "></div>

          <div
            className="col-sm-2 "
            style={{ display: "flex", justifyContent: "end" }}
          >
            <button class="btn btn-dark" onClick={Refresh}>
              Refresh
            </button>
          </div>
          <div className="col-sm-2 ">
            <button
              class="btn btn-warning"
              onClick={() => {
                window.open(data?.payment_link, "_blank");
              }}
            >
              Payment Link
            </button>
          </div>
          <div className="col-sm-2 "></div>
        </div>
      </div>

      <div id="divToPrint" className="m-3">
        <div class="row d-flex justify-content-center">
          <div class="col-md-8">
            <div class="card">
              <div class="d-flex flex-row justify-content-between  p-2">
                <img
                  src="/assets/newlogo.svg"
                  alt=""
                  //className="header__logo"
                  className="imgWidth"
                />
                <div class="d-flex flex-column">
                  <span class="font-weight-bold">Order Status</span>
                  <small
                    style={
                      data?.order_status == "PAID"
                        ? { color: "green" }
                        : data.order_status == "FAILED"
                        ? { color: "red" }
                        : { color: "lightblue" }
                    }
                  >
                    {data?.order_status}
                  </small>
                </div>
              </div>

              <hr />
              <div class="table-responsive p-2">
                <table class="table table-borderless">
                  <tbody>
                    <tr class="content">
                      <td>To</td>
                      <td>From</td>
                    </tr>

                    <tr class="content">
                      <td class="font-weight-bold">
                        {details?.data?.pickUp}
                        <br />
                        {details?.data?.pickupDetailAdress}
                      </td>
                      <td class="font-weight-bold">
                        {details?.data?.dropoff}
                        <br />
                        {details?.data?.dropoffDetailAdress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
              <div class="products p-2">
                <table class="table table-borderless">
                  <tbody>
                    <tr class="content">
                      <td>Name</td>
                      <td></td>
                      <td></td>
                      <td>{details?.data?.name}</td>
                    </tr>

                    <tr class="content">
                      <td>Date</td>
                      <td></td>
                      <td></td>
                      <td>{details?.data?.date}</td>
                    </tr>
                    <tr class="content">
                      <td>Email</td>
                      <td></td>
                      <td></td>
                      <td>{details?.data?.email}</td>
                    </tr>
                    <tr class="content">
                      <td>Phone</td>
                      <td></td>
                      <td></td>
                      <td>{details?.data?.phone}</td>
                    </tr>
                    <tr class="content">
                      <td>Passanger</td>
                      <td></td>
                      <td></td>
                      <td>
                        Adults - {details?.data?.passengers?.adults}| Infants -{" "}
                        {details?.data?.passengers?.childern} | Children -{" "}
                        {details?.data?.passengers?.infants}
                      </td>
                    </tr>
                    <tr class="content">
                      <td>Order ID</td>
                      <td></td>
                      <td></td>
                      <td>{data?.order_id}</td>
                    </tr>

                    <tr class="content">
                      <td>CF Order ID</td>
                      <td></td>
                      <td></td>
                      <td>{data?.cf_order_id}</td>
                    </tr>
                    <tr class="content">
                      <td>Order Amount</td>
                      <td></td>
                      <td></td>
                      <td>
                        {details?.data?.TypeTravel == ""
                          ? data.order_amount
                          : data.order_amount}{" "}
                        Rs
                      </td>
                    </tr>
                    <tr class="content">
                      <td>Order Status</td>
                      <td></td>
                      <td></td>
                      <td
                        style={
                          data?.order_status == "PAID"
                            ? { color: "green" }
                            : data.order_status == "FAILED"
                            ? { color: "red" }
                            : { color: "lightblue" }
                        }
                      >
                        {data?.order_status}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalPayment;
