import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from "@mui/material";
import pmlAPI from "../api/pmlAPI";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import indianCitiesDatabase from "indian-cities-database";
import imageToBase64 from "image-to-base64";

const gridStyle = {
  textAlign: "center",
  paddingLeft: 2,
  paddingRight: 2,
};
const fromControlstylr = {
  width: {
    xxs: 100,
    xs: 150,
    sm: 200,
    md: 300,
    lg: 400,
    xl: 500,
    textAlign: "left",
  },
};

const theme = createTheme();
const blue = {
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
};

const CustomButtonRoot = styled("button")`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 12px 24px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${blue[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function CustomButton(props) {
  return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

const CabPoolForm = ({ setCabCreation }) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const citess = indianCitiesDatabase.cities;
  const [cabData, setcabData] = useState({
    carModel: "",
    luggage: "",
    seats: "",
    cabImage: "",
    cab_type: "",
    fule_type: "",
  });

  const handleCabFormSubmit = async (e) => {
    setloading(true);
    console.log(cabData);

    await pmlAPI
      .post(`/api/cabpool`, cabData)
      .then((res) => {
        if (res?.data) {
          setloading(false);
          navigate("/dashboard/allpoolcab");
        } else {
          swal({
            title: "Error",
            text: res.data.massage,
            icon: "error",
            dangerMode: true,
          });
          setloading(false);
        }

        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "Error",
          text: "Something Went Wrong",
          icon: "error",
          dangerMode: true,
        });
        setloading(false);
      });

    setCabCreation(false);
  };

  function encodeImageFileAsURL(element) {
    var file = element.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setcabData({
        ...cabData,
        cabImage: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }

  return (
    <>
      <br></br>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="sm">
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            <Grid item xs={12} sx={gridStyle}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="carModel"
                name="carModel"
                label="Car model..."
                type="url"
                InputLabelProps={{
                  style: { fontSize: 15, color: "black" },
                }}
                sx={{
                  width: {
                    xxs: 100,
                    xs: 150,
                    sm: 200,
                    md: 300,
                    lg: 400,
                    xl: 500,
                  },
                }}
                onChange={(e) => {
                  setcabData({
                    ...cabData,
                    carModel: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sx={gridStyle}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="number"
                id="carLuggage"
                label="Luggage space"
                name="carLuggage"
                InputLabelProps={{
                  style: { fontSize: 15, color: "black" },
                }}
                sx={{
                  width: {
                    xxs: 100,
                    xs: 150,
                    sm: 200,
                    md: 300,
                    lg: 400,
                    xl: 500,
                  },
                }}
                onChange={(e) => {
                  setcabData({
                    ...cabData,
                    luggage: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sx={gridStyle}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="carSeats"
                label="Available seats"
                name="carSeats"
                InputLabelProps={{
                  style: { fontSize: 15, color: "black" },
                }}
                sx={{
                  width: {
                    xxs: 100,
                    xs: 150,
                    sm: 200,
                    md: 300,
                    lg: 400,
                    xl: 500,
                  },
                }}
                onChange={(e) => {
                  setcabData({
                    ...cabData,
                    seats: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sx={gridStyle}>
              <FormControl sx={fromControlstylr}>
                <InputLabel sx={{ fontSize: 15 }} required id="fuel_type">
                  Cab Type
                </InputLabel>
                <Select
                  sx={fromControlstylr}
                  InputLabelProps={{
                    style: { fontSize: 15, color: "black" },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Fuel Type"
                  onChange={(e) => {
                    setcabData({
                      ...cabData,
                      cab_type: e.target.value,
                    });
                  }}
                >
                  <MenuItem sx={{ fontSize: 13 }} value="Hatchback">
                    Hatchback
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 13 }} value="Sedan">
                    Sedan
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 13 }} value="SUV">
                    SUV
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={gridStyle}>
              <FormControl sx={fromControlstylr}>
                <InputLabel sx={{ fontSize: 15 }} required id="fuel_type">
                  Fuel type
                </InputLabel>
                <Select
                  sx={fromControlstylr}
                  InputLabelProps={{
                    style: { fontSize: 15, color: "black" },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Fuel Type"
                  onChange={(e) => {
                    setcabData({
                      ...cabData,
                      fule_type: e.target.value,
                    });
                  }}
                >
                  <MenuItem sx={{ fontSize: 13 }} value="Diesel">
                    Diesel
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 13 }} value="Petrol">
                    Petrol
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 13 }} value="CNG">
                    CNG
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 13 }} value="CNG">
                    Electric
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={gridStyle}>
              <FormControl sx={fromControlstylr}>
                <InputLabel sx={{ fontSize: 15 }} required id="fuel_type">
                  Cab Image
                </InputLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="carImage"
                  name="carImage"
                  type="file"
                  variant="filled"
                  inputProps={{
                    accept:
                      "image/x-png,image/gif,image/jpeg ,image/jpg,image/png",
                  }}
                  InputLabelProps={{
                    style: { fontSize: 15, color: "black" },
                  }}
                  sx={{
                    width: {
                      xxs: 100,
                      xs: 150,
                      sm: 200,
                      md: 300,
                      lg: 400,
                      xl: 500,
                    },
                  }}
                  onChange={(e) => {
                    encodeImageFileAsURL(e);
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={gridStyle}>
              <CustomButton
                id="btn_sin"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 1,
                  fontSize: 14,
                  bgcolor: "#092348",
                  width: {
                    xxs: 100,
                    xs: 150,
                    sm: 200,
                    md: 300,
                    lg: 400,
                    xl: 500,
                  },
                }}
                onClick={() => {
                  handleCabFormSubmit();
                }}
              >
                {loading ? "Loading..." : " Add Cab"}
              </CustomButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CabPoolForm;
