import "./RateModifier.css";
import React, { useState, useEffect } from "react";
import { RichTextEditor } from "@mantine/rte";
import indianCitiesDatabase from "indian-cities-database";
import pmlAPI from "../../../api/pmlAPI";
import AirportData from "../../airport";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import airportlist from "../df";
import airportJson from "../airportJson";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";
export default function AirportEdit() {
  const citess = indianCitiesDatabase.cities;
  let { id } = useParams();
  const navigate = useNavigate();

  let allstate = [
    {
      abbreviation: "nn",
      name: "Please Select State",
    },
    {
      abbreviation: "AN",
      name: "Andaman and Nicobar Islands",
    },
    {
      abbreviation: "AP",
      name: "Andhra Pradesh",
    },
    {
      abbreviation: "AR",
      name: "Arunachal Pradesh",
    },
    {
      abbreviation: "AS",
      name: "Assam",
    },
    {
      abbreviation: "BR",
      name: "Bihar",
    },
    {
      abbreviation: "CG",
      name: "Chandigarh",
    },
    {
      abbreviation: "CH",
      name: "Chhattisgarh",
    },
    {
      abbreviation: "DH",
      name: "Dadra and Nagar Haveli",
    },
    {
      abbreviation: "DD",
      name: "Daman and Diu",
    },
    {
      abbreviation: "DL",
      name: "Delhi",
    },
    {
      abbreviation: "GA",
      name: "Goa",
    },
    {
      abbreviation: "GJ",
      name: "Gujarat",
    },
    {
      abbreviation: "HR",
      name: "Haryana",
    },
    {
      abbreviation: "HP",
      name: "Himachal Pradesh",
    },
    {
      abbreviation: "JK",
      name: "Jammu and Kashmir",
    },
    {
      abbreviation: "JH",
      name: "Jharkhand",
    },
    {
      abbreviation: "KA",
      name: "Karnataka",
    },
    {
      abbreviation: "KL",
      name: "Kerala",
    },
    {
      abbreviation: "LD",
      name: "Lakshadweep",
    },
    {
      abbreviation: "MP",
      name: "Madhya Pradesh",
    },
    {
      abbreviation: "MH",
      name: "Maharashtra",
    },
    {
      abbreviation: "MN",
      name: "Manipur",
    },
    {
      abbreviation: "ML",
      name: "Meghalaya",
    },
    {
      abbreviation: "MZ",
      name: "Mizoram",
    },
    {
      abbreviation: "NL",
      name: "Nagaland",
    },
    {
      abbreviation: "OR",
      name: "Odisha",
    },
    {
      abbreviation: "PY",
      name: "Puducherry",
    },
    {
      abbreviation: "PB",
      name: "Punjab",
    },
    {
      abbreviation: "RJ",
      name: "Rajasthan",
    },
    {
      abbreviation: "SK",
      name: "Sikkim",
    },
    {
      abbreviation: "TN",
      name: "Tamil Nadu",
    },
    {
      abbreviation: "TS",
      name: "Telangana",
    },
    {
      abbreviation: "TR",
      name: "Tripura",
    },
    {
      abbreviation: "UP",
      name: "Uttar Pradesh",
    },
    {
      abbreviation: "UK",
      name: "Uttarakhand",
    },
    {
      abbreviation: "WB",
      name: "West Bengal",
    },
  ];

  const [cabData, setcabData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).id,
    carModel: "",
    luggage: "",
    seats: "",
    cabImage: "",
    region: "",
    city: "",
    fule_type: "",
    cab_type: "",
    airport: true,
    airportdata: {},
  });

  const [addAirport, setAddAirort] = useState({
    airType: "pick",
    base_fare: "",
    gst: "",
    term_and_condition: "",
    cancellation: "",
    pickUp_location: "",
    dropOff_location: "",
    state: "",
    city: "",
  });

  const [citeies, setciteies] = useState([]);
  const [AllCabPool, setAllCabPool] = useState([]);
  const [cabName, setCabName] = useState([]);
  const [airportState, setAirPortState] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [airType, setAirtype] = useState("pick");
  const [pickLocation, setPickLocation] = useState({
    pickUp_location: "",
    dropOff_location: "",
    state: "",
    city: "",
  });
  const [dropoffLocation, setdropOffLocation] = useState({
    pickUp_location: "",
    dropOff_location: "",
    state: "",
    city: "",
  });

  useEffect(() => {
    let data = citess?.filter((w) => {
      return w.state == addAirport?.state;
    });

    if (data?.length > 0) {
      let addvalue = {
        city: "Please Select City ",
        state: "Please Select City",
      };
      data?.unshift(addvalue);
    }

    setciteies(data);
  }, [addAirport]);

  useEffect(() => {
    pmlAPI
      .get(`/api/cabpool`)
      .then((res) => {
        setAllCabPool(res.data);
        let selectData = res?.data?.map((w) => {
          return {
            carName: w?.carModel,
            id: w?._id,
          };
        });

        setCabName(selectData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const SelectCarModal = (id) => {
    let singleCabFormPool = AllCabPool?.filter((value) => {
      return value._id == id;
    });
    let singleData = singleCabFormPool[0];

    setcabData({
      ...cabData,
      carModel: singleData?.carModel,
      luggage: singleData?.luggage,
      seats: singleData?.seats,
      cabImage: singleData?.cabImage,
      fule_type: singleData?.fule_type,
      cab_type: singleData?.cab_type,
    });
  };

  useEffect(() => {
    let value = AirportData?.filter((w) => {
      return w?.country == "India";
    }).map((e, i) => {
      return {
        id: i,
        city: e?.city,
        name: e?.name,
        _geoloc: e?._geoloc,
        iata_code: e?.iata_code,
        value: ` ${e?.name} ${e?.city}`,
      };
    });

    setAirPortState(value);
  }, []);

  const handleOnSearch = (string, results) => {
    //console.log({ string }, { results });
  };
  ///// handle Sweet Alert
  const handleSweeyAlert = (title, text, icon) => {
    swal({
      title: title,
      text: text,
      icon: icon,
    });
  };

  const downloadFile = async () => {
    cabData.airportdata = addAirport;
    cabData.city = addAirport.pickUp_location;
    cabData.region = addAirport.pickUp_location;

    try {
      let res = await pmlAPI.put(`/api/cabs/${id}`, cabData);
      if (res.data?.success) {
        handleSweeyAlert("Success", res.data?.massage, "success");
        navigate("/dashboard/airport-cab");
      } else {
        handleSweeyAlert("Error", res.data?.massage, "error");
      }
    } catch (error) {
      handleSweeyAlert("Error", "There Is a Error try Again", "error");
    }
  };

  useEffect(async () => {
    try {
      let res = await pmlAPI.get(`/api/cabs/${id}`);
      console.log(res?.data?.data);

      setcabData({
        ...cabData,
        userId: res?.data?.data?.userId,
        carModel: res?.data?.data?.carModel,
        luggage: res?.data?.data?.luggage,
        seats: res?.data?.data?.seats,
        cabImage: res?.data?.data?.cabImage,
        region: res?.data?.data?.region,
        city: res?.data?.data?.city,
        fule_type: res?.data?.data?.fule_type,
        cab_type: res?.data?.data?.cab_type,
        airport: res?.data?.data?.airport,
        airportdata: {},
      });

      setAddAirort((pre) => {
        return {
          ...pre,
          airType: res?.data?.data?.airportdata?.airType,
          base_fare: res?.data?.data?.airportdata?.base_fare,
          gst: res?.data?.data?.airportdata?.gst,
          pickUp_location: res?.data?.data?.airportdata?.pickUp_location,
          dropOff_location: res?.data?.data?.airportdata?.dropOff_location,
          state: res?.data?.data?.airportdata?.state,
          city: res?.data?.data?.airportdata?.city,
        };
      });

      setAddAirort((pre) => {
        return {
          ...pre,
          term_and_condition: res?.data?.data?.airportdata?.term_and_condition,
        };
      });
      setAddAirort((pre) => {
        return {
          ...pre,
          cancellation: res?.data?.data?.airportdata?.cancellation,
        };
      });

      let data = citess?.filter((w) => {
        return w.state == res?.data?.data?.airportdata?.state;
      });

      if (data?.length > 0) {
        let addvalue = {
          city: "Please Select City ",
          state: "Please Select City",
        };
        data?.unshift(addvalue);
      }

      setciteies(data);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  return (
    <div className="modifyWrapper">
      <div className="filter_section">
        {/* Car */}
        <div>
          <label>Car</label>
          <select
            disabled={true}
            value={cabData?.carModel}
            onChange={(e) => {
              SelectCarModal(e.target.value);
            }}
          >
            <option value="none">{cabData?.carModel}</option>;
          </select>
        </div>

        {addAirport?.airType == "pick" ? (
          <div>
            <input
              type="radio"
              className="inp_radio_btn_chag"
              name="radio_btn"
              checked={addAirport?.airType == "pick" ? true : false}
              onChange={(e) => {
                if (e.target.checked) {
                  setAddAirort({
                    ...addAirport,
                    airType: "pick",
                  });
                }
              }}
            />
            <label className="airlable_main">Airport PickUp</label>
          </div>
        ) : (
          <div>
            <input
              type="radio"
              className="inp_radio_btn_chag"
              name="radio_btn"
              checked={addAirport?.airType == "drop" ? true : false}
              onChange={(e) => {
                if (e.target.checked) {
                  setAddAirort({
                    ...addAirport,
                    airType: "drop",
                  });
                }
              }}
            />
            <label className="airlable_main">Airport Drop</label>
          </div>
        )}

        {/* Button */}
        <div className="buildBtn">
          <button
            onClick={() => {
              downloadFile();
            }}
          >
            Build Changes
          </button>
        </div>
      </div>

      {addAirport?.airType == "pick" ? (
        <>
          {/* AirPort PickUp */}
          <div className="div_container_second_main">
            <div className="div_main_autocomplete">
              <label className="lable_inner_air_edit">
                {addAirport?.pickUp_location}
              </label>
              <ReactSearchAutocomplete
                placeholder="Search Airport By Name Or IATA Code ...."
                items={airportJson}
                fuseOptions={{
                  keys: ["city", "Name", "Type", "Code"],
                }} // Search on both fields
                resultStringKeyName="Name" // String to display in the results
                onSearch={handleOnSearch}
                value={addAirport.pickUp_location}
                onSelect={(item) => {
                  setAddAirort({
                    ...addAirport,
                    pickUp_location: item?.Name,
                  });
                }}
                showIcon={true}
                styling={{
                  height: "25px",
                  borderRadius: "3px",
                  backgroundColor: "white",
                  fontSize: "12px",
                  clearIconMargin: "3px 8px 0 0",
                }}
              />
            </div>

            <div></div>

            <div className="div_main_autocomplete_two">
              {/* State */}
              <div className="div_innner_state_wise">
                <label className="lable_inner_auto_comaple">State</label>
                <select
                  className="select_btn_csss_is_here"
                  value={addAirport.state}
                  onChange={(e) => {
                    setAddAirort({
                      ...addAirport,
                      state: e.target.value,
                      city: e.target.value,
                      dropOff_location: e.target.value,
                    });
                  }}
                >
                  {allstate?.map((w) => {
                    return <option value={w.name}>{w.name}</option>;
                  })}
                </select>
              </div>

              {/* City */}

              {citeies?.length > 0 && (
                <div className="div_innner_state_wise">
                  <label className="lable_inner_auto_comaple">City</label>
                  <br />
                  <select
                    className="select_btn_csss_is_here"
                    value={addAirport.city}
                    onChange={(e) => {
                      setAddAirort({
                        ...addAirport,
                        city: e.target.value,
                        dropOff_location: `${e.target.value} ${addAirport?.state} `,
                      });
                    }}
                  >
                    {citeies?.map((w) => {
                      return (
                        <option value={`${w.city}  ${w.state}`}>
                          {w.city}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {/* AirPort Drop Off */}
          <div className="div_container_second_main">
            <div className="div_main_autocomplete_two">
              {/* State */}
              <div className="div_innner_state_wise">
                <label className="lable_inner_auto_comaple">State</label>
                <select
                  className="select_btn_csss_is_here"
                  value={addAirport.state}
                  onChange={(e) => {
                    setAddAirort({
                      ...addAirport,
                      state: e.target.value,
                      city: e.target.value,
                      pickUp_location: e.target.value,
                    });
                  }}
                >
                  {allstate?.map((w) => {
                    return <option value={w.name}>{w.name}</option>;
                  })}
                </select>
              </div>
              {/* City */}
              {citeies?.length > 0 && (
                <div className="div_innner_state_wise">
                  <label className="lable_inner_auto_comaple">City</label>
                  <br />
                  <select
                    className="select_btn_csss_is_here"
                    value={addAirport.city}
                    onChange={(e) => {
                      setAddAirort({
                        ...addAirport,
                        city: e.target.value,
                        pickUp_location: e.target.value,
                      });
                    }}
                  >
                    {citeies?.map((w) => {
                      return (
                        <option value={`${w.city}  ${w.state}`}>
                          {w.city}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div></div>
            <div className="div_main_autocomplete">
              <label className="lable_inner_air_edit">
                {addAirport?.dropOff_location}
              </label>
              <ReactSearchAutocomplete
                placeholder="Search Airport By Name Or IATA Code ...."
                items={airportJson}
                fuseOptions={{
                  keys: ["city", "Name", "Type", "Code"],
                }} // Search on both fields
                resultStringKeyName="Name" // String to display in the results
                onSearch={handleOnSearch}
                value={addAirport?.dropOff_location}
                onSelect={(item) => {
                  setAddAirort({
                    ...addAirport,
                    dropOff_location: item?.Name,
                  });
                }}
                showIcon={true}
                styling={{
                  height: "25px",
                  borderRadius: "3px",
                  backgroundColor: "white",
                  fontSize: "12px",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 999,
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className="allThree">
        {/* OneWayModifier */}
        <div className="allThree">
          <div className="outstation_section">
            <div className="form_box">
              <form>
                <div className="base_fare">
                  <label>Base Fare</label>
                  <input
                    type="number"
                    id="basefare"
                    name="base_fare"
                    value={addAirport?.base_fare}
                    onChange={(e) => {
                      setAddAirort({
                        ...addAirport,
                        base_fare: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="base_fare">
                  <label>GST</label>
                  <div className="inner_button_gst">
                    <p className="percentage">% age</p>
                  </div>
                  <input
                    type="number"
                    id="gst"
                    name="gst"
                    min="0"
                    max="100"
                    value={addAirport?.gst}
                    onChange={(e) => {
                      setAddAirort({
                        ...addAirport,
                        gst: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="userUpdateItem">
                  <label className="user__label">Terms & Conditions</label>

                  <RichTextEditor
                    style={{
                      width: "450px",
                    }}
                    value={addAirport.term_and_condition}
                    controls={[
                      ["bold", "italic", "underline"],
                      ["unorderedList"],
                    ]}
                    onChange={(e) => {
                      setAddAirort({
                        ...addAirport,
                        term_and_condition: e,
                      });
                    }}
                  />
                </div>

                <div className="userUpdateItem">
                  <label className="user__label">Cancellation Policy</label>

                  <RichTextEditor
                    style={{
                      width: "450px",
                    }}
                    value={addAirport.cancellation}
                    controls={[
                      ["bold", "italic", "underline"],
                      ["unorderedList"],
                    ]}
                    onChange={(e) => {
                      setAddAirort({
                        ...addAirport,
                        cancellation: e,
                      });
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
