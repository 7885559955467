import React from "react"
import "./style.css"

export default function PhotoScreen() {
  return (
    <>
      <div className="banner">
        <img src="/assets/photoscreen.svg" className="img_inner_photo_screen" />
      </div>
    </>
  )
}
