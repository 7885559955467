import React from "react";
import "./style.css";

export default function TopRoute() {
  return (
    <>
      <div className="divmain">
        <h1 className="heading_why_fleet_see_here">Top Cab Routes</h1>

        <div className="inner_conatiner_main_check">
          <div className="innerCheck_div_here">
            <h1 className="inner_hading_main">From Chandigarh</h1>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">Chandigarh - Manali</h3>
            </div>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">Chandigarh - New Delhi</h3>
            </div>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">Chandigarh - Shimla</h3>
            </div>
          </div>
          <div className="innerCheck_div_here">
            <h1 className="inner_hading_main">From New Delhi</h1>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">New Delhi - Manali</h3>
            </div>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">New Delhi - Chandigarh</h3>
            </div>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">New Delhi - Shimla</h3>
            </div>
          </div>
          <div className="innerCheck_div_here">
            <h1 className="inner_hading_main">From Mumbai</h1>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">Mumbai - New Delhi</h3>
            </div>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">Mumbai - Pune</h3>
            </div>
            <div className="Main_check_innerNalDiv">
              <img
                src="/assets/toproute/location.png"
                className="img_innerView"
              />
              <h3 className="heafing_h3_inner_check">Mumbai - Goa</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
