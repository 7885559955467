import React, { useState, useEffect } from "react"
import "./style.css"
import Geocoder from "../Geocoder"
import { ReactSearchAutocomplete } from "react-search-autocomplete"
import { useRideValue } from "../../context/rideContext"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useNavigate } from "react-router-dom"
import swal from "sweetalert"
import moment from "moment"
import pmlAPI from "../../api/pmlAPI"
export default function TopBox() {
  // NOTE: The following context is for the use of the hidden map
  // const [{ pickup, dropoff, distance, duration, passengers, date }, dispatch] =
  //   useRideValue();
  const [
    {
      pickup,
      dropoff,
      passengers,
      date,
      TypeTravel,
      distance,
      duration,
      hourlyRate,
      check,
    },
    dispatch,
  ] = useRideValue()
  const matches = useMediaQuery("(max-width:910px)")

  const [selectedOption, setselectedOption] = useState("oneway")

  const navigate = useNavigate()
  const travale = JSON.parse(localStorage.getItem("userData"))
  const [btnloding, setbtnloding] = useState(false)
  const [marge, setmargin] = useState("0px")

  const [datevale, setdatevle] = useState({
    to: "",
    from: "",
  })
  const [internationaldata, setinternationaldata] = useState({
    name: travale?.name || "No Data Found  plz LogIn",
    email: travale?.email || "No Data Found  plz LogIn",
    phone: "",
    location: "",
    date: "",
    luggage: "",
    passengers: {
      adults: "",
      children: "",
      infants: "",
    },
    textarea: "",
  })
  // const [showMap, setShowMap] = useState(false);

  //// AirPorty section
  const [checkAirType, setCheckAirtype] = useState("pick")
  const [dataAirAll, setDataAirAll] = useState([])
  const [pickupDatapick, setPickupdataPick] = useState([])
  const [dropOffDatapick, setDropOffDataPick] = useState([])
  const [pickupDatadrop, setPickupdataDrop] = useState([])
  const [dropOffDatadrop, setDropOffDataDrop] = useState([])
  const [airportData, setAirportData] = useState({
    pickup: "",
    dropoff: "",
    type: "pick",
    pickupTime: "",
  })
  //// One Way section
  const [oneWaydataAirAll, setOneWayDataAirAll] = useState([])
  const [oneWaypickupDatapick, setOneWayPickupdataPick] = useState([])
  const [oneWaydropOffDatapick, setOneWayDropOffDataPick] = useState([])
  const [onwayData, setOneWayData] = useState({
    pickup: "",
    dropOff: "",
    pickupTime: "",
  })

  useEffect(() => {
    if (
      internationaldata.name == "No Data Found  plz LogIn" &&
      internationaldata.email == "No Data Found  plz LogIn"
    ) {
      setbtnloding(true)
    } else {
      setbtnloding(false)
    }
  }, [internationaldata])

  useEffect(() => {
    let localvalu = {
      pickup: pickup,
      dropoff: dropoff,
      date: date,
      passengers: passengers,
      duration: duration,
      distance: distance,
      TypeTravel: selectedOption,
      hourlyRate: hourlyRate,
      check: check,
    }

    localStorage.setItem("travelDetail", JSON.stringify(localvalu))
  }, [pickup, dropoff, date, passengers, distance, TypeTravel, hourlyRate])
  const sawlForAllError = (data) => {
    swal({
      title: "Error!",
      text: data,
      icon: "error",
    })
  }

  const handleSearchClick = async () => {
    let dateChange1 = moment(new Date(datevale?.to)).format(
      "MMMM Do YYYY, h:mm:ss a"
    )
    var date1 = moment(dateChange1, "MMMM Do YYYY, h:mm:ss a")
    var datecurrent = moment(new Date(), "MMMM Do YYYY, h:mm:ss a")
    var minutesDiffddd = date1.diff(datecurrent, "minutes")
    if (minutesDiffddd < 0) {
      sawlForAllError("Invalid PickUp Date And Time")
    } else {
      if (selectedOption == "Airport") {
        handleAirportSubmit()
      } else if (selectedOption == "oneway") {
        HandleOneWay()
      } else {
        HandleOutstationHourly()
      }
    }
  }

  const handleAirportSubmit = async () => {
    airportData.pickupTime = datevale.to
    if (airportData?.pickup == "") {
      sawlForAllError("Select Pick Up Location")
    } else if (airportData?.dropoff == "") {
      sawlForAllError("Select Drop Off Location ")
    } else if (datevale.to == "") {
      sawlForAllError("Select PickUp Date  And Time")
    } else if (
      Number(passengers.adults) +
        Number(passengers.children) +
        Number(passengers.infants) ==
      0
    ) {
      sawlForAllError("Select Passengers")
    } else {
      localStorage.setItem("airportData", JSON.stringify(airportData))
      navigate("/cabs")
    }
  }

  const HandleOutstationHourly = async () => {
    let checkDateForFinal = moment(new Date(datevale?.to)).format("YYYY-MM-DD")
    let returnDate = moment(new Date(datevale?.from)).format("YYYY-MM-DD")

    let dateChange1 = moment(new Date(datevale?.to)).format(
      "MMMM Do YYYY, h:mm:ss a"
    )
    let dateChange2 = moment(new Date(datevale?.from)).format(
      "MMMM Do YYYY, h:mm:ss a"
    )

    var date1 = moment(dateChange1, "MMMM Do YYYY, h:mm:ss a")

    var date2 = moment(dateChange2, "MMMM Do YYYY, h:mm:ss a")

    let checkDateOne = moment(new Date(datevale?.to)).format(
      "YYYY-MM-DD h:mm:ss a"
    )
    let checkDateTwo = moment(new Date(datevale?.from)).format(
      "YYYY-MM-DD h:mm:ss a"
    )

    let durationAll = null
    if (parseInt(duration / 60) * 2 == 0) {
      durationAll = 0
    } else {
      durationAll = parseInt(duration / 60) * 2 + 60
    }

    if (selectedOption === 3) {
      dispatch({
        type: "ADD_DROPOFF",
        dropoff: {},
      })
    }

    if (selectedOption == "hourly" && hourlyRate == "") {
      dispatch({
        type: "HOURLY_RATE",
        hourlyRate: "2",
      })
    }

    var minutesDiff = date2.diff(date1, "minutes")

    console.log("api time *2 +1")
    console.log(parseInt(duration / 60) * 2 + 60)
    console.log("time diffrance between two date")
    console.log(minutesDiff)

    if (Object.keys(pickup).length == 0) {
      sawlForAllError("Select Pick Up Location ")
    } else if (
      selectedOption == "outstation" &&
      Object.keys(dropoff).length == 0
    ) {
      sawlForAllError("Select Drop Off Location ")
    } else if (datevale.to == "") {
      sawlForAllError("Select PickUp Date  And Time")
    } else if (selectedOption == "outstation" && datevale.from == "") {
      sawlForAllError("Select Return Date  And Time")
    } else if (
      selectedOption == "outstation" &&
      moment(checkDateOne).isSame(checkDateTwo)
    ) {
      sawlForAllError("PickUp date & Return date can not be same ")
    } else if (selectedOption == "outstation" && minutesDiff < durationAll) {
      sawlForAllError(
        " Return is not possible. Please change your Return date."
      )
    } else if (
      Number(passengers.adults) +
        Number(passengers.children) +
        Number(passengers.infants) ==
      0
    ) {
      sawlForAllError("Select Passengers")
    } else if (
      selectedOption == "outstation" &&
      moment(checkDateForFinal).isSame(returnDate) &&
      distance / 1000 >= 250
    ) {
      sawlForAllError("Same Day Return is not Possible")
    } else {
      navigate("/cabs")
    }
  }

  const HandleOneWay = async () => {
    onwayData.pickupTime = datevale.to
    if (onwayData?.pickup == "") {
      sawlForAllError("Select Pick Up Location")
    } else if (onwayData?.dropoff == "") {
      sawlForAllError("Select Drop Off Location ")
    } else if (datevale.to == "") {
      sawlForAllError("Select PickUp Date  And Time")
    } else if (
      Number(passengers.adults) +
        Number(passengers.children) +
        Number(passengers.infants) ==
      0
    ) {
      sawlForAllError("Select Passengers")
    } else {
      localStorage.setItem("onewayData", JSON.stringify(onwayData))
      navigate("/cabs")
    }
  }

  /* Get the duration of the ride and store it in context */
  useEffect(() => {
    if (Object.keys(pickup).length !== 0 && Object.keys(dropoff).length !== 0) {
      fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${pickup.center[0]},${pickup.center[1]};${dropoff.center[0]},${dropoff.center[1]}?access_token=pk.eyJ1IjoiaGVjdG9yZzIyMTEiLCJhIjoiY2t0eWtxbmhtMDhwMTJwcG1jZXd0b3VhMSJ9.8XhBErdMP3PqsR-xN-NkMA`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.routes) {
            dispatch({
              type: "ADD_DURATION",
              duration: data.routes[0]?.duration,
            })
            dispatch({
              type: "ADD_DISTANCE",
              distance: data.routes[0]?.distance,
            })
          }
        })
    }
  }, [dispatch, pickup, dropoff])

  useEffect(() => {
    if (matches) {
      setmargin("300px")
    } else {
      setmargin("20px")
    }
  }, [matches])

  const handleOnSearch = (string, results) => {
    console.log({ string }, { results })
  }

  const onChangeDatePickTo = (value) => {
    let dateAll = {
      to: new Date(value),
    }

    setdatevle({
      ...datevale,
      to: value,
    })

    dispatch({
      type: "ADD_DATE",
      date: dateAll,
    })
  }
  const onChangeDatePickFrom = (value) => {
    let dateAll = {
      to: new Date(datevale.to),
      from: new Date(value),
    }

    setdatevle({
      ...datevale,
      from: value,
    })

    dispatch({
      type: "ADD_DATE",
      date: dateAll,
    })
  }
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }
  /////////// Airport Section
  useEffect(async () => {
    try {
      let res = await pmlAPI.get("/api/cabs")

      let Fildataair = res?.data?.cab
        ?.filter((w) => {
          return w?.airport
        })
        .map((q) => {
          return {
            type: q?.airportdata?.airType,
            dropoffair: q?.airportdata?.dropOff_location,
            pickUpair: q?.airportdata?.pickUp_location,
          }
        })
      setDataAirAll(Fildataair)

      let datafil = Fildataair?.filter((w) => {
        return w.type == "pick"
      }).map((w) => {
        return w?.pickUpair
      })
      let datafiltwo = Fildataair?.filter((w) => {
        return w.type == "drop"
      }).map((w) => {
        return w?.pickUpair
      })

      var unique = datafil.filter(onlyUnique)
      var uniquetwo = datafiltwo.filter(onlyUnique)
      let Arraone = unique?.map((w) => {
        return {
          name: w,
        }
      })
      let Arratwo = uniquetwo?.map((w) => {
        return {
          name: w,
        }
      })

      setPickupdataPick(Arraone)
      setPickupdataDrop(Arratwo)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleOnSelectPick = (item) => {
    setAirportData({
      ...airportData,
      pickup: item.name,
    })
    let filetDataArrayPick = dataAirAll
      ?.filter((w) => {
        return w.type == "pick" && w?.pickUpair == item?.name
      })
      .map((w) => {
        return w?.dropoffair
      })

    var unique = filetDataArrayPick.filter(onlyUnique)

    let Arratwo = unique?.map((w) => {
      return {
        name: w,
      }
    })
    setDropOffDataPick(Arratwo)
  }
  const handleOnSelectDrop = (item) => {
    setAirportData({
      ...airportData,
      pickup: item.name,
    })
    let filetDataArrayPick = dataAirAll
      ?.filter((w) => {
        return w.type == "drop" && w?.pickUpair == item?.name
      })
      .map((w) => {
        return w?.dropoffair
      })

    var unique = filetDataArrayPick.filter(onlyUnique)

    let Arratwo = unique?.map((w) => {
      return {
        name: w,
      }
    })
    setDropOffDataDrop(Arratwo)
  }

  /////////// One Way Pick Up Section
  useEffect(async () => {
    try {
      let res = await pmlAPI.get("/api/cabs")

      let Fildataair = res?.data?.cab
        ?.filter((w) => {
          return w?.oneway
        })
        .map((q) => {
          return {
            dropoffair: q?.onewaydata?.dropoff,
            pickUpair: q?.onewaydata?.pickup,
          }
        })
      setOneWayDataAirAll(Fildataair)

      let datafil = Fildataair?.map((w) => {
        return w?.pickUpair
      })

      var unique = datafil.filter(onlyUnique)

      let Arraone = unique?.map((w) => {
        return {
          name: w,
        }
      })
      console.log(Arraone)

      setOneWayPickupdataPick(Arraone)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleOnSelectPickOn = (item) => {
    setOneWayData({
      ...onwayData,
      pickup: item.name,
    })
    let filetDataArrayPick = oneWaydataAirAll
      ?.filter((w) => {
        return w?.pickUpair == item?.name
      })
      .map((w) => {
        return w?.dropoffair
      })

    var unique = filetDataArrayPick.filter(onlyUnique)

    let Arratwo = unique?.map((w) => {
      return {
        name: w,
      }
    })
    setOneWayDropOffDataPick(Arratwo)
  }

  const handleChangeType = () => {
    dispatch({ type: "HANDLE_RESET" })
  }
  return (
    <>
      <div className="main_div_topBox">
        <div className="div_second_for_background_img"></div>
        <div className="div_">
          <div></div>
          <div>
            <img
              src="/assets/carinner.png"
              className="img_car_innner_top_box"
            />
          </div>
        </div>

        {/* /////////////////////////// */}
        <div className="main_ineer_container_to_check">
          <div
            className={`third_main_div_is_here ${
              selectedOption == "hourly" ? "top_box_container" : ""
            }`}
          >
            <h1 className="main_inner_white_div_heading">
              India’s Trusted Tourist <br /> Cab Partner
            </h1>

            <div className="div_for_padding_here">
              <div className="div_main_button">
                <button
                  className={
                    selectedOption == "oneway"
                      ? "button_active_class"
                      : "button_non_active_class button_tab"
                  }
                  onClick={() => {
                    let data = "oneway"
                    dispatch({
                      type: "TypeTravel",
                      data,
                    })
                    setselectedOption("oneway")
                  }}
                >
                  One Way Trip
                </button>
                <button
                  className={
                    selectedOption == "outstation"
                      ? "button_active_class"
                      : "button_non_active_class"
                  }
                  onClick={() => {
                    let data = "outstation"
                    dispatch({
                      type: "TypeTravel",
                      data,
                    })
                    setselectedOption("outstation")
                  }}
                >
                  Round Trip
                </button>
                <button
                  className={
                    selectedOption == "Airport"
                      ? "button_active_class"
                      : "button_non_active_class"
                  }
                  onClick={() => {
                    setselectedOption("Airport")
                    let data = "Airport"
                    dispatch({
                      type: "TypeTravel",
                      data,
                    })
                    //setshow(true);
                    //navigate('/airportpickup')
                  }}
                >
                  Airport
                </button>
                <button
                  className={
                    selectedOption == "hourly"
                      ? "button_active_class"
                      : "button_non_active_class"
                  }
                  onClick={() => {
                    let data = "hourly"
                    dispatch({
                      type: "TypeTravel",
                      data,
                    })
                    setselectedOption("hourly")
                    handleChangeType()
                  }}
                >
                  Hourly Rental
                </button>
              </div>

              <div className="div_for_margin">
                {selectedOption == "Airport" && (
                  <div className="div_airport_is_here">
                    <button
                      className={
                        checkAirType == "pick"
                          ? "airport_btn_active"
                          : "airport_btn_inactive"
                      }
                      onClick={() => {
                        setCheckAirtype("pick")
                        setAirportData({
                          ...airportData,
                          type: "pick",
                        })
                      }}
                    >
                      From Airport
                    </button>
                    <button
                      className={
                        checkAirType == "drop"
                          ? "airport_btn_active"
                          : "airport_btn_inactive"
                      }
                      onClick={() => {
                        setCheckAirtype("drop")
                        setAirportData({
                          ...airportData,
                          type: "drop",
                        })
                      }}
                    >
                      To Airport
                    </button>
                  </div>
                )}

                {/* One Way  Code Here */}
                {selectedOption == "oneway" && (
                  <div className="mt-30">
                    <div className="div_end_check_is_here round_trip_input h-40">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/targetRed.png"
                          className="imge_input_style_check"
                        />
                      </div>
                      <div className="input_check_here">
                        <ReactSearchAutocomplete
                          placeholder="From "
                          items={oneWaypickupDatapick}
                          fuseOptions={{ keys: ["name"] }} // Search on both fields
                          resultStringKeyName="name" // String to display in the results
                          onSearch={handleOnSearch}
                          onSelect={handleOnSelectPickOn}
                          showIcon={false}
                          styling={{
                            width: "100px",
                            height: "30px",
                            borderRadius: "0px",
                            backgroundColor: "#f5f5f5",
                            fontSize: "12px",
                            clearIconMargin: "3px 8px 0 0",
                            border: "none",
                            color: "#CCCCCC",
                            fontSize: "15px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="div_for_margin_inner_check"></div>

                    <div className="div_end_check_is_here_two round_trip_input h-40">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/enviroment.png"
                          className="imge_input_style_check"
                        />
                      </div>
                      <div className="input_check_here">
                        <ReactSearchAutocomplete
                          placeholder="To "
                          items={oneWaydropOffDatapick}
                          fuseOptions={{ keys: ["name"] }} // Search on both fields
                          resultStringKeyName="name" // String to display in the results
                          onSearch={handleOnSearch}
                          onSelect={(item) => {
                            setOneWayData({
                              ...onwayData,
                              dropOff: item.name,
                            })
                          }}
                          showIcon={false}
                          styling={{
                            width: "100px",
                            height: "30px",
                            borderRadius: "0px",
                            backgroundColor: "#f5f5f5",
                            fontSize: "12px",
                            clearIconMargin: "3px 8px 0 0",
                            border: "none",
                            color: "#CCCCCC",
                            fontSize: "15px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="div_end_check_is_here_date">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/calender.png"
                          className="imge_input_style_check_inpu"
                        />
                      </div>
                      <div className="input_check_here">
                        <h4 className="input_span_value_check">
                          Start Date & Time
                        </h4>
                        <input
                          className="input_lable_is_here"
                          type="datetime-local"
                          min={moment(new Date()).format("YYYY-MM-DDThh:mm")}
                          onChange={(e) => {
                            onChangeDatePickTo(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Round Trip Code Here */}
                {selectedOption == "outstation" && (
                  <div>
                    <div className="relative mt-30">
                      <div className="div_end_check_is_here round_trip_input h-40">
                        <div className="one_div_image_check">
                          <img
                            src="/assets/toproute/targetRed.png"
                            className="imge_input_style_check"
                          />
                        </div>
                        <div className="input_check_here">
                          <Geocoder number={1} className="geocoderrr" />
                        </div>
                      </div>

                      <div className="div_end_check_is_here_two round_trip_input h-40">
                        <div className="one_div_image_check">
                          <img
                            src="/assets/toproute/enviroment.png"
                            className="imge_input_style_check"
                          />
                        </div>
                        <div className="input_check_here">
                          <Geocoder number={2} />
                        </div>
                      </div>
                      <div className="return-arrow">
                        <img
                          src="/assets/toproute/arrow.png"
                          className="img_inner_output"
                        />
                      </div>
                    </div>

                    <div className="div_end_check_is_here_date">
                      <div
                        className="one_div_image_check"
                        style={{
                          display: "none",
                        }}
                      >
                        <img
                          src="/assets/toproute/calender.png"
                          className="imge_input_style_check_input"
                        />
                      </div>

                      <div className="round_trip_check_is_here">
                        <div className="input_check_here_round  new_round_input_check">
                          <h4 className="input_span_value_check">
                            Start Date & Time
                          </h4>
                          <input
                            className="input_lable_is_here"
                            type="datetime-local"
                            min={moment(new Date()).format("YYYY-MM-DDThh:mm")}
                            onChange={(e) => {
                              onChangeDatePickTo(e.target.value)
                            }}
                          />
                        </div>
                        <div className="input_check_here_round">
                          <h4 className="input_span_value_check">
                            Return Date & Time
                          </h4>
                          <input
                            className="input_lable_is_here"
                            type="datetime-local"
                            min={datevale.to}
                            onChange={(e) => {
                              onChangeDatePickFrom(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedOption == "Airport" && (
                  <>
                    <div className="div_end_check_is_here round_trip_input h-40">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/targetRed.png"
                          className="imge_input_style_check"
                        />
                      </div>
                      <div className="input_check_here">
                        {checkAirType == "pick" ? (
                          <ReactSearchAutocomplete
                            placeholder="From "
                            items={pickupDatapick}
                            fuseOptions={{ keys: ["name"] }} // Search on both fields
                            resultStringKeyName="name" // String to display in the results
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelectPick}
                            showIcon={false}
                            styling={{
                              width: "100px",
                              height: "30px",
                              borderRadius: "0px",
                              backgroundColor: "#f5f5f5",
                              fontSize: "12px",
                              clearIconMargin: "3px 8px 0 0",
                              border: "none",
                              color: "#CCCCCC",
                              fontSize: "15px",
                            }}
                          />
                        ) : (
                          <ReactSearchAutocomplete
                            placeholder="From "
                            items={pickupDatadrop}
                            fuseOptions={{ keys: ["name"] }} // Search on both fields
                            resultStringKeyName="name" // String to display in the results
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelectDrop}
                            showIcon={false}
                            styling={{
                              width: "100px",
                              height: "30px",
                              borderRadius: "0px",
                              backgroundColor: "#f5f5f5",
                              fontSize: "12px",
                              clearIconMargin: "3px 8px 0 0",
                              border: "none",
                              color: "#CCCCCC",
                              fontSize: "15px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="div_for_margin_inner_check"></div>
                    <div className="div_end_check_is_here_two round_trip_input h-40">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/enviroment.png"
                          className="imge_input_style_check"
                        />
                      </div>
                      <div className="input_check_here">
                        {checkAirType == "pick" ? (
                          <ReactSearchAutocomplete
                            placeholder="To "
                            items={dropOffDatapick}
                            fuseOptions={{ keys: ["name"] }} // Search on both fields
                            resultStringKeyName="name" // String to display in the results
                            onSearch={handleOnSearch}
                            onSelect={(item) => {
                              setAirportData({
                                ...airportData,
                                dropoff: item.name,
                              })
                            }}
                            showIcon={false}
                            styling={{
                              width: "100px",
                              height: "30px",
                              borderRadius: "0px",
                              backgroundColor: "#f5f5f5",
                              fontSize: "12px",
                              clearIconMargin: "3px 8px 0 0",
                              border: "none",
                              color: "#CCCCCC",
                              fontSize: "15px",
                            }}
                          />
                        ) : (
                          <ReactSearchAutocomplete
                            placeholder="To "
                            items={dropOffDatadrop}
                            fuseOptions={{ keys: ["name"] }} // Search on both fields
                            resultStringKeyName="name" // String to display in the results
                            onSearch={handleOnSearch}
                            onSelect={(item) => {
                              setAirportData({
                                ...airportData,
                                dropoff: item.name,
                              })
                            }}
                            showIcon={false}
                            styling={{
                              width: "100px",
                              height: "30px",
                              borderRadius: "0px",
                              backgroundColor: "#f5f5f5",
                              fontSize: "12px",
                              clearIconMargin: "3px 8px 0 0",
                              border: "none",
                              color: "#CCCCCC",
                              fontSize: "15px",
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="div_end_check_is_here_date">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/calender.png"
                          className="imge_input_style_check_inpu"
                        />
                      </div>
                      <div className="input_check_here">
                        <h4 className="input_span_value_check">
                          Start Date & Time
                        </h4>
                        <input
                          className="input_lable_is_here"
                          type="datetime-local"
                          min={moment(new Date()).format("YYYY-MM-DDThh:mm")}
                          onChange={(e) => {
                            onChangeDatePickTo(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {selectedOption == "hourly" && (
                  <>
                    <div className="div_end_check_is_here round_trip_input h-40">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/targetRed.png"
                          className="imge_input_style_check"
                        />
                      </div>
                      <div className="input_check_here">
                        <Geocoder number={1} />
                      </div>
                    </div>

                    <div className="div_end_check_is_here_date">
                      <div className="one_div_image_check">
                        <img
                          src="/assets/toproute/calender.png"
                          className="imge_input_style_check_inpu"
                        />
                      </div>

                      <div className="round_trip_check_is_here">
                        <div className="input_check_here_round  new_round_input_check">
                          <h4 className="input_span_value_check">
                            Start Date & Time
                          </h4>
                          <input
                            className="input_lable_is_here"
                            type="datetime-local"
                            min={moment(new Date()).format("YYYY-MM-DDThh:mm")}
                            onChange={(e) => {
                              onChangeDatePickTo(e.target.value)
                            }}
                          />
                        </div>
                        <div className="input_check_here_round">
                          <h4 className="input_span_value_check">Rent For</h4>

                          <select
                            className="input_lable_is_here_select"
                            onChange={(e) => {
                              dispatch({
                                type: "HOURLY_RATE",
                                hourlyRate: e.target.value,
                              })
                            }}
                          >
                            <option value="2">2hr/20km</option>
                            <option value="4">4hr/40km</option>
                            <option value="6">6hr/60km</option>
                            <option value="8">8hr/80km</option>
                            <option value="10">10hr/100km</option>
                            <option value="12">12hr/120km</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div
                  className="div_for_margin_check_here"
                  onClick={() => {
                    handleSearchClick()
                  }}
                >
                  Search Cabs
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
