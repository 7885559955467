import React, { useState, useEffect } from "react";
import pmlAPI from "../api/pmlAPI";
import { useNavigate } from "react-router-dom";
import "./Allcss/AllOder.css";
import {
  TableRow,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Button,
  Chip,
} from "@mui/material";
import { nanoid } from "nanoid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";

const tableRow = {
  fontSize: "0.8vw",
  fontWeight: "bold",
};
const tablebodyrow = {
  fontSize: "0.7vw",
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
export default function CabOrder() {
  const [data, setdata] = useState([]);
  const [backUpData, setBackUpData] = useState([]);
  const navigate = useNavigate();
  const [refresh, setrefresh] = useState();
  const [statuscolor, setstatuscolor] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(async () => {
    await pmlAPI
      .get("/api/caboreder")
      .then((res) => {
        let newdata = res?.data?.reverse();
        let sendData = newdata?.filter((w) => {
          let date = moment(currentDate).format("YYYY-MM-DD");
          let date2 = moment(w?.updatedAt).format("YYYY-MM-DD");
          return moment(date).isSame(date2, "day");
        });

        setBackUpData(newdata);
        setdata(sendData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(async () => {
    const interval = setInterval(async () => {
      await pmlAPI
        .get("/api/caboreder")
        .then((res) => {
          let newdata = res?.data?.reverse();
          let sendData = newdata?.filter((w) => {
            let date = moment(currentDate).format("YYYY-MM-DD");
            let date2 = moment(w?.updatedAt).format("YYYY-MM-DD");
            return moment(date).isSame(date2, "day");
          });

          setBackUpData(newdata);
          setdata(sendData);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const DateWiseFilter = (e) => {
    setCurrentDate(e);
    let filterbackup = backUpData?.filter((w) => {
      let date2 = moment(w?.updatedAt).format("YYYY-MM-DD");
      return moment(e).isSame(date2, "day");
    });
    setdata(filterbackup);
  };

  const OrderTypeFilter = (value) => {
    let orderFirst = null;
    if (value == "none") {
      orderFirst = backUpData;
    } else {
      orderFirst = backUpData?.filter((w) => {
        return w?.data?.TypeTravel == value;
      });
    }

    let filterbackup = orderFirst?.filter((w) => {
      let date2 = moment(w?.updatedAt).format("YYYY-MM-DD");
      return moment(currentDate).isSame(date2, "day");
    });

    setdata(filterbackup);
  };

  const PaymentStatus = (value) => {
    let orderFirst = null;
    if (value == "none") {
      orderFirst = backUpData;
    } else {
      orderFirst = backUpData?.filter((w) => {
        return w?.order_details?.status == value;
      });
    }

    let filterbackup = orderFirst?.filter((w) => {
      let date2 = moment(w?.updatedAt).format("YYYY-MM-DD");
      return moment(currentDate).isSame(date2, "day");
    });

    setdata(filterbackup);
  };

  const handleCabStatus = (value) => {
    let orderFirst = null;
    if (value == "none") {
      orderFirst = backUpData;
    } else {
      orderFirst = backUpData?.filter((w) => {
        return w?.cab_status == value;
      });
    }
    let filterbackup = orderFirst?.filter((w) => {
      let date2 = moment(w?.updatedAt).format("YYYY-MM-DD");
      return moment(currentDate).isSame(date2, "day");
    });

    setdata(filterbackup);
  };

  return (
    <>
      <br></br>
      <br></br>
      <div className="flex-container">
        <div className="dateDiv">
          <label className="vieworder-lable">View Order</label>
          <input
            value={currentDate}
            type="date"
            onChange={(e) => {
              DateWiseFilter(e.target.value);
            }}
          />
        </div>

        <div className="dateDiv">
          <label>Order Type</label>
          <select onChange={(e) => OrderTypeFilter(e.target.value)}>
            <option value="none">Filter order By type </option>
            <option value="outstation">Outstation </option>
            <option value="hourly">Hourly</option>
            <option value="Airport">Airport</option>
          </select>
        </div>

        <div className="dateDiv">
          <label>Payment Status</label>
          <select onChange={(e) => PaymentStatus(e.target.value)}>
            <option value="none">Filter Order By Payment</option>
            <option value="ACTIVE">Active</option>
            <option value="PAID">Paid</option>
          </select>
        </div>
        <div className="dateDiv">
          <label>Cab Status</label>
          <select onChange={(e) => handleCabStatus(e.target.value)}>
            <option value="none">Filter Order by status</option>
            <option value="On Hold">On Hold</option>
            <option value="Processing">Processing</option>
            <option value="Payment Pending">Payment Pending</option>
            <option value="Complete">Complete</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
      </div>
      {data?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 150 }} aria-label="caption table">
            {/* <caption>A basic table example with a caption</caption> */}
            <TableHead>
              <TableRow>
                <TableCell sx={tableRow}>Name</TableCell>
                <TableCell sx={tableRow}>Email</TableCell>
                <TableCell sx={tableRow}> Type Of Order </TableCell>
                <TableCell sx={tableRow}>Pick Up Location</TableCell>
                <TableCell sx={tableRow}>Drop Off Location</TableCell>
                <TableCell sx={tableRow}>Payment Status</TableCell>
                <TableCell sx={tableRow}>Cab Status</TableCell>
                <TableCell sx={tableRow}>Driver Phone No.</TableCell>
                <TableCell sx={tableRow}>Total</TableCell>
                <TableCell sx={tableRow}>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((w) => {
                return (
                  <TableRow key={w._id}>
                    <TableCell sx={tablebodyrow}>{w?.data?.name}</TableCell>
                    <TableCell sx={tablebodyrow}>{w.data.email}</TableCell>
                    <TableCell sx={tablebodyrow}>{w.data.TypeTravel}</TableCell>
                    <TableCell sx={tablebodyrow}>{w?.data?.pickUp} </TableCell>
                    <TableCell sx={tablebodyrow}>{w?.data?.dropoff}</TableCell>
                    <TableCell
                      sx={tablebodyrow}
                      style={{ textAlign: "center" }}
                    >
                      <p
                        style={
                          w?.order_details?.status == "PAID"
                            ? { color: "green" }
                            : w?.order_details?.status == "ACTIVE"
                            ? { color: "skyblue" }
                            : { color: "red" }
                        }
                      >
                        {w?.order_details?.status || "Not Updated"}
                      </p>
                    </TableCell>
                    <TableCell
                      sx={tablebodyrow}
                      style={{ textAlign: "center" }}
                    >
                      <p className={`para ${w?.cab_status}`}>
                        {w?.cab_status}{" "}
                      </p>
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {" "}
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            {w?.cab_no || w?.driver_name ? (
                              <>
                                {" "}
                                <Typography>Cab No. :- {w?.cab_no}</Typography>
                                <Typography>
                                  Driver Name. :- {w?.driver_name}
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        }
                      >
                        <Button> {w?.driver_mobile_no || "No Details"}</Button>
                      </HtmlTooltip>{" "}
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {w?.data?.TypeTravel == "hourly"
                        ? w?.data?.pricePerHour
                        : w?.data?.total}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={tablebodyrow}
                        onClick={() => {
                          navigate(`/dashboard/vieworder/${w?._id}`);
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="caption table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead>
                <TableRow>
                  <TableCell sx={tableRow}>Name</TableCell>
                  <TableCell sx={tableRow}>Email</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell sx={tableRow}>No Data Found</TableCell>
                  <TableCell sx={tableRow}>No Data Found</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
