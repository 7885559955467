import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import pmlAPI from "../../api/pmlAPI";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

export default function ViewCab() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentValue, setCurrentValue] = useState();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(async () => {
    try {
      let res = await pmlAPI.get(`/api/cabs/${id}`);
      console.log(res?.data?.data);
      setData(res?.data?.data);
      if (res?.data?.data.outstation && res?.data?.data.hourly) {
        setCurrentValue("out");
      } else if (res?.data?.data.outstation && !res?.data?.data.hourly) {
        setCurrentValue("out");
      } else if (!res?.data?.data.outstation && res?.data?.data.hourly) {
        setCurrentValue("hour");
      }
    } catch (error) {
      console.log(error);
    }
  }, [id]);
  return (
    <div className="mainDivHeading">
      <div className="divMainViewCab">
        {data?.outstation ? (
          <button
            className="buttonShow"
            style={
              currentValue == "out"
                ? {
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                  }
                : {}
            }
            onClick={() => {
              setCurrentValue("out");
              setShow(false);
            }}
          >
            Out Station Details
          </button>
        ) : (
          ""
        )}

        {data?.hourly ? (
          <button
            className="buttonShow"
            style={
              currentValue == "hour"
                ? {
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                  }
                : {}
            }
            onClick={() => {
              setCurrentValue("hour");
              setShow(true);
            }}
          >
            Hourly Details
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="View_cab_Card">
        <div
          style={{
            backgroundImage: ` url(${data.cabImage})`,
            maxWidth: "300px",
            width: "100%",
            height: "161px",
            backgroundSize: "cover",
            borderRadius: "15px",
          }}
        ></div>
        <div className="divHeading">
          <div className="div_internal_flex">
            <p className="para_show">Car Type :-</p>
            <p className="para_show">{data.cab_type}</p>
          </div>
          <div className="div_internal_flex">
            <p className="para_show">Car Model :-</p>
            <p className="para_show">{data.carModel}</p>
          </div>
          <div className="div_internal_flex">
            <p className="para_show"> State :-</p>
            <p className="para_show">{data.region}</p>
          </div>
          <div className="div_internal_flex">
            <p className="para_show">City :-</p>
            <p className="para_show">{data.city}</p>
          </div>
          <div className="div_internal_flex">
            <p className="para_show">Fuel Type :-</p>
            <p className="para_show">{data.fule_type}</p>
          </div>
        </div>
        <div className="divHeading">
          <div className="div_internal_flex">
            <p className="para_show">Luggage :-</p>
            <p className="para_show">{data.luggage}</p>
          </div>
          <div className="div_internal_flex">
            <p className="para_show">Seat :-</p>
            <p className="para_show">{data.seats}</p>
          </div>
          <div className="div_internal_flex">
            <p className="para_show">Created At :-</p>
            <p className="para_show">{data.createdAt}</p>
          </div>
          <div className="div_internal_flex">
            <p className="para_show">Updated At :-</p>
            <p className="para_show">{data.updatedAt}</p>
          </div>
        </div>

        <div className="divHeading_for_icons">
          <div className="icondiv_view_order">
            <a
              onClick={() => {
                navigate(`/dashboard/RateModifier/${data?._id}`);
              }}
            >
              <EditIcon className="svg_icons_view_order" />
            </a>
          </div>
        </div>
      </div>
      <div className="table_div">
        {data.outstation && currentValue == "out" && !show ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Base Fare
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.outstationdata?.base_fare}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Minimum KM
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.outstationdata?.minkm}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Per KM Charge
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.outstationdata?.per_km_charge}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Extra Pick Up
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.outstationdata?.extra_pickup}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Driver Allowance
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.outstationdata?.driver_allowance}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Gst
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.outstationdata?.gst} %
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    State & Toll
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.outstationdata?.state_toll.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Vechile & Fuel
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.outstationdata?.vechile_and_fuel.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Waitng Charge
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.outstationdata?.waiting_charge?.type == "hour"
                      ? `${data?.outstationdata?.waiting_charge?.value} /hour`
                      : `${data?.outstationdata?.waiting_charge?.value} /min`}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Night Charge
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.outstationdata?.night_charge}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Night Charge
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.outstationdata?.night_time?.to} -
                    {data?.outstationdata?.night_time?.from}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ""
        )}
        {data.hourly && currentValue == "hour" && show ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Fare For 2hr/20km
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.two}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Fare For 4hr/40km
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.four}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Fare For 6hr/60km
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.six}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Fare For 8hr/80km
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.eight}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Fare For 10hr/100km
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.ten}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Fare For 12hr/120km
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.tewlev}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Per KM Charge
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.per_km_charge}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Extra Pick Up
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {data?.hourlydata?.extra_pickup}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Gst
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.hourlydata?.gst} %
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    State & Toll
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.hourlydata?.state_toll.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Vechile & Fuel
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.hourlydata?.vechile_and_fuel.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWidth: "bold",
                    }}
                  >
                    Waitng Charge
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.hourlydata?.waiting_charge?.type == "flat"
                      ? `₹ ${data?.hourlydata?.waiting_charge?.value}`
                      : `${data?.hourlydata?.waiting_charge?.value} %`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
