import React, { useState, useEffect } from "react";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import LuggageIcon from "@mui/icons-material/Luggage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import pmlAPI from "../../api/pmlAPI";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useRideValue } from "../../context/rideContext";

import "../Allcss/carcard.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoCard from "../InfoCard";

const CabCard = ({
  carModel,
  seats,
  luggage,
  price,
  cabImage,
  dashboardDisplay,
  distance,
  cabId,
  pricePerHour,
}) => {
  const [{ pickup, dropoff, passengers, date, TypeTravel }, dispatch] =
    useRideValue();
  const [total, settotel] = useState(123);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [locationtype, setlocationtype] = useState();
  const [driver, setdriver] = useState(400);
  const [show, setShow] = useState(false);
  const [airTax, setAirTax] = useState();

  // useEffect(() => {
  //   let datepick = JSON.parse(localStorage.getItem("travelDetail")).date;
  //   const date1 = new Date(datepick?.to);
  //   const date2 = new Date(datepick?.from);
  //   const diffTime = Math.abs(date2 - date1);
  //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  //   if (Number(diffDays) == 0) {
  //     setdriver(400);
  //   } else {
  //     setdriver(400 * Number(diffDays));
  //   }
  // }, []);

  useEffect(() => {
    let data = String(
      JSON.parse(localStorage.getItem("travelDetail"))?.TypeTravel
    );
    setlocationtype(data);
  }, []);
  useEffect(() => {
    let traveldataa = parseInt(
      JSON.parse(localStorage.getItem("airPort_Distance")) / 1000
    );
    let gst = null;
    let value = null;
    let final = null;

    if (Number(traveldataa) > 250) {
      value = parseInt(parseInt(traveldataa) * price * 2) + 150;
      gst = parseInt((Number(value + 150) / 100) * 5);
      final = Number(value + 150) + Number(gst);
    } else {
      value = parseInt(parseInt(250 * price) + 150);
      gst = parseInt((Number(value + 150) / 100) * 5);
      final = Number(value + 150) + Number(gst);
    }
    settotel(final);
  }, []);

  useEffect(() => {
    let traveldataa = parseInt(
      JSON.parse(localStorage.getItem("airPort_Distance")) / 1000
    );
    let gst = null;
    let value = null;
    let final = null;

    if (Number(traveldataa) > 250) {
      value = parseInt(parseInt(traveldataa) * price * 2) + 150;
      gst = parseInt((Number(value + 150) / 100) * 5);
      final = Number(value + 150) + Number(gst) +1;
    } else {
      value = parseInt(parseInt(250 * price) + 150);
      gst = parseInt((Number(value + 150) / 100) * 5);
      final = Number(value + 150) + Number(gst);
    }
    setAirTax(gst);
  }, []);

  const booknowbtn = async () => {
    let user = JSON.parse(localStorage.getItem("userData"));

    let traveldetails = JSON.parse(localStorage.getItem("travelDetail"));
    let Airport = JSON.parse(localStorage.getItem("AirPort"));

    let cardDetails = {
      carModel,
      seats,
      luggage,
      price,
      cabImage,
      dashboardDisplay,
      distance,
      cabId,
      pricePerHour,
    };

    let senddata = {
      data: {
        name: user?.name,
        email: user?.email,
        phone: "",
        pickUp: Airport?.name,
        dropoff: traveldetails.dropoff.place_name,
        pickupDetailAdress: "",
        dropoffDetailAdress: "",
        date: `${moment(traveldetails?.date?.to).format(
          "MMMM Do YYYY, h:mm a"
        )} To ${moment(traveldetails?.date?.from).format(
          "MMMM Do YYYY, h:mm a"
        )}`,
        passengers: {
          adults: traveldetails.passengers.adults,
          childern: traveldetails.passengers.children,
          infants: traveldetails.passengers.infants,
        },
        total: Number(total) ,
        TypeTravel: traveldetails?.TypeTravel,
        pricePerHour: pricePerHour,
        AirTax: airTax,
      },

      useremail: user.email,
      cabid: cabId,
      userid: user.id,
    };

    let totaldata = {
      cardDetails,
      senddata,
    };

    localStorage.setItem("data", JSON.stringify(totaldata));

    navigate("/airportpickup");
  };

  return (
    <>
      <div className="all_cab_card">
        <div className="cab-card" key={cabId}>
          <div className="cab-card__image">
            <img src={cabImage} alt="car 1" className="imgcar" />
          </div>

          <div className="cab-card__details">
            <h2 className="h2--2 cab__name">{carModel}</h2>
            <div className="service">
              <div className="service__unit">
                <h3 className="h3 h3--1 colorlue">1 Unit</h3>
              </div>
              <div className="service__seats">
                <h3 className="h3 h3--1 colorlue">
                  <AirlineSeatReclineNormalIcon /> {seats} Seats
                </h3>
              </div>
              <div className="service__luggage">
                <h3 className="h3 h3--1 colorlue">
                  <LuggageIcon /> {luggage} Luggage bag
                </h3>
              </div>
            </div>
            {!dashboardDisplay && (
              <>
                <div className="tags">
                  <div className="tags__safety">
                    <h3 className="h3">Safety Standards & Restriction</h3>
                  </div>
                  <div className="tags__payment">
                    <h3 className="h3">Partial Payment</h3>
                  </div>
                </div>

                <div className="checkmarks">
                  <p className="p p--1">
                    <CheckCircleIcon />
                    Free cancellation
                  </p>
                  <p className="p p--1">
                    <CheckCircleIcon />
                    24 / 7 customer helpline
                  </p>
                </div>
              </>
            )}
          </div>

          <div className="cab-card__price">
            <h2 className="h2--2" style={{ color: "black" }}>
              ₹ {parseInt(total)  + airTax}
            </h2>

            <>
              <button
                className="btn btn--1"
                onClick={() => {
                      let checkdata = JSON.parse(
                        localStorage.getItem("userData")
                      );
                   if (Object.keys(checkdata).length > 1) {
                     booknowbtn();
                   } else {
                     swal({
                       title: "Error!",
                       text: "Please Log In Then Book Now",
                       icon: "error",
                     });
                   }
                 
                }}
                disabled={loading}
              >
                {" "}
                {loading ? "Loading.." : "Book Now"}
              </button>
            </>
          </div>
        </div>
        <div className="cab-card-info" onClick={() => setShow(!show)}>
          <a>
            <h4>view more information </h4>
          </a>
          <KeyboardArrowDownIcon />
        </div>
        {show && <InfoCard />}
      </div>
    </>
  );
};

export default CabCard;
