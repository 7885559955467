import React, { useState, useEffect } from "react";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import LuggageIcon from "@mui/icons-material/Luggage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import pmlAPI from "../api/pmlAPI";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useRideValue } from "../context/rideContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "./Allcss/carcard.css";
import "./Allcss/CabCard.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import InfoCard from "./InfoCard";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";

const CabCard = ({
  carModel,
  seats,
  luggage,
  price,
  cabImage,
  dashboardDisplay,
  distance,
  hourlyRate,
  cabId,
  pricePerHour,
  cabData,
  checkReturn,
}) => {
  const [{ pickup, dropoff, passengers, date, TypeTravel, tax }, dispatch] =
    useRideValue();
  const [total, settotel] = useState(123);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [locationtype, setlocationtype] = useState();
  const [driver, setdriver] = useState(400);
  const [show, setShow] = useState(false);
  const [hourRate, setHourRate] = useState();
  const [taxCalculatehour, settaxCalculatehour] = useState();
  const [taxCalculateout, settaxCalculateout] = useState();
  const [noOfDay, setNoOFDay] = useState(0);

  let localData = JSON.parse(localStorage.getItem("travelDetail"));
  let CheckRoudTrip = JSON.parse(localStorage.getItem("roudCheck"));
  const matches = useMediaQuery("(min-width:600px)");
  const [CssSet, setCssSet] = useState({
    image: {},
    one: "",
    two: "",
    three: "",
  });

  ///Driver
  useEffect(() => {
    let driverPay = Number(cabData?.outstationdata?.driver_allowance) || 400;
    let FinaldriverPay = null;

    if (localData.TypeTravel == "outstation") {
      let datepick = JSON.parse(localStorage.getItem("travelDetail")).date;

      const date1 = new Date(datepick?.to);
      const date2 = new Date(datepick?.from);
      let oneDate = moment(date1).format("DD-MM-YYYY");
      let twoDate = moment(date2).format("DD-MM-YYYY");

      let dateone = moment(oneDate, "DD-MM-YYYY");
      let datetwo = moment(twoDate, "DD-MM-YYYY");

      let daysDiff = datetwo.diff(dateone, "days");

      const diffTime = Math.abs(date2 - date1);
      const diffDaysOne = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      var diffDays = 0;
      if (Number(diffDaysOne) > 1) {
        diffDays = Number(daysDiff) + 1;
      } else {
        diffDays = diffDaysOne;
      }

      if (Number(diffDays) <= 0) {
        FinaldriverPay = driverPay;
      } else {
        FinaldriverPay = driverPay * Number(diffDays);
      }
    } else {
      FinaldriverPay = 0;
    }

    setdriver(FinaldriverPay);
  }, []);

  //Trivel Type
  useEffect(() => {
    let data = String(
      JSON.parse(localStorage.getItem("travelDetail")).TypeTravel
    );
    setlocationtype(data);
  }, [TypeTravel]);

  /// outstation price calculation
  useEffect(() => {
    let datepick = JSON.parse(localStorage.getItem("travelDetail")).date;
    const distancenew = JSON.parse(
      localStorage.getItem("travelDetail")
    )?.distance;
    const date1 = new Date(datepick?.to);
    const date2 = new Date(datepick?.from);
    let oneDate = moment(date1).format("DD-MM-YYYY");
    let twoDate = moment(date2).format("DD-MM-YYYY");

    let dateone = moment(oneDate, "DD-MM-YYYY");
    let datetwo = moment(twoDate, "DD-MM-YYYY");

    let daysDiff = datetwo.diff(dateone, "days");

    const diffTime = Math.abs(date2 - date1);
    const diffDaysOne = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    var diffDays = 0;
    if (Number(diffDaysOne) > 1) {
      diffDays = Number(daysDiff) + 1;
    } else {
      diffDays = diffDaysOne;
    }

    let MinKm = Number(cabData?.outstationdata?.minkm);
    let perkmextra = Number(cabData?.outstationdata?.per_km_charge);
    let baseFare = Number(cabData?.outstationdata?.base_fare);

    let distantone = parseInt(distancenew / 1000) * 2;
    let distant = distantone + 50;

    let mainData = null;

    if (localData.TypeTravel == "outstation") {
      let distDiffrance = distant - MinKm;

      let checkextraPayment = Number(distDiffrance * perkmextra);

      if (Number(diffDays) > 0) {
        let checkOutSide = 250 * Number(diffDays) * perkmextra;
        let BasePlusExtra = baseFare + checkextraPayment;
        if (checkOutSide > BasePlusExtra) {
          mainData = checkOutSide;
        } else {
          mainData = BasePlusExtra;
        }
      } else {
        mainData = baseFare + checkextraPayment;
      }
    }

    settotel(mainData);
  }, []);

  /// /// outstation tax  calculation
  useEffect(() => {
    let datepick = JSON.parse(localStorage.getItem("travelDetail")).date;
    const distancenew = JSON.parse(
      localStorage.getItem("travelDetail")
    )?.distance;
    const date1 = new Date(datepick?.to);
    const date2 = new Date(datepick?.from);
    let oneDate = moment(date1).format("DD-MM-YYYY");
    let twoDate = moment(date2).format("DD-MM-YYYY");

    let dateone = moment(oneDate, "DD-MM-YYYY");
    let datetwo = moment(twoDate, "DD-MM-YYYY");

    let daysDiff = datetwo.diff(dateone, "days");

    const diffTime = Math.abs(date2 - date1);
    const diffDaysOne = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    var diffDays = 0;
    if (Number(diffDaysOne) > 1) {
      diffDays = Number(daysDiff) + 1;
    } else {
      diffDays = diffDaysOne;
    }

    let MinKm = Number(cabData?.outstationdata?.minkm);
    let perkmextra = Number(cabData?.outstationdata?.per_km_charge);
    let baseFare = Number(cabData?.outstationdata?.base_fare);
    let gst_value = Number(cabData?.outstationdata?.gst);

    let distantone = parseInt(distancenew / 1000) * 2;
    let distant = distantone + 50;

    let mainData = null;
    let maingst = null;

    if (localData.TypeTravel == "outstation") {
      let distDiffrance = distant - MinKm;

      let checkextraPayment = Number(distDiffrance * perkmextra);

      if (Number(diffDays) > 0) {
        let checkOutSide = 250 * Number(diffDays) * perkmextra;
        console.log(diffDays);
        let BasePlusExtra = baseFare + checkextraPayment;
        if (checkOutSide > BasePlusExtra) {
          mainData = checkOutSide;
        } else {
          mainData = BasePlusExtra;
        }
      } else {
        mainData = baseFare + checkextraPayment;
      }
    }

    maingst = parseInt((mainData / 100) * gst_value);
    settaxCalculateout(maingst);
  }, []);

  /// hourly  price calculation
  useEffect(() => {
    let two = cabData?.hourlydata?.two;
    let four = cabData?.hourlydata?.four;
    let six = cabData?.hourlydata?.six;
    let eight = cabData?.hourlydata?.eight;
    let ten = cabData?.hourlydata?.ten;
    let tewlev = cabData?.hourlydata?.tewlev;
    let mainData = null;
    switch (Number(localData && localData?.hourlyRate)) {
      case 2:
        mainData = two;
        break;
      case 4:
        mainData = four;
        break;
      case 6:
        mainData = six;
        break;
      case 8:
        mainData = eight;
        break;
      case 10:
        mainData = ten;
        break;
      case 12:
        mainData = tewlev;
    }

    setHourRate(parseInt(mainData));
  }, []);

  /// hourly  tax calculation
  useEffect(() => {
    let two = cabData?.hourlydata?.two;
    let four = cabData?.hourlydata?.four;
    let six = cabData?.hourlydata?.six;
    let eight = cabData?.hourlydata?.eight;
    let ten = cabData?.hourlydata?.ten;
    let tewlev = cabData?.hourlydata?.tewlev;

    let gst_value = Number(cabData?.hourlydata?.gst);

    let mainData = null;

    let maintax = null;
    switch (Number(localData && localData?.hourlyRate)) {
      case 2:
        mainData = two;
        break;
      case 4:
        mainData = four;
        break;
      case 6:
        mainData = six;
        break;
      case 8:
        mainData = eight;
        break;
      case 10:
        mainData = ten;
        break;
      case 12:
        mainData = tewlev;
    }

    maintax = parseInt((mainData / 100) * gst_value);

    settaxCalculatehour(maintax);
  }, []);

  ///Airport Data
  useEffect(() => {
    if (localData.TypeTravel == "Airport") {
      settotel(Number(cabData?.airportdata?.base_fare));
    } else if (localData.TypeTravel == "oneway") {
      settotel(Number(cabData?.onewaydata?.base_fare));
    }
  }, []);
  ///Airport Data Teaacc
  useEffect(() => {
    if (localData.TypeTravel == "Airport") {
      let maingst = parseInt(
        (Number(cabData?.airportdata?.base_fare) / 100) *
          Number(cabData?.airportdata?.gst)
      );

      settaxCalculateout(Number(maingst));
    } else if (localData.TypeTravel == "oneway") {
      let maingst = parseInt(
        (Number(cabData?.onewaydata?.base_fare) / 100) *
          Number(cabData?.onewaydata?.gst)
      );

      settaxCalculateout(Number(maingst));
    }
  }, []);

  const booknowbtn = async () => {
    let user = JSON.parse(localStorage.getItem("userData"));

    let traveldetails = JSON.parse(localStorage.getItem("travelDetail"));
    console.log(cabData?.airportdata);

    let cardDetails = {
      carModel,
      seats,
      luggage,
      price: total + taxCalculateout + driver || total + taxCalculateout,
      cabImage,
      dashboardDisplay,
      distance,
      cabId,
      pricePerHour: hourRate + taxCalculatehour,
    };

    let senddata = {
      data: {
        name: user?.name,
        email: user?.email,
        phone: "",
        pickUp:
          traveldetails.pickup.place_name ||
          cabData?.airportdata?.pickUp_location ||
          cabData?.onewaydata?.pickup,
        dropoff:
          traveldetails.dropoff.place_name ||
          cabData?.airportdata?.dropOff_location ||
          cabData?.onewaydata?.dropoff,
        pickupDetailAdress: "",
        dropoffDetailAdress: "",
        date: `${moment(traveldetails?.date?.to).format(
          "MMMM Do YYYY, h:mm a"
        )} To ${moment(traveldetails?.date?.from).format(
          "MMMM Do YYYY, h:mm a"
        )}`,
        passengers: {
          adults: traveldetails.passengers.adults,
          childern: traveldetails.passengers.children,
          infants: traveldetails.passengers.infants,
        },
        total: total + taxCalculateout + driver || total + taxCalculateout,
        TypeTravel: traveldetails.TypeTravel,
        pricePerHour: hourRate + taxCalculatehour,
        hourlyRate: hourlyRate,
        tax: tax,
      },

      useremail: user.email,
      cabid: cabId,
      userid: user.id,
    };

    let calculation = {
      hourInitial: hourRate,
      hourTax: taxCalculatehour,
      outInitial: total,
      outtax: taxCalculateout,
      outDriver: driver,
    };
    let extraData = {
      hourlyData: cabData?.hourlydata,
      outData:
        cabData?.outstationdata || cabData?.airportdata || cabData?.onewaydata,
    };

    let totaldata = {
      cardDetails,
      senddata,
      calculation,
      extraData,
    };

    if (
      traveldetails.TypeTravel == "outstation" &&
      Number.isNaN(total + taxCalculateout + driver)
    ) {
      window.location.href = "/";
    } else if (
      traveldetails.TypeTravel == "hourly" &&
      Number.isNaN(hourRate + taxCalculatehour)
    ) {
      window.location.href = "/";
    } else if (
      traveldetails.TypeTravel == "Airport" &&
      Number.isNaN(total + taxCalculateout)
    ) {
      window.location.href = "/";
    } else if (
      traveldetails.TypeTravel == "oneway" &&
      Number.isNaN(total + taxCalculateout)
    ) {
      window.location.href = "/";
    } else {
      localStorage.setItem("data", JSON.stringify(totaldata));
      navigate("/checkout");
    }
  };

  useEffect(() => {
    if (matches) {
      setCssSet({
        ...CssSet,
        image: {
          width: "200px",
        },
        one: "",
        two: "",
        three: "",
      });
    } else {
      setCssSet({
        ...CssSet,
        image: {
          width: "100%",
          height: "100%",
        },
        one: "53px",
        two: " 59px",
        three: "109px",
      });
    }
    console.log(matches);
  }, [matches]);

  return (
    <>
      <div className="all_cab_card">
        <div className="cab-card" key={cabId}>
          <div className="cab-card__image img_div_car">
            <img
              src={cabImage}
              alt="car 1"
              className="imgcar"
              style={CssSet.image}
            />
          </div>

          <div className="cab-card__details img_div_car">
            <h2 className="h2--2 cab__name cab_name_main_h2">
              {carModel.toUpperCase()}
            </h2>
            <div className="service">
              <div className="service__unit">
                <h3
                  className="h3 h3--1 colorlue "
                  style={{
                    width: CssSet.one,
                  }}
                >
                  <LocalTaxiIcon />{" "}
                  <span className="spanUnitCheck">1 Unit</span>
                </h3>
              </div>
              <div className="service__seats">
                <h3
                  className="h3 h3--1 colorlue "
                  style={{
                    width: CssSet.two,
                  }}
                >
                  <AirlineSeatReclineNormalIcon /> {seats} Seats
                </h3>
              </div>
              <div className="service__luggage">
                <h3
                  className="h3 h3--1 colorlue "
                  style={{
                    width: CssSet.three,
                  }}
                >
                  <LuggageIcon /> {luggage} Luggage bag
                </h3>
              </div>
            </div>
            {!dashboardDisplay && (
              <>
                <div className="tags">
                  <div className="tags__safety div_for_border_red">
                    <h3 className="h3 inner_hone_width">
                      Safety Standards & Restriction
                    </h3>
                  </div>
                  <div className="tags__payment">
                    <h3 className="h3 inner_hone_width">Partial Payment</h3>
                  </div>
                </div>

                <div className="checkmarks innerFreeMain_div">
                  <p className="p p--1 innertext">
                    <CheckCircleIcon />
                    Free cancellation
                  </p>
                  <p className="p p--1 innertext">
                    <CheckCircleIcon />
                    24 / 7 customer helpline
                  </p>
                </div>
              </>
            )}
          </div>

          <div className="cab-card__price">
            {locationtype == "hourly" && (
              <h2
                className="h2--2"
                style={{ color: "black", fontSize: "1.3vw" }}
              >
                ₹ {hourRate + taxCalculatehour}
              </h2>
            )}
            {locationtype == "outstation" && (
              <h2 className="h2--2" style={{ color: "black" }}>
                ₹{total + taxCalculateout + driver}
              </h2>
            )}
            {locationtype == "Airport" && (
              <h2 className="h2--2" style={{ color: "black" }}>
                ₹{Number(total) + taxCalculateout}
              </h2>
            )}
            {locationtype == "oneway" && (
              <h2 className="h2--2" style={{ color: "black" }}>
                ₹{Number(total) + taxCalculateout}
              </h2>
            )}

            <>
              <button
                className="button_check_is_here"
                onClick={() => {
                  let checkdata = JSON.parse(localStorage.getItem("userData"));

                  if (Object.keys(checkdata).length > 1) {
                    booknowbtn();
                  } else {
                    swal({
                      title: "Error!",
                      text: "Please LogIn To Book Cab",
                      icon: "error",
                    });
                    localStorage.setItem("route", JSON.stringify("/cabs"));
                    navigate("/login");
                  }
                }}
                disabled={loading}
              >
                {loading ? "Loading.." : "Book Now"}
              </button>
            </>
          </div>
        </div>
        <div
          className="cab-card-info div_inner_card_checkfor_bg"
          onClick={() => setShow(!show)}
        >
          <a>
            <h4 className="h4_check_view_css">view more information </h4>
          </a>
          <KeyboardArrowDownIcon
            style={{
              color: "#ee0014",
            }}
          />
        </div>
        {show && <InfoCard type={localData?.TypeTravel} data={cabData} />}
      </div>
    </>
  );
};

export default CabCard;
