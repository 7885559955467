import React, { useState, useEffect } from "react";
import "./scss/App.scss";

// Router
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import api from "./api/pmlAPI.js";

// Components
import Header from "./components/Header";
import BookingSection from "./components/BookingSection";
import Benefits from "./components/Benefits";
import TopRoutes from "./components/TopRoutes";
import International from "./components/International";
import Why from "./components/Why";
import Rates from "./components/Rates";
import Footer from "./components/Footer";
import "react-loading-skeleton/dist/skeleton.css";
// Pages
import CabsScreen from "./components/CabsScreen";
import DashboardScreen from "./components/DashboardScreen";
import AllCabs from "./components/AllCabs";
import Login from "./components/login";
import LoginPage from "./components/LoginPage";
import SignUp from "./components/SignUp";
import ChangePassword from "./components/ChangePassword";
import Aboutus from "./components/Aboutus";
import LocalSight from "./components/LocalSight";
import Checkout from "./components/Checkout";
import { jssPreset } from "@material-ui/core";
import Destination from "./Pages/Destinations/destination";
import SightSeeing from "./Pages/SightSeeing/SightSeeing";
import AirportPickUp from "./components/AirportPickUp";
import AirPortScreen from "./components/AirportSection/AirPortScreen";
import FianlePage from "./components/FinalPayment";
import HeaderNew from "./components/Header/HeaderNew";
import TopBox from "./components/TopBox/TopBox";
import PhotoScreen from "./components/PhotoScreen/PhotoScreen";
import Whyfleet from "./components/WhyFleet/Whyfleet";
import TopRoute from "./components/TopRoute/TopRoute";

/// ProTected Route
import Protect from "./components/privateRoute";

function App() {
  React.useEffect(async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;

    let data = {
      token: token,
    };
    try {
      if (token) {
        let res = await api.post(`/api/checktoken`, data);

        if (res.data.login) {
        } else {
          let data = {
            name: "",
          };

          localStorage.setItem("userData", JSON.stringify(data));
        }
      }
    } catch (error) {
      let data = {
        name: "",
      };

      localStorage.setItem("userData", JSON.stringify(data));
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        {/* <Header /> */}
        <HeaderNew />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <TopBox />
                <PhotoScreen />
                <Whyfleet />
                <TopRoute />
                {/* <BookingSection />
                <Benefits />
                <TopRoutes /> */}
                <International />
                {/* <Why />
                <Rates /> */}
                <Footer />
              </>
            }
          ></Route>
          <Route path="/aboutus" element={<Aboutus />}></Route>
          <Route path="/cabs" element={<CabsScreen />}></Route>
          <Route
            path="/local-sight-seeing/:package"
            element={<LocalSight />}
          ></Route>
          <Route path="/changepsd" element={<ChangePassword />}></Route>

          <Route
            path="/dashboard/*"
            element={
              <Protect>
                <DashboardScreen />
              </Protect>
            }
          ></Route>

          <Route path="/checkout" element={<Checkout />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/activities" element={<Destination />}></Route>
          <Route path="/airportpickup" element={<AirportPickUp />}></Route>
          <Route path="/airportCab" element={<AirPortScreen />}></Route>
          <Route path="/sights/:location" element={<SightSeeing />}></Route>

          <Route
            path="/finalPage/:orderid/:id"
            element={<FianlePage />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
