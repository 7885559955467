import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
// Router
import { useNavigate } from "react-router-dom";

// Contes
import { useRideValue } from "../context/rideContext";
import Footer from "./Footer";

// API
import pmlAPI from "../api/pmlAPI";

import CabCard from "./CabCard";
import "./Allcss/checkout.css";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import one from "../imges/one.svg";
import two from "../imges/two.svg";
import three from "../imges/three.svg";
import PinDropIcon from "@mui/icons-material/PinDrop";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttributionIcon from "@mui/icons-material/Attribution";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import LuggageIcon from "@mui/icons-material/Luggage";
import SideBarEdit from "./SideBarEdit";
import NewSideBar from "./NewSideBar/NewSideBar";
import useMediaQuery from "@mui/material/useMediaQuery";

const CabsScreen = () => {
  const [
    {
      pickup,
      dropoff,
      passengers,
      date,
      TypeTravel,
      distance,
      duration,
      hourlyRate,
      check,
    },
    dispatch,
  ] = useRideValue();
  const [cabs, setCabs] = useState([]);
  const [backupCab, setbackupCab] = useState([]);
  const [loading, setLoading] = useState(true);
  const [travel, settravel] = useState([]);
  const [traveltype, settraveltype] = useState("outstation");
  const [fueltype, setfueltype] = useState([]);
  const [fuleTypeCheckBoxArray, setfuleTypeCheckBoxArray] = useState([]);
  const [cabTypeCheckBoxArray, setCabTypeCheckBoxArray] = useState([]);
  const [showEdit, setShowEdit] = useState(false);

  const [activeCabType, setActiveCabType] = useState();
  const [activefuleType, setActiveFuleType] = useState();

  const [cabType, setCabType] = useState();
  const [distanceState, setDistanceState] = useState(0);
  const [distanceCal, setdistanceCal] = useState();
  const [AirportDataAll, setAirportDataAll] = useState([]);
  const [OneWayDataAll, setOneWayDataAll] = useState([]);
  const matches = useMediaQuery("(min-width:897px)");
  const navigate = useNavigate();

  useEffect(() => {
    let AirData = JSON.parse(localStorage.getItem("airportData"));
    let OneWayData = JSON.parse(localStorage.getItem("onewayData"));
    console.log(AirData);
    setAirportDataAll(AirData);
    setOneWayDataAll(OneWayData);
  }, []);

  const FilterByFuelType = (type, data) => {
    if (String(data) == "Any" && type) {
      setCabs(backupCab);
    } else {
      let Array = [];
      backupCab?.map((value) => {
        return fuleTypeCheckBoxArray?.map((w) => {
          if (w == value.fule_type) {
            Array.push(value);
          }
        });
      });
      if (fuleTypeCheckBoxArray.length == 0) {
        setCabs(backupCab);
      } else {
        setCabs(Array);
      }
    }
  };

  const FuelTypeFunction = (data) => {
    let fuleArray = data?.map((value) => {
      return value?.fule_type;
    });

    var filtered = fuleArray.filter(function (x) {
      return x !== undefined;
    });

    filtered.push("Any");

    let uniqueChars = [...new Set(filtered)];

    setfueltype(uniqueChars);
  };

  const CabTypeCheck = (data) => {
    let fuleArray = data?.map((value) => {
      return value?.cab_type;
    });

    var filtered = fuleArray.filter(function (x) {
      return x !== undefined;
    });

    filtered.push("Any");

    let uniqueChars = [...new Set(filtered)];
    setCabType(uniqueChars);
  };

  useEffect(() => {
    const tra = JSON.parse(localStorage.getItem("travelDetail"));
    settraveltype(tra.TypeTravel);
    settravel(tra);
  }, []);

  const onPageLoad = useCallback(async () => {
    let localdata = JSON.parse(localStorage.getItem("travelDetail"));

    let typeoftravel = localdata?.pickup?.text?.toLowerCase();
    let totalpassanger =
      Number(localdata?.passengers?.adults) +
      Number(localdata?.passengers?.children) +
      Number(localdata?.passengers?.infants);

    await pmlAPI
      .get("/api/cabs")
      .then((res) => {
        const myArray = typeoftravel?.split(" ");
        let DataFOrFilter = null;
        if (localdata?.TypeTravel == "outstation") {
          DataFOrFilter = res?.data?.cab?.filter((w) => {
            return w.outstation;
          });
        } else if (localdata?.TypeTravel == "oneway") {
          let FilterAir = res?.data?.cab?.filter((w) => {
            return w.oneway;
          });
          DataFOrFilter = FilterAir;
        } else if (localdata?.TypeTravel == "Airport") {
          let FilterAir = res?.data?.cab?.filter((w) => {
            return w.airport;
          });
          DataFOrFilter = FilterAir;
        } else if (localdata?.TypeTravel == "hourly") {
          let filterData = res?.data?.cab?.filter((w) => {
            return w?.hourly;
          });

          DataFOrFilter = filterData;
        } else {
          DataFOrFilter = [];
        }

        if (
          localdata?.TypeTravel == "outstation" ||
          localdata?.TypeTravel == "hourly"
        ) {
          for (let i = 0; i < myArray?.length; i++) {
            let arrfi = myArray[i]?.toLowerCase();

            let data = DataFOrFilter?.filter((e) => {
              let city = e?.city?.toLowerCase();
              let region = e?.region?.toLowerCase();

              return (
                (city.includes(arrfi) &&
                  Number(totalpassanger) <= Number(e.seats)) ||
                (region.includes(arrfi) &&
                  Number(totalpassanger) <= Number(e.seats))
              );
            });

            if (data?.length == 0) {
            } else {
              setCabs(data);
              setbackupCab(data);
              FuelTypeFunction(data);
              CabTypeCheck(data);
            }
          }
        } else if (localdata?.TypeTravel == "Airport") {
          let AirData = JSON.parse(localStorage.getItem("airportData"));

          let fileterCab = DataFOrFilter?.filter((w) => {
            return (
              w?.airportdata?.pickUp_location == AirData?.pickup &&
              AirData?.dropoff == w?.airportdata?.dropOff_location &&
              Number(totalpassanger) <= Number(w.seats)
            );
          });

          setCabs(fileterCab);
          setbackupCab(fileterCab);
          FuelTypeFunction(fileterCab);
          CabTypeCheck(fileterCab);
        } else if (localdata?.TypeTravel == "oneway") {
          let oneWayData = JSON.parse(localStorage.getItem("onewayData"));

          let fileterCab = DataFOrFilter?.filter((w) => {
            return (
              w?.onewaydata?.pickup == oneWayData?.pickup &&
              oneWayData?.dropOff == w?.onewaydata?.dropoff &&
              Number(totalpassanger) <= Number(w.seats)
            );
          });

          setCabs(fileterCab);
          setbackupCab(fileterCab);
          FuelTypeFunction(fileterCab);
          CabTypeCheck(fileterCab);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [travel]);

  useEffect(() => {
    if (!pickup || !date) navigate("/");
    onPageLoad();
  }, [pickup, date, navigate, onPageLoad]);

  const ResetAllFunction = (data) => {
    setActiveFuleType("");
    setActiveCabType("");
    setCabs(backupCab);
  };

  const FilterCabType = (w) => {
    if (w == "Any") {
      setCabs(backupCab);
    } else {
      let checkData = backupCab?.filter((r) => {
        return r?.cab_type == w;
      });
      setCabs(checkData);
    }
  };

  const FilterFuleType = (value) => {
    if (value == "Any") {
      setCabs(backupCab);
    } else {
      let checkData = backupCab?.filter((r) => {
        return r?.fule_type == value;
      });

      setCabs(checkData);
    }
  };

  useEffect(() => {
    let datepick = JSON.parse(localStorage.getItem("travelDetail")).date;
    const distancenew = JSON.parse(
      localStorage.getItem("travelDetail")
    )?.distance;

    const date1 = new Date(datepick?.to);
    const date2 = new Date(datepick?.from);
    let oneDate = moment(date1).format("DD-MM-YYYY");
    let twoDate = moment(date2).format("DD-MM-YYYY");

    let dateone = moment(oneDate, "DD-MM-YYYY");
    let datetwo = moment(twoDate, "DD-MM-YYYY");

    let daysDiff = datetwo.diff(dateone, "days");

    const diffTime = Math.abs(date2 - date1);
    const diffDaysOne = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    var diffDays = 0;
    if (Number(diffDaysOne) > 1) {
      diffDays = Number(daysDiff) + 1;
    } else {
      diffDays = diffDaysOne;
    }

    let distantone = parseInt(distancenew / 1000) * 2;
    let distant = distantone + 50;
    let disCal = null;

    if (diffDays <= 0) {
      disCal = 250 * 1;
    } else {
      disCal = 250 * Number(diffDays);
    }
    console.log(disCal);
    setdistanceCal(disCal);
    setDistanceState(distant);
  }, []);

  return (
    <>
      <div className="cab-screen new_div_for_margin">
        {matches ? (
          <div className="cab-grid">
            <div className="cab_card_leftside ">
              <div className="ride-info ">
                <div
                  className="ride-info__title"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "default",
                    fontSize: "12px",
                    alignItems: "center",
                  }}
                >
                  <h2 className="h2--2">Your Transfer</h2>
                  <div
                    className="divEditAndShow"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      background: "bule",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowEdit(!showEdit);
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                      }}
                    >
                      {showEdit ? "Show Trip" : "Edit Trip"}
                    </span>
                  </div>
                </div>
              </div>

              {showEdit ? (
                <>
                  <NewSideBar />
                </>
              ) : (
                <div className="div_for_minus_margin">
                  <div className="ride-info ">
                    <div
                      className="ride-info__pickup "
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div style={{ display: "flex" }}>
                        <PinDropIcon
                          sx={{
                            position: "relative",
                            top: "-5px",
                            width: "25px",
                            height: "22px",
                            color: "#ffd60a",
                            marginRight: "2px",
                          }}
                        />
                        <h3 className="h3 h3--1 super_head heading_bold_css">
                          Pick-up location
                        </h3>
                      </div>
                      <div style={{ marginLeft: "25px" }}>
                        <h3 className="h3">
                          {travel?.TypeTravel == "Airport"
                            ? AirportDataAll?.pickup
                            : travel?.TypeTravel == "oneway"
                            ? OneWayDataAll?.pickup
                            : travel?.pickup?.place_name}
                        </h3>
                      </div>
                    </div>

                    {travel?.TypeTravel !== "hourly" && (
                      <div
                        className="ride-info__dropoff"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <PinDropIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "#ffd60a",
                              marginRight: "2px",
                            }}
                          />
                          <h3 className="h3 h3--1 super_head heading_bold_css">
                            {travel?.TypeTravel == "outstation"
                              ? "Destination"
                              : " Drop-off location"}
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">
                            {travel?.TypeTravel == "Airport"
                              ? AirportDataAll?.dropoff
                              : travel?.TypeTravel == "oneway"
                              ? OneWayDataAll?.dropOff
                              : travel?.dropoff?.place_name}
                          </h3>
                        </div>
                      </div>
                    )}

                    <div
                      className="ride-info__date"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div style={{ display: "flex" }}>
                        <CalendarTodayIcon
                          sx={{
                            position: "relative",
                            top: "-5px",
                            width: "25px",
                            height: "22px",
                            color: "#ffd60a",
                            marginRight: "2px",
                          }}
                        ></CalendarTodayIcon>
                        <h3 className="h3 h3--1 super_head heading_bold_css">
                          Pick-up Date & Time
                        </h3>
                      </div>
                      <div style={{ marginLeft: "25px" }}>
                        <h3 className="h3">
                          {moment(travel?.date?.to).format(
                            "MMMM Do YYYY, h:mm a"
                          )}
                        </h3>
                      </div>
                    </div>

                    {travel?.TypeTravel == "outstation" && (
                      <div
                        className="ride-info__date"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <CalendarTodayIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "#ffd60a",
                              marginRight: "2px",
                            }}
                          ></CalendarTodayIcon>
                          <h3 className="h3 h3--1 super_head heading_bold_css">
                            Return Date & Time
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">
                            {moment(travel?.date?.from).format(
                              "MMMM Do YYYY, h:mm a"
                            )}
                          </h3>
                        </div>
                      </div>
                    )}
                    {travel?.TypeTravel == "hourly" ? (
                      <>
                        <div
                          className="ride-info__passengers"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ display: "flex" }}>
                            <AccessTimeIcon
                              sx={{
                                position: "relative",
                                top: "-5px",
                                width: "25px",
                                height: "22px",
                                color: "#ffd60a",
                                marginRight: "2px",
                              }}
                            />
                            <h3 className="h3 h3--1 super_head heading_bold_css">
                              Booking Hour
                            </h3>
                          </div>
                          <div style={{ marginLeft: "25px" }}>
                            <h3 className="h3">{travel?.hourlyRate} hour</h3>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div
                      className="ride-info__passengers"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div style={{ display: "flex" }}>
                        <PermIdentityIcon
                          sx={{
                            position: "relative",
                            top: "-5px",
                            width: "25px",
                            height: "22px",
                            color: "#ffd60a",
                            marginRight: "2px",
                          }}
                        />
                        <h3 className="h3 h3--1 super_head heading_bold_css">
                          Passengers
                        </h3>
                      </div>
                      <div style={{ marginLeft: "25px" }}>
                        <h3 className="h3">
                          {travel?.passengers?.adults} adt,{" "}
                          {travel?.passengers?.children} chd,{" "}
                          {travel?.passengers?.infants} inf
                        </h3>
                      </div>
                    </div>

                    {travel?.TypeTravel == "outstation" && (
                      <div
                        className="ride-info__passengers"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <AttributionIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "#ffd60a",
                              marginRight: "2px",
                            }}
                          />
                          <h3 className="h3 h3--1 super_head heading_bold_css">
                            Distance
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">
                            {distanceState > distanceCal
                              ? distanceState
                              : distanceCal}
                            KM
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="right_banner">
                <div className="div_heading_check">
                  <div className="heading_div_card">
                    <h3 className="h3_css_newCard">Feature</h3>
                  </div>

                  <div className="div_card_sction_check">
                    <img src={one} className="img_csss_check" />
                    <div>
                      <h4 className="new_div_heading_hfour">
                        Unlock exclusive benefits
                      </h4>
                      <p className="div_para_css_check">
                        Get amazing discounts on the first ride and additional
                        on others
                      </p>
                    </div>
                  </div>
                  <div className="div_card_sction_check">
                    <img src={two} className="img_csss_check" />
                    <div>
                      <h4 className="new_div_heading_hfour">
                        Track & Manage your bookings from one place
                      </h4>
                      <p className="div_para_css_check">
                        You can keep a track of your cab & manage all bookings
                        from one place
                      </p>
                    </div>
                  </div>
                  <div className="div_card_sction_check">
                    <img src={three} className="img_csss_check" />
                    <div>
                      <h4 className="new_div_heading_hfour">
                        Book faster with the saved details
                      </h4>
                      <p className="div_para_css_check">
                        The saved details can be used again to book your cab
                        ride
                      </p>
                    </div>
                  </div>
                </div>
                <Card style={{ width: "30rem" }}></Card>
              </div>
              <div className=" filter_card">
                <div className="ttl_sec">
                  <h3>Filter</h3>
                  <button
                    onClick={() => {
                      ResetAllFunction();
                    }}
                  >
                    Reset All
                  </button>
                </div>
                <form>
                  <div className="panel">
                    <div className="hx1">Cab Type</div>
                    {cabType?.map((w, i) => {
                      return (
                        <div className="fuel_check">
                          <input
                            type="checkbox"
                            id="vehicle2"
                            name="vehicle2"
                            value={w}
                            checked={activeCabType == w ? true : false}
                            onChange={(e) => {
                              setActiveCabType(w);
                              FilterCabType(w);
                            }}
                          />
                          <label for="vehicle2">{w}</label>
                          {/* <span className="fltr">{i+4}</span> */}
                        </div>
                      );
                    })}
                  </div>

                  <div className="panel">
                    <div className="hx1">Fuel Type</div>
                    {fueltype?.map((w) => {
                      return (
                        <>
                          <div className="fuel_check" key={w}>
                            <input
                              type="checkbox"
                              id="vehicle"
                              name="vehicle"
                              value={w}
                              checked={activefuleType == w ? true : false}
                              onClick={(e) => {
                                setActiveFuleType(w);
                                FilterFuleType(w);
                              }}
                            />
                            <label for="vehicle1">{w}</label>
                            {/* <span className="fltr">4</span> */}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </form>
              </div>
              <div className="top_info_n">
                <div className="box-new_in"></div>
              </div>
            </div>

            <div className="cab-info  ">
              {!loading && (
                <>
                  {cabs?.map((cab, index) => (
                    <CabCard
                      key={`${index}`}
                      carModel={cab?.carModel}
                      seats={cab?.seats}
                      luggage={cab?.luggage}
                      cabImage={cab?.cabImage}
                      cabId={cab?.userId}
                      distance={travel?.distance}
                      hourlyRate={travel.hourlyRate}
                      region={cab?.region}
                      cabData={cab}
                    />
                  ))}
                </>
              )}
              {!loading && cabs.length === 0 && (
                <div className="all_cab_card">
                  <div className="cab-card">
                    <div className="cab-card__image">
                      <img
                        src="https://www.how-to-draw-funny-cartoons.com/images/how-to-draw-a-cab-008.jpg"
                        alt="car 1"
                        className="imgcar"
                      />
                    </div>

                    <div className="cab-card__details">
                      <h2 className="h2--2 cab__name">No Cab Found</h2>
                    </div>
                  </div>
                </div>
              )}
              {loading && (
                <>
                  <div className="cab-card">
                    <div className="cab-card__image">
                      <Skeleton height="200px" width="200px" />
                    </div>

                    <div className="cab-card__details">
                      <h2 className="h2--2 ">
                        <Skeleton count={3} />
                      </h2>
                    </div>

                    <div className="cab-card__price">
                      <h2 className="h2--2">
                        <Skeleton count={2} />
                      </h2>
                    </div>
                  </div>
                  <div className="cab-card">
                    <div className="cab-card__image">
                      <Skeleton height="200px" width="200px" />
                    </div>

                    <div className="cab-card__details">
                      <h2 className="h2--2">
                        <Skeleton count={3} />
                      </h2>
                    </div>

                    <div className="cab-card__price">
                      <h2 className="h2--2">
                        <Skeleton count={2} />
                      </h2>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="cab-grid">
            <div className="cab_card_leftside ">
              <div className="cab-info  ">
                {!loading && (
                  <>
                    {cabs?.map((cab, index) => (
                      <CabCard
                        key={`${index}`}
                        carModel={cab?.carModel}
                        seats={cab?.seats}
                        luggage={cab?.luggage}
                        cabImage={cab?.cabImage}
                        cabId={cab?.userId}
                        distance={travel?.distance}
                        hourlyRate={travel.hourlyRate}
                        region={cab?.region}
                        cabData={cab}
                      />
                    ))}
                  </>
                )}
                {!loading && cabs.length === 0 && (
                  <div className="all_cab_card">
                    <div className="cab-card">
                      <div className="cab-card__image">
                        <img
                          src="https://www.how-to-draw-funny-cartoons.com/images/how-to-draw-a-cab-008.jpg"
                          alt="car 1"
                          className="imgcar"
                        />
                      </div>

                      <div className="cab-card__details">
                        <h2 className="h2--2 cab__name">No Cab Found</h2>
                      </div>
                    </div>
                  </div>
                )}
                {loading && (
                  <>
                    <div className="cab-card">
                      <div className="cab-card__image">
                        <Skeleton height="200px" width="200px" />
                      </div>

                      <div className="cab-card__details">
                        <h2 className="h2--2 ">
                          <Skeleton count={3} />
                        </h2>
                      </div>

                      <div className="cab-card__price">
                        <h2 className="h2--2">
                          <Skeleton count={2} />
                        </h2>
                      </div>
                    </div>
                    <div className="cab-card">
                      <div className="cab-card__image">
                        <Skeleton height="200px" width="200px" />
                      </div>

                      <div className="cab-card__details">
                        <h2 className="h2--2">
                          <Skeleton count={3} />
                        </h2>
                      </div>

                      <div className="cab-card__price">
                        <h2 className="h2--2">
                          <Skeleton count={2} />
                        </h2>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="ride-info ">
                <div
                  className="ride-info__title"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "default",
                    fontSize: "12px",
                    alignItems: "center",
                  }}
                >
                  <h2 className="h2--2">Your Transfer</h2>
                  <div
                    className="divEditAndShow"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      background: "bule",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowEdit(!showEdit);
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                      }}
                    >
                      {showEdit ? "Show Trip" : "Edit Trip"}
                    </span>
                  </div>
                </div>
              </div>

              {showEdit ? (
                <>
                  <NewSideBar />
                </>
              ) : (
                <div className="div_for_minus_margin">
                  <div className="ride-info ">
                    <div
                      className="ride-info__pickup "
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div style={{ display: "flex" }}>
                        <PinDropIcon
                          sx={{
                            position: "relative",
                            top: "-5px",
                            width: "25px",
                            height: "22px",
                            color: "#ffd60a",
                            marginRight: "2px",
                          }}
                        />
                        <h3 className="h3 h3--1 super_head heading_bold_css">
                          Pick-up location
                        </h3>
                      </div>
                      <div style={{ marginLeft: "25px" }}>
                        <h3 className="h3">
                          {travel?.TypeTravel == "Airport"
                            ? AirportDataAll?.pickup
                            : travel?.TypeTravel == "oneway"
                            ? OneWayDataAll?.pickup
                            : travel?.pickup?.place_name}
                        </h3>
                      </div>
                    </div>

                    {travel?.TypeTravel !== "hourly" && (
                      <div
                        className="ride-info__dropoff"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <PinDropIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "#ffd60a",
                              marginRight: "2px",
                            }}
                          />
                          <h3 className="h3 h3--1 super_head heading_bold_css">
                            {travel?.TypeTravel == "outstation"
                              ? "Destination"
                              : " Drop-off location"}
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">
                            {travel?.TypeTravel == "Airport"
                              ? AirportDataAll?.dropoff
                              : travel?.TypeTravel == "oneway"
                              ? OneWayDataAll?.dropOff
                              : travel?.dropoff?.place_name}
                          </h3>
                        </div>
                      </div>
                    )}

                    <div
                      className="ride-info__date"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div style={{ display: "flex" }}>
                        <CalendarTodayIcon
                          sx={{
                            position: "relative",
                            top: "-5px",
                            width: "25px",
                            height: "22px",
                            color: "#ffd60a",
                            marginRight: "2px",
                          }}
                        ></CalendarTodayIcon>
                        <h3 className="h3 h3--1 super_head heading_bold_css">
                          Pick-up Date & Time
                        </h3>
                      </div>
                      <div style={{ marginLeft: "25px" }}>
                        <h3 className="h3">
                          {moment(travel?.date?.to).format(
                            "MMMM Do YYYY, h:mm a"
                          )}
                        </h3>
                      </div>
                    </div>

                    {travel?.TypeTravel == "outstation" && (
                      <div
                        className="ride-info__date"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <CalendarTodayIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "#ffd60a",
                              marginRight: "2px",
                            }}
                          ></CalendarTodayIcon>
                          <h3 className="h3 h3--1 super_head heading_bold_css">
                            Return Date & Time
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">
                            {moment(travel?.date?.from).format(
                              "MMMM Do YYYY, h:mm a"
                            )}
                          </h3>
                        </div>
                      </div>
                    )}
                    {travel?.TypeTravel == "hourly" ? (
                      <>
                        <div
                          className="ride-info__passengers"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ display: "flex" }}>
                            <AccessTimeIcon
                              sx={{
                                position: "relative",
                                top: "-5px",
                                width: "25px",
                                height: "22px",
                                color: "#ffd60a",
                                marginRight: "2px",
                              }}
                            />
                            <h3 className="h3 h3--1 super_head heading_bold_css">
                              Booking Hour
                            </h3>
                          </div>
                          <div style={{ marginLeft: "25px" }}>
                            <h3 className="h3">{travel?.hourlyRate} hour</h3>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div
                      className="ride-info__passengers"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div style={{ display: "flex" }}>
                        <PermIdentityIcon
                          sx={{
                            position: "relative",
                            top: "-5px",
                            width: "25px",
                            height: "22px",
                            color: "#ffd60a",
                            marginRight: "2px",
                          }}
                        />
                        <h3 className="h3 h3--1 super_head heading_bold_css">
                          Passengers
                        </h3>
                      </div>
                      <div style={{ marginLeft: "25px" }}>
                        <h3 className="h3">
                          {travel?.passengers?.adults} adt,{" "}
                          {travel?.passengers?.children} chd,{" "}
                          {travel?.passengers?.infants} inf
                        </h3>
                      </div>
                    </div>

                    {travel?.TypeTravel == "outstation" && (
                      <div
                        className="ride-info__passengers"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <AttributionIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "#ffd60a",
                              marginRight: "2px",
                            }}
                          />
                          <h3 className="h3 h3--1 super_head heading_bold_css">
                            Distance
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">
                            {distanceState > distanceCal
                              ? distanceState
                              : distanceCal}
                            KM
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className=" filter_card">
                <div className="ttl_sec">
                  <h3>Filter</h3>
                  <button
                    onClick={() => {
                      ResetAllFunction();
                    }}
                  >
                    Reset All
                  </button>
                </div>
                <form>
                  <div className="panel">
                    <div className="hx1">Cab Type</div>
                    {cabType?.map((w, i) => {
                      return (
                        <div className="fuel_check">
                          <input
                            type="checkbox"
                            id="vehicle2"
                            name="vehicle2"
                            value={w}
                            checked={activeCabType == w ? true : false}
                            onChange={(e) => {
                              setActiveCabType(w);
                              FilterCabType(w);
                            }}
                          />
                          <label for="vehicle2">{w}</label>
                          {/* <span className="fltr">{i+4}</span> */}
                        </div>
                      );
                    })}
                  </div>

                  <div className="panel">
                    <div className="hx1">Fuel Type</div>
                    {fueltype?.map((w) => {
                      return (
                        <>
                          <div className="fuel_check" key={w}>
                            <input
                              type="checkbox"
                              id="vehicle"
                              name="vehicle"
                              value={w}
                              checked={activefuleType == w ? true : false}
                              onClick={(e) => {
                                setActiveFuleType(w);
                                FilterFuleType(w);
                              }}
                            />
                            <label for="vehicle1">{w}</label>
                            {/* <span className="fltr">4</span> */}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </form>
              </div>

              <div className="right_banner margin_loss">
                <div className="div_heading_check">
                  <div className="heading_div_card">
                    <h3 className="h3_css_newCard">Feature</h3>
                  </div>

                  <div className="div_card_sction_check">
                    <img src={one} className="img_csss_check" />
                    <div>
                      <h4 className="new_div_heading_hfour">
                        Unlock exclusive benefits
                      </h4>
                      <p className="div_para_css_check">
                        Get amazing discounts on the first ride and additional
                        on others
                      </p>
                    </div>
                  </div>
                  <div className="div_card_sction_check">
                    <img src={two} className="img_csss_check" />
                    <div>
                      <h4 className="new_div_heading_hfour">
                        Track & Manage your bookings from one place
                      </h4>
                      <p className="div_para_css_check">
                        You can keep a track of your cab & manage all bookings
                        from one place
                      </p>
                    </div>
                  </div>
                  <div className="div_card_sction_check">
                    <img src={three} className="img_csss_check" />
                    <div>
                      <h4 className="new_div_heading_hfour">
                        Book faster with the saved details
                      </h4>
                      <p className="div_para_css_check">
                        The saved details can be used again to book your cab
                        ride
                      </p>
                    </div>
                  </div>
                </div>
                <Card style={{ width: "30rem" }}></Card>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default CabsScreen;
