import "./RateModifier.css";
import React, { useState, useEffect } from "react";
import { RichTextEditor } from "@mantine/rte";
import indianCitiesDatabase from "indian-cities-database";
import pmlAPI from "../../../api/pmlAPI";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const RateModifier = () => {
  const citess = indianCitiesDatabase.cities;
  let { id } = useParams();
  const navigate = useNavigate();
  let allstate = [
    {
      abbreviation: "nn",
      name: "Please Select State",
    },
    {
      abbreviation: "nn",
      name: "Please Select State",
    },
    {
      abbreviation: "AN",
      name: "Andaman and Nicobar Islands",
    },
    {
      abbreviation: "AP",
      name: "Andhra Pradesh",
    },
    {
      abbreviation: "AR",
      name: "Arunachal Pradesh",
    },
    {
      abbreviation: "AS",
      name: "Assam",
    },
    {
      abbreviation: "BR",
      name: "Bihar",
    },
    {
      abbreviation: "CG",
      name: "Chandigarh",
    },
    {
      abbreviation: "CH",
      name: "Chhattisgarh",
    },
    {
      abbreviation: "DH",
      name: "Dadra and Nagar Haveli",
    },
    {
      abbreviation: "DD",
      name: "Daman and Diu",
    },
    {
      abbreviation: "DL",
      name: "Delhi",
    },
    {
      abbreviation: "GA",
      name: "Goa",
    },
    {
      abbreviation: "GJ",
      name: "Gujarat",
    },
    {
      abbreviation: "HR",
      name: "Haryana",
    },
    {
      abbreviation: "HP",
      name: "Himachal Pradesh",
    },
    {
      abbreviation: "JK",
      name: "Jammu and Kashmir",
    },
    {
      abbreviation: "JH",
      name: "Jharkhand",
    },
    {
      abbreviation: "KA",
      name: "Karnataka",
    },
    {
      abbreviation: "KL",
      name: "Kerala",
    },
    {
      abbreviation: "LD",
      name: "Lakshadweep",
    },
    {
      abbreviation: "MP",
      name: "Madhya Pradesh",
    },
    {
      abbreviation: "MH",
      name: "Maharashtra",
    },
    {
      abbreviation: "MN",
      name: "Manipur",
    },
    {
      abbreviation: "ML",
      name: "Meghalaya",
    },
    {
      abbreviation: "MZ",
      name: "Mizoram",
    },
    {
      abbreviation: "NL",
      name: "Nagaland",
    },
    {
      abbreviation: "OR",
      name: "Odisha",
    },
    {
      abbreviation: "PY",
      name: "Puducherry",
    },
    {
      abbreviation: "PB",
      name: "Punjab",
    },
    {
      abbreviation: "RJ",
      name: "Rajasthan",
    },
    {
      abbreviation: "SK",
      name: "Sikkim",
    },
    {
      abbreviation: "TN",
      name: "Tamil Nadu",
    },
    {
      abbreviation: "TS",
      name: "Telangana",
    },
    {
      abbreviation: "TR",
      name: "Tripura",
    },
    {
      abbreviation: "UP",
      name: "Uttar Pradesh",
    },
    {
      abbreviation: "UK",
      name: "Uttarakhand",
    },
    {
      abbreviation: "WB",
      name: "West Bengal",
    },
  ];

  const [cabData, setcabData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).id,
    carModel: "",
    luggage: "",
    seats: "",
    cabImage: "",
    region: "",
    city: "",
    fule_type: "",
    cab_type: "",
    oneway: true,
    onewaydata: {},
  });

  const [addoneway, setAddOneWay] = useState({
    base_fare: "",
    gst: "",
    term_and_condition: "",
    cancellation: "",
    pickupState: "",
    pickupCity: "",
    dropoffState: "",
    dropoffCity: "",
    pickup: "",
    dropoff: "",
  });

  const [citeies, setciteies] = useState([]);
  const [citiesTwo, setCitiesTwo] = useState([]);
  const [AllCabPool, setAllCabPool] = useState([]);
  const [cabName, setCabName] = useState([]);

  const handleSearchScityOne = (value) => {
    let data = citess?.filter((w) => {
      return w.state == value;
    });

    if (data?.length > 0) {
      let addvalue = {
        city: "Please Select City ",
        state: "Please Select City",
      };
      data?.unshift(addvalue);
    }

    setciteies(data);
  };
  const handleSearchScityTwo = (value) => {
    let data = citess?.filter((w) => {
      return w.state == value;
    });

    if (data?.length > 0) {
      let addvalue = {
        city: "Please Select City ",
        state: "Please Select City",
      };
      data?.unshift(addvalue);
    }

    setCitiesTwo(data);
  };

  useEffect(() => {
    pmlAPI
      .get(`/api/cabpool`)
      .then((res) => {
        setAllCabPool(res.data);
        let selectData = res?.data?.map((w) => {
          return {
            carName: w?.carModel,
            id: w?._id,
          };
        });

        setCabName(selectData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(async () => {
    try {
      let res = await pmlAPI.get(`/api/cabs/${id}`);
      console.log(res?.data?.data);

      setcabData({
        ...cabData,
        userId: res?.data?.data?.userId,
        carModel: res?.data?.data?.carModel,
        luggage: res?.data?.data?.luggage,
        seats: res?.data?.data?.seats,
        cabImage: res?.data?.data?.cabImage,
        region: res?.data?.data?.region,
        city: res?.data?.data?.city,
        fule_type: res?.data?.data?.fule_type,
        cab_type: res?.data?.data?.cab_type,
        airport: res?.data?.data?.airport,
        airportdata: {},
        oneway: res?.data?.data?.oneway || true,
        onewaydata: {},
      });

      setAddOneWay((pre) => {
        return {
          ...pre,
          base_fare: res?.data?.data?.onewaydata?.base_fare,
          gst: res?.data?.data?.onewaydata?.gst,
          pickupState: res?.data?.data?.onewaydata?.pickupState,
          pickupCity: res?.data?.data?.onewaydata?.pickupCity,
          dropoffState: res?.data?.data?.onewaydata?.dropoffState,
          dropoffCity: res?.data?.data?.onewaydata?.dropoffCity,
          pickup: res?.data?.data?.onewaydata?.pickup,
          dropoff: res?.data?.data?.onewaydata?.dropoff,
        };
      });

      setAddOneWay((pre) => {
        return {
          ...pre,
          term_and_condition: res?.data?.data?.onewaydata?.term_and_condition,
        };
      });
      setAddOneWay((pre) => {
        return {
          ...pre,
          cancellation: res?.data?.data?.onewaydata?.cancellation,
        };
      });

      let Pickities = citess?.filter((w) => {
        return w.state == res?.data?.data?.onewaydata?.pickupState;
      });
      let DropCities = citess?.filter((w) => {
        return w.state == res?.data?.data?.onewaydata?.dropoffState;
      });

      if (Pickities?.length > 0) {
        let addvalue = {
          city: "Please Select City ",
          state: "Please Select City",
        };
        Pickities?.unshift(addvalue);
      }
      if (DropCities?.length > 0) {
        let addvalue = {
          city: "Please Select City ",
          state: "Please Select City",
        };
        DropCities?.unshift(addvalue);
      }
      setciteies(Pickities);
      setCitiesTwo(DropCities);
    } catch (error) {
      console.log(error);
    }
  }, [id]);
  ///// handle Sweet Alert
  const handleSweeyAlert = (title, text, icon) => {
    swal({
      title: title,
      text: text,
      icon: icon,
    });
  };

  const HandleSubmit = async () => {
    cabData.onewaydata = addoneway;
    cabData.city = addoneway.pickup;
    cabData.region = addoneway.pickup;

    try {
      let res = await pmlAPI.put(`/api/cabs/${id}`, cabData);
      if (res.data?.success) {
        handleSweeyAlert("Success", res.data?.massage, "success");
        navigate("/dashboard/oneway-cabs");
      } else {
        handleSweeyAlert("Error", res.data?.massage, "error");
      }
    } catch (error) {
      handleSweeyAlert("Error", "There Is a Error try Again", "error");
    }
  };

  return (
    <div className="modifyWrapper">
      <div className="filter_section">
        {/* Car */}
        <div>
          <label>Car</label>
          <select disabled={true} onChange={(e) => {}}>
            <option value="none">{cabData?.carModel}</option>;
          </select>
        </div>

        {/* State */}
        <div></div>

        {/* City */}
        <div></div>

        {/* Button */}
        <div className="buildBtn">
          <button
            onClick={() => {
              HandleSubmit();
            }}
          >
            Build Changes
          </button>
        </div>
      </div>

      <div className="div_oneway_view_is_here">
        <div className="div_main_autocomplete_two">
          {/* State */}
          <div className="div_innner_state_wise">
            <label className="lable_inner_auto_comaple">State</label>
            <select
              className="select_btn_csss_is_here"
              value={addoneway.pickupState}
              onChange={(e) => {
                handleSearchScityOne(e.target.value);
                setAddOneWay({
                  ...addoneway,
                  pickupState: e.target.value,
                  pickup: e.target.value,
                });
              }}
            >
              {allstate?.map((w) => {
                return <option value={w.name}>{w.name}</option>;
              })}
            </select>
          </div>

          {/* City */}

          {citeies?.length > 0 && (
            <div className="div_innner_state_wise">
              <label className="lable_inner_auto_comaple">City</label>

              <select
                className="select_btn_csss_is_here"
                value={addoneway.pickupCity}
                onChange={(e) => {
                  setAddOneWay({
                    ...addoneway,
                    pickupCity: e.target.value,
                    pickup: e.target.value,
                  });
                }}
              >
                {citeies?.map((w) => {
                  return (
                    <option value={`${w.city}  ${w.state}`}>{w.city}</option>
                  );
                })}
              </select>
            </div>
          )}
        </div>

        <div className="div_main_autocomplete_two">
          {/* State */}
          <div className="div_innner_state_wise">
            <label className="lable_inner_auto_comaple">State</label>
            <select
              className="select_btn_csss_is_here"
              value={addoneway.dropoffState}
              onChange={(e) => {
                handleSearchScityTwo(e.target.value);
                setAddOneWay({
                  ...addoneway,
                  dropoffState: e.target.value,
                  dropoff: e.target.value,
                });
              }}
            >
              {allstate?.map((w) => {
                return <option value={w.name}>{w.name}</option>;
              })}
            </select>
          </div>

          {/* City */}

          {citiesTwo?.length > 0 && (
            <div className="div_innner_state_wise">
              <label className="lable_inner_auto_comaple">City</label>

              <select
                className="select_btn_csss_is_here"
                value={addoneway.dropoffCity}
                onChange={(e) => {
                  setAddOneWay({
                    ...addoneway,
                    dropoffCity: e.target.value,
                    dropoff: e.target.value,
                  });
                }}
              >
                {citiesTwo?.map((w) => {
                  return (
                    <option value={`${w.city}  ${w.state}`}>{w.city}</option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
      </div>

      <div className="allThree">
        {/* OneWayModifier */}
        <div className="allThree">
          <div className="outstation_section">
            <div className="form_box">
              <form>
                <div className="base_fare">
                  <label>Base Fare</label>
                  <input
                    type="number"
                    id="basefare"
                    name="base_fare"
                    value={addoneway?.base_fare}
                    onChange={(e) => {
                      setAddOneWay({
                        ...addoneway,
                        base_fare: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="base_fare">
                  <label>GST</label>
                  <div className="inner_button_gst">
                    <p className="percentage">% age</p>
                  </div>
                  <input
                    type="number"
                    id="gst"
                    name="gst"
                    min="0"
                    max="100"
                    value={addoneway?.gst}
                    onChange={(e) => {
                      setAddOneWay({
                        ...addoneway,
                        gst: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="userUpdateItem">
                  <label className="user__label">Terms & Conditions</label>

                  <RichTextEditor
                    style={{
                      width: "450px",
                    }}
                    value={addoneway.term_and_condition}
                    controls={[
                      ["bold", "italic", "underline"],
                      ["unorderedList"],
                    ]}
                    onChange={(e) => {
                      setAddOneWay({
                        ...addoneway,
                        term_and_condition: e,
                      });
                    }}
                  />
                </div>

                <div className="userUpdateItem">
                  <label className="user__label">Cancellation Policy</label>

                  <RichTextEditor
                    style={{
                      width: "450px",
                    }}
                    disabled={!cabData?.oneway}
                    value={addoneway.cancellation}
                    controls={[
                      ["bold", "italic", "underline"],
                      ["unorderedList"],
                    ]}
                    onChange={(e) => {
                      setAddOneWay({
                        ...addoneway,
                        cancellation: e,
                      });
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateModifier;
