import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import pmlAPI from "../api/pmlAPI";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import {
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

export default function ViewOrder() {
  const paramlink = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [refresh, setResfresh] = useState();

  useEffect(async () => {
    await pmlAPI
      .get("/api/caboreder")
      .then((res) => {
        let fildata = res?.data?.filter((w) => {
          return w?._id == paramlink.id;
        });

        setdata(fildata[0]);
        console.log(fildata[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const tableRow = {
    fontSize: "1.1vw",
    fontWeight: "bold",
  };
  const tablebodyrow = {
    fontSize: "0.8vw",
  };

  const onupdate = () => {
    let id = data?._id;
    delete data?._id;
    delete data?.__v;
    delete data?.createdAt;
    delete data?.updatedAt;

    console.log(data);
    console.log(id);

    pmlAPI
      .patch(`/api/caborder/${id}`, data)
      .then((res) => {
        if (res.data?.massage) {
          swal({
            title: "Success",
            text: res.data?.massage,
            icon: "success",
          });
          navigate("/dashboard/caborder");
        } else {
          swal({
            title: "Error",
            text: res?.data?.massage,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "Error",
          text: "Please Check Your Internet and Try Again",
          icon: "error",
        });
      });
  };

  const checkPaymentstatus = async () => {
    const orderid = data?.order_details?.id;
    let id = data?._id;

    try {
      let getOrderDetails = await pmlAPI.get(
        `/api/payment/getorder/${orderid}`
      );
      swal("Oops", "Something went wrong!", "error");

      if (getOrderDetails?.data?.order_status == "PAID") {
        swal(
          "Payment Status !",
          `Payment Status is ${getOrderDetails?.data?.order_status}`,
          "success"
        );

        try {
          let DataValuwwe = {
            data: data?.data,
            useremail: data?.useremail,
            cabid: data?.cabid,
            userid: data?.userid,
            cab_status: data?.cab_status,
            driver_name: data?.driver_name,
            cab_no: data?.cab_no,
            driver_mobile_no: data?.driver_mobile_no,
            order_details: {
              id: data?.order_details?.id,
              amount: data?.order_details?.amount,
              status: getOrderDetails?.data?.order_status,
              token: data?.order_details?.token,
              link: data?.order_details?.link,
            },
          };

          await pmlAPI.patch(`/api/caborder/${id}`, DataValuwwe);
          setResfresh(nanoid());
        } catch (error) {
          console.log(error);
        }
      } else {
        swal(
          "Payment Status !",
          `Payment Status is ${getOrderDetails?.data?.order_status}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <br></br>
      <br></br>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 150 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableRow}>Tile </TableCell>
                    <TableCell sx={tableRow}>User Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Name:-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>{data?.data?.name}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Email:-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>{data?.data?.email}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Phone No. :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>{data?.data?.phone}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Date :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>{data?.data?.date}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Pick Up Location :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.data?.pickUp}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Pick Detail Adress :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.data?.pickupDetailAdress}
                    </TableCell>
                  </TableRow>
                  {data?.data?.TypeTravel == "hourly" ? (
                    ""
                  ) : (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" sx={tablebodyrow}>
                          Drop Up Location :-
                        </TableCell>
                        <TableCell sx={tablebodyrow}>
                          {data?.data?.dropoff}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" sx={tablebodyrow}>
                          Drop Up Location :-
                        </TableCell>
                        <TableCell sx={tablebodyrow}>
                          {data?.data?.dropoffDetailAdress}
                        </TableCell>
                      </TableRow>
                    </>
                  )}

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Adult Passanger :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.data?.passengers?.adults}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Childern Passanger :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.data?.passengers?.childern}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Infants Passanger :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.data?.passengers?.infants}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Type Of Travel :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.data?.TypeTravel}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Order Id :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.order_details?.id}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Payment Status :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.order_details?.status}{" "}
                      <span
                        style={{
                          backgroundColor: "gray",
                          color: "white",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          checkPaymentstatus();
                        }}
                      >
                        Check Current Status{" "}
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Total Amount :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {data?.order_details?.amount}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Cab Status :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      {/* <select 
                       value={data[0]?.cab_status}
                       onChange={(e) => {
                        setdata({
                          ...data,
                          cab_status: e.target.value,
                        });
                      }}
                       >
                       
                        <option value='Processing'>Processing</option>
                        <option value='Payment Pending'>Payment Pending</option>
                        <option value='On Hold'>On Hold</option>
                        <option value='Complete'>Complete</option>
                      </select> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Cab Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={data[0]?.cab_status}
                          label="Staus"
                          defaultValue={data[0]?.cab_status}
                          onChange={(e) => {
                            setdata({
                              ...data,
                              cab_status: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value="Processing">Processing</MenuItem>
                          <MenuItem value="Payment Pending">
                            Payment Pending
                          </MenuItem>
                          <MenuItem value="On Hold">On Hold</MenuItem>
                          <MenuItem value="Complete">Complete</MenuItem>
                          <MenuItem value="Cancelled">Cancelled</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Cab No. :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="cab_status"
                        variant="standard"
                        label="Enter Cab No."
                        name="cab_status"
                        type="text"
                        value={data?.cab_no}
                        sx={{
                          width: {
                            xxs: 100,
                            xs: 150,
                            sm: 200,
                            md: 300,
                            lg: 400,
                            xl: 500,
                          },
                          borderBottom: 2,
                          borderBottomColor: "gray",
                        }}
                        onChange={(e) => {
                          setdata({
                            ...data,
                            cab_no: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 15,
                            width: "-webkit-fill-available",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Driver Name:-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="driver_name"
                        variant="standard"
                        label="Enter Driver Name"
                        name="driver_name"
                        type="text"
                        value={data?.driver_name}
                        sx={{
                          width: {
                            xxs: 100,
                            xs: 150,
                            sm: 200,
                            md: 300,
                            lg: 400,
                            xl: 500,
                          },
                          borderBottom: 2,
                          borderBottomColor: "gray",
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 15,
                            width: "-webkit-fill-available",
                          },
                        }}
                        onChange={(e) => {
                          setdata({
                            ...data,
                            driver_name: e.target.value,
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      Driver No. :-
                    </TableCell>
                    <TableCell sx={tablebodyrow}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="driver_no"
                        variant="standard"
                        label="Enter Driver No."
                        name="driver_no"
                        type="number"
                        value={data?.driver_mobile_no}
                        sx={{
                          width: {
                            xxs: 100,
                            xs: 150,
                            sm: 200,
                            md: 300,
                            lg: 400,
                            xl: 500,
                          },
                          borderBottom: 2,
                          borderBottomColor: "gray",
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 15,
                            width: "-webkit-fill-available",
                          },
                        }}
                        onChange={(e) => {
                          setdata({
                            ...data,
                            driver_mobile_no: e.target.value,
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tablebodyrow}>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: 15,
                          maxWidth: "130px",
                          maxHeight: "50px",
                          minWidth: "100px",
                          minHeight: "40px",
                        }}
                        onClick={() => navigate("/dashboard/caborder")}
                      >
                        Back
                      </Button>
                    </TableCell>
                    <TableCell sx={tablebodyrow} align="right">
                      {" "}
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: 15,
                          maxWidth: "130px",
                          maxHeight: "50px",
                          minWidth: "100px",
                          minHeight: "40px",
                        }}
                        onClick={() => {
                          onupdate();
                        }}
                      >
                        Submit
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Container>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}
