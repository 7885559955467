import React, { useState, useEffect } from "react"
import pmlAPI from "../api/pmlAPI"
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Badge,
  Button,
  Form,
} from "react-bootstrap"
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi"
import "./Allcss/checkout.css"
import moment from "moment"
import SwapHorizSharpIcon from "@mui/icons-material/SwapHorizSharp"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined"
import { TextField } from "@mui/material"
import swal from "sweetalert"
import one from "../imges/one.svg"
import two from "../imges/two.svg"
import three from "../imges/three.svg"
import { useNavigate } from "react-router-dom"
import { toUnitless } from "@mui/material/styles/cssUtils"
import { Markup } from "interweave"

export default function Checkout() {
  const navigate = useNavigate()
  const [data, setdata] = useState([])
  const [datediff, setdatediff] = useState()
  const [cabprice, setcabprice] = useState()
  const [tax, settax] = useState(0)
  const [driver, setdriver] = useState()
  const [dropff, setdropoff] = useState()
  const [outStation, setOutAtation] = useState({
    itital: "",
    tax: "",
    final: "",
  })

  const [total, settotal] = useState()
  const [caltaxHour, setCalTaxHour] = useState({
    intial: "",
    tax: "",
    final: "",
  })
  const [loading, setloading] = useState(false)
  const [hourData, sethourData] = useState([])
  const [OutData, setOutData] = useState([])
  const [checkTermCheckbox, settermcheckBox] = useState(false)
  const [distanceState, setDistanceState] = useState(0)
  const [distanceCal, setdistanceCal] = useState()

  let typeData = JSON.parse(localStorage.getItem("travelDetail"))
  let localData = JSON.parse(localStorage.getItem("data"))

  useEffect(() => {
    let datalocal = JSON.parse(localStorage.getItem("data"))?.cardDetails?.price
    let distance = Number(
      parseInt(JSON.parse(localStorage.getItem("travelDetail")).distance)
    )
    if (Number(distance / 1000) > 250) {
      setcabprice(parseInt(parseInt(distance / 1000) * datalocal) * 2)
    } else {
      setcabprice(parseInt(Number(datalocal * 250)))
    }
  }, [])

  useEffect(() => {
    let datalocal = JSON.parse(localStorage.getItem("data"))
    sethourData(datalocal?.extraData?.hourlyData)
    setOutData(datalocal?.extraData?.outData)
  }, [])

  useEffect(() => {
    let datalocal = JSON.parse(localStorage.getItem("data"))
    let distance = Number(
      JSON.parse(localStorage.getItem("travelDetail")).distance
    )

    let details = Number(datalocal?.cardDetails?.price) * distance

    // settotal({
    //   ...total,
    //   cabprice: parseInt(Number(datalocal?.cardDetails?.price * distance) / 1000)*2,
    //   tax:parseInt((Number(details) / 1000 / 100) * 8 * 2),
    // });

    setdata(datalocal)
  }, [])

  useEffect(() => {
    let datepick = JSON.parse(localStorage.getItem("travelDetail")).date
    const date1 = new Date(datepick?.to)
    const date2 = new Date(datepick?.from)
    let oneDate = moment(date1).format("DD-MM-YYYY")
    let twoDate = moment(date2).format("DD-MM-YYYY")

    let dateone = moment(oneDate, "DD-MM-YYYY")
    let datetwo = moment(twoDate, "DD-MM-YYYY")

    let daysDiff = datetwo.diff(dateone, "days")

    const diffTime = Math.abs(date2 - date1)
    const diffDaysOne = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    var diffDays = 0
    if (Number(diffDaysOne) > 1) {
      diffDays = Number(daysDiff) + 1
    } else {
      diffDays = diffDaysOne
    }

    if (Number(diffDays) == 0) {
      setdriver(400)
    } else {
      setdriver(400 * Number(diffDays))
    }
  }, [])

  useEffect(() => {
    let datalocal = JSON.parse(localStorage.getItem("data"))

    settotal(datalocal.calculation)
  }, [])

  useEffect(() => {
    let datepick = JSON.parse(localStorage.getItem("travelDetail")).date
    const distancenew = JSON.parse(
      localStorage.getItem("travelDetail")
    )?.distance

    const date1 = new Date(datepick?.to)
    const date2 = new Date(datepick?.from)
    let oneDate = moment(date1).format("DD-MM-YYYY")
    let twoDate = moment(date2).format("DD-MM-YYYY")

    let dateone = moment(oneDate, "DD-MM-YYYY")
    let datetwo = moment(twoDate, "DD-MM-YYYY")

    let daysDiff = datetwo.diff(dateone, "days")

    const diffTime = Math.abs(date2 - date1)
    const diffDaysOne = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    var diffDays = 0
    if (Number(diffDaysOne) > 1) {
      diffDays = Number(daysDiff) + 1
    } else {
      diffDays = diffDaysOne
    }

    let distantone = parseInt(distancenew / 1000) * 2
    let distant = distantone + 50
    let disCal = null

    if (diffDays <= 0) {
      disCal = 250 * 1
    } else {
      disCal = 250 * Number(diffDays)
    }
    console.log(disCal)
    setdistanceCal(disCal)
    setDistanceState(distant)
  }, [])

  const bookNow = async () => {
    setloading(true)
    await pmlAPI
      .post("/send", data.senddata)
      .then((res) => {
        console.log(res.data)
        if (res.data.massage) {
          if (res.data.nouser) {
            swal({
              title: "Error",
              text: res.data.massage,
              icon: "error",
              dangerMode: true,
            })
            setloading(false)
          } else {
          }
        } else {
          swal({
            title: "Error",
            text: "somithing went wrong",
            icon: "error",
            dangerMode: true,
          })
          setloading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        swal({
          title: "Error",
          text: "plz check internet",
          icon: "error",
          dangerMode: true,
        })
        setloading(false)
      })
  }

  const apibook = async () => {
    let finalPrice = null
    if (data?.senddata?.data?.TypeTravel == "hourly") {
      finalPrice = total?.hourInitial + total?.hourTax
    } else {
      finalPrice = total?.outInitial + total?.outtax + total?.outDriver
    }
    console.log({ total })
    await pmlAPI
      .post("/api/caboreder", data.senddata)
      .then(async (res) => {
        let id = res.data.cab._id
        let sendValue = {
          id: res.data.cab.userid,
          amount: finalPrice,
          customer_name: res.data.cab.data.name,
          email: res.data.cab.useremail,
          phone: res.data.cab.data.phone,
        }

        await pmlAPI
          .post(`api/payment/create`, sendValue)
          .then(async (resrr) => {
            let orderId = resrr.data.order_id
            let PaymentLink = resrr.data.payment_link
            let DataValuwwe = {
              data: res.data.cab.data,
              useremail: res.data.cab.useremail,
              cabid: res.data.cab.cabid,
              userid: res.data.cab.userid,
              cab_status: res.data.cab.cab_status,
              driver_name: res.data.cab.driver_name,
              cab_no: res.data.cab.cab_no,
              driver_mobile_no: res.data.cab.driver_mobile_no,
              order_details: {
                id: resrr.data.order_id,
                amount: finalPrice,
                status: resrr.data.order_status,
                token: resrr.data.order_token,
                link: resrr.data.payment_link,
              },
            }

            await pmlAPI
              .patch(`/api/caborder/${id}`, DataValuwwe)
              .then((www) => {
                window.open(PaymentLink, "_blank")

                navigate(`/finalPage/${orderId}/${id}`)
              })
          })
          .catch((err) => {
            console.log({ err })
            swal({
              title: "Cashfree Error !",
              text: err?.response?.data?.error?.message,
              icon: "error",
              dangerMode: true,
            })
            setloading(false)
          })
      })
      .catch((err) => {
        console.log({ err })
        setloading(false)
      })
  }

  const smsbook = async () => {
    try {
      let res = await pmlAPI.post("/api/otp/booking", data.senddata)
      setloading(false)
    } catch (error) {
      console.log(error)
      setloading(false)
    }
  }

  let travelldetail = JSON.parse(localStorage.getItem("travelDetail"))?.date
  useEffect(() => {
    let textname = JSON.parse(localStorage.getItem("travelDetail"))?.dropoff
      ?.text
    setdropoff(textname)
  }, [])

  const BookBtnClick = () => {
    if (data?.senddata?.data?.phone == "") {
      swal({
        title: "Warning !",
        text: "Please enter mobile no.",
        icon: "warning",
        dangerMode: true,
      })
    } else if (data?.senddata?.data?.phone?.length < 10) {
      swal({
        title: "Error",
        text: "Mobile No. must be 10 Digits",
        icon: "error",
        dangerMode: true,
      })
    } else if (!checkTermCheckbox) {
      swal({
        title: "Error",
        text: "Please Accept Term And Condition To Book Cab",
        icon: "error",
      })
    } else {
      bookNow()
      apibook()
      smsbook()
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col md={8}>
            <div>
              <Card>
                <Card.Header>
                  <LocalTaxiIcon className="logo" />
                  <span className="heading header_gone">Transfer Detail</span>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row style={{ minHeight: 100 }}>
                      <Col
                        style={{
                          minHeight: 100,
                        }}
                        sm={3}
                      >
                        <img
                          src={data?.cardDetails?.cabImage}
                          alt="car 1"
                          style={{
                            backgroundImage: `url(${data?.cardDetails?.cabImage})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "100%",
                            width: "100%",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </Col>

                      <Col sm={9} style={{ margin: "auto" }}>
                        <h2 className="carmodalheading">
                          {data?.cardDetails?.carModel}
                        </h2>
                        <p>
                          <span className="textUnit">(1 Unit) |</span>
                          <span className="textUnit">
                            {data?.cardDetails?.seats} Seats |
                          </span>
                          <span className="textUnit">
                            {data?.cardDetails?.luggage} Luggage
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item className="listItemsecond">
                    <Row>
                      <Col sm={5}>
                        <ListGroup>
                          <ListGroup.Item className="listItemsecond">
                            <h5 className="pickuplocation">Pickup Location</h5>
                            <p className="fontsize">
                              {data?.senddata?.data?.pickUp}
                            </p>
                          </ListGroup.Item>
                          <ListGroup.Item className="listItemsecond">
                            {data?.senddata?.data?.TypeTravel !== "hourly" && (
                              <>
                                <h5 className="pickuplocation">
                                  Pickup Date & Time
                                </h5>
                                <p
                                  className="fontsize"
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {moment(travelldetail?.to).format(
                                    "MMMM Do YYYY h:mm a"
                                  )}
                                </p>
                              </>
                            )}
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col style={{ width: "fit-content" }} sm={2}>
                        <SwapHorizSharpIcon className="logo pickdropicon" />
                      </Col>
                      <Col sm={5}>
                        {data?.senddata?.data?.TypeTravel == "hourly" ? (
                          <ListGroup>
                            <ListGroup.Item className="listItemsecond">
                              <h5 className="pickuplocation">
                                Pickup Date & Time
                              </h5>
                              <p
                                className="fontsize"
                                style={{
                                  color: "black",
                                }}
                              >
                                {moment(travelldetail?.to).format(
                                  "MMMM Do YYYY h:mm a"
                                )}
                              </p>
                            </ListGroup.Item>
                            <ListGroup.Item className="listItemsecond"></ListGroup.Item>
                          </ListGroup>
                        ) : (
                          <ListGroup>
                            <ListGroup.Item className="listItemsecond">
                              <h5 className="pickuplocation">Destination</h5>
                              <p className="fontsize">
                                {data?.senddata?.data?.dropoff}
                              </p>
                            </ListGroup.Item>

                            <ListGroup.Item className="listItemsecond">
                              {data?.senddata?.data?.TypeTravel ==
                                "outstation" && (
                                <>
                                  <h5 className="pickuplocation">
                                    Return Date & Time
                                  </h5>
                                  <p className="fontsize">
                                    {moment(travelldetail?.from).format(
                                      "MMMM Do YYYY h:mm a"
                                    )}
                                  </p>
                                </>
                              )}
                            </ListGroup.Item>
                          </ListGroup>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </div>

            <br></br>

            {/* Travellers Details */}
            <div>
              <Card>
                <Card.Header>
                  <AccountCircleOutlinedIcon className="logo" />{" "}
                  <span className="heading header_gone ">
                    Travellers Details
                  </span>
                </Card.Header>
                <Card.Body>
                  {/* name and select */}
                  <Row>
                    <Col sm={4}>
                      <Card.Title className="formheading ">Title</Card.Title>
                      <Card.Text>
                        <select className="selectoption">
                          <option className="options">Mr.</option>
                          <option className="options">Mrs.</option>
                          <option className="options">Miss.</option>
                        </select>
                      </Card.Text>
                    </Col>

                    <Col sm={8}>
                      <Card.Title className="formheading">Name</Card.Title>
                      <Card.Text>
                        <Form.Control
                          type="text"
                          placeholder="Enter name"
                          className="inputfiled"
                          value={data?.senddata?.data?.name}
                          onChange={(e) => {
                            setdata({
                              ...data,
                              senddata: {
                                ...data.senddata,
                                data: {
                                  ...data.senddata.data,
                                  name: e.target.value,
                                },
                              },
                            })
                          }}
                        />
                      </Card.Text>
                    </Col>
                  </Row>

                  <ListGroup variant="flush">
                    {/* pic and drop location is in this ection */}

                    <ListGroup.Item>
                      <Row>
                        <Col sm={6}>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <Card.Title className="formheading">
                                Pick-Up Address
                              </Card.Title>
                              <Card.Text>
                                <TextField
                                  id="standard-basic"
                                  label="Enter Detailed PickUp Address"
                                  className="textfieldfomnt"
                                  variant="standard"
                                  InputLabelProps={{
                                    style: { fontSize: 17 },
                                  }}
                                  value={
                                    data?.senddata?.data?.pickupDetailAdress
                                  }
                                  sx={{
                                    fontSize: 3,
                                    marginTop: 2,
                                  }}
                                  onChange={(e) => {
                                    setdata({
                                      ...data,
                                      senddata: {
                                        ...data.senddata,
                                        data: {
                                          ...data.senddata.data,
                                          pickupDetailAdress: e.target.value,
                                        },
                                      },
                                    })
                                  }}
                                />
                              </Card.Text>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h3 className="h3_css_check_color">
                                {data?.senddata?.data?.pickUp}
                              </h3>
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>

                        <Col sm={6}>
                          {data?.senddata?.data?.TypeTravel == "hourly" ? (
                            ""
                          ) : (
                            <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Card.Title className="formheading">
                                  Drop-Off Address
                                </Card.Title>
                                <Card.Text>
                                  <TextField
                                    id="standard-basic"
                                    label=" Enter Detailed Dropoff Address"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { fontSize: 17 },
                                    }}
                                    value={
                                      data?.senddata?.data?.dropoffDetailAdress
                                    }
                                    sx={{
                                      fontSize: 3,
                                      marginTop: 2,
                                    }}
                                    onChange={(e) => {
                                      setdata({
                                        ...data,
                                        senddata: {
                                          ...data.senddata,
                                          data: {
                                            ...data.senddata.data,
                                            dropoffDetailAdress: e.target.value,
                                          },
                                        },
                                      })
                                    }}
                                  />
                                </Card.Text>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <h3 className="h3_css_check_color">
                                  {data?.senddata?.data?.dropoff}
                                </h3>
                              </ListGroup.Item>
                            </ListGroup>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    {/* email And Phoebn  */}

                    <ListGroup.Item>
                      <Row>
                        <Col sm={6}>
                          <label className="formheading">Email </label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            className="inputfiledcontrol"
                            value={data?.senddata?.data?.email}
                            onChange={(e) => {
                              setdata({
                                ...data,
                                senddata: {
                                  ...data.senddata,
                                  data: {
                                    ...data.senddata.data,
                                    email: e.target.value,
                                  },
                                },
                              })
                            }}
                          />
                        </Col>
                        <Col sm={6}>
                          <label className="formheading">Phone No</label>
                          <Form.Control
                            className="inputfiledcontrol"
                            placeholder="Enter Phone No"
                            type="tel"
                            value={data?.senddata?.data?.phone}
                            onChange={(e) => {
                              setdata({
                                ...data,
                                senddata: {
                                  ...data.senddata,
                                  data: {
                                    ...data.senddata.data,
                                    phone: e.target.value,
                                  },
                                },
                              })
                            }}
                          />
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </div>

            <br></br>
            {/* Read before you book! */}

            <div>
              <Card>
                <Card.Header>
                  <ArticleOutlinedIcon className="logo" />{" "}
                  <span className="heading header_gone">
                    Read before you book!
                  </span>
                </Card.Header>
                <Card.Body className="cardbody">
                  <Card.Title className="ulpadding ulheading">
                    Terms & Conditions
                  </Card.Title>
                  <Card.Text className="ulpadding textfontli">
                    {typeData?.TypeTravel == "hourly" ? (
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <Markup
                          content={
                            localData?.extraData?.hourlyData?.term_and_condition
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <Markup
                          content={
                            localData?.extraData?.outData?.term_and_condition
                          }
                        />
                      </div>
                    )}
                  </Card.Text>
                  <br></br>
                  <Card.Title className="ulpadding ulheading">
                    Cancellation policy
                  </Card.Title>
                  <Card.Text className="ulpadding textfontli">
                    {typeData?.TypeTravel == "hourly" ? (
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <Markup
                          content={
                            localData?.extraData?.hourlyData?.cancellation
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <Markup
                          content={localData?.extraData?.outData?.cancellation}
                        />
                      </div>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>

          {/* Price Details field Start here  */}

          <Col md={4}>
            <div className="flex-md">
              {/* Card Price */}
              <div style={{ width: "100%" }}>
                <Card className="card-md">
                  <Card.Header>
                    <CurrencyRupeeOutlinedIcon className="logo" />
                    <span className="heading header_gone ">Price Details</span>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="listitem">
                        <span className="headingside">
                          {data?.senddata?.data?.TypeTravel == "hourly"
                            ? `${data?.senddata?.data?.hourlyRate} Hour Price:`
                            : " Total"}
                        </span>
                        <span className="amountside finalAmountDispaly">
                          <img
                            src="/assets/rupee.png"
                            alt=""
                            className="imgmFronTop"
                          />
                          <span>
                            {data?.senddata?.data?.TypeTravel == "hourly"
                              ? total?.hourInitial
                              : total?.outInitial}
                          </span>
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="listitem">
                        <span className="headingside">Tax</span>
                        <span className="amountside finalAmountDispaly">
                          <img
                            src="/assets/rupee.png"
                            alt=""
                            className="imgmFronTop"
                          />
                          <span>
                            {data?.senddata?.data?.TypeTravel == "hourly"
                              ? parseInt(total?.hourTax)
                              : parseInt(total?.outtax)}
                          </span>
                        </span>
                      </ListGroup.Item>

                      {data?.senddata?.data?.TypeTravel == "hourly" ||
                      data?.senddata?.data?.TypeTravel == "Airport" ||
                      data?.senddata?.data?.TypeTravel == "oneway" ? (
                        ""
                      ) : (
                        <ListGroup.Item className="listitem">
                          <span className="headingside ">
                            Driver Allowance :
                          </span>
                          <span className="amountside finalAmountDispaly">
                            <img
                              src="/assets/rupee.png"
                              alt=""
                              className="imgmFronTop"
                            />
                            <span>{total?.outDriver}</span>
                          </span>
                        </ListGroup.Item>
                      )}

                      {/* <ListGroup.Item className="listitem">
                        <span className="headingside ">Promo Code : </span>
                        <span className="amountside"> 0</span>
                      </ListGroup.Item> */}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </div>
              <br></br>

              {data?.senddata?.data?.TypeTravel == "hourly" ||
                (data?.senddata?.data?.TypeTravel == "outstation" && (
                  <div className="w-full">
                    <Card
                      className="card-md"
                      // style={{ width: "30rem", marginBottom: "10px" }}
                    >
                      <Card.Header>
                        <span className="heading header_gone">
                          {" "}
                          Fare Details
                        </span>
                      </Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">Base Fare </div>
                            <div className="spanfont">Include</div>
                          </p>
                        </ListGroup.Item>

                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">
                              GST (
                              {typeData.TypeTravel == "hourly"
                                ? hourData.gst
                                : OutData.gst}
                              % On Base Fare)
                            </div>
                            <div className="spanfont">Include</div>
                          </p>
                        </ListGroup.Item>

                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">State , Toll Tax</div>
                            <div className="spanfont">
                              {typeData.TypeTravel == "hourly"
                                ? hourData?.state_toll
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  hourData?.state_toll?.slice(1)
                                : OutData?.state_toll?.charAt(0).toUpperCase() +
                                  OutData?.state_toll?.slice(1)}
                            </div>
                          </p>
                        </ListGroup.Item>

                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">
                              Vehicle & Fuel Charges{" "}
                            </div>
                            <div className="spanfont">
                              {typeData.TypeTravel == "hourly"
                                ? hourData?.vechile_and_fuel
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  hourData?.vechile_and_fuel?.slice(1)
                                : OutData?.vechile_and_fuel
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  OutData?.vechile_and_fuel?.slice(1)}
                            </div>
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">Distance</div>
                            <div className="spanfont">
                              {distanceState > distanceCal
                                ? distanceState
                                : distanceCal}{" "}
                              KM
                            </div>
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>

                    <Card className="card-md">
                      <Card.Header>
                        <span className="heading header_gone">
                          Other Charge
                        </span>
                      </Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">Waiting Charge</div>
                            <div className="spanfonttow">
                              <img
                                src="/assets/rupee.png"
                                alt=""
                                className="imgmiddile"
                              />
                              <span>
                                {typeData.TypeTravel == "hourly"
                                  ? ` ${hourData?.waiting_charge?.value} / ${hourData?.waiting_charge?.type}`
                                  : ` ${OutData?.waiting_charge?.value} / ${OutData?.waiting_charge?.type}`}
                              </span>
                            </div>
                          </p>
                        </ListGroup.Item>

                        {typeData.TypeTravel !== "hourly" && (
                          <ListGroup.Item className="listitem">
                            <p className="cardPara">
                              <div className="spanfont">
                                Night Charge
                                {typeData.TypeTravel == "hourly"
                                  ? ""
                                  : `(${OutData?.night_time?.to?.slice(0, 2)} ${
                                      OutData?.night_time?.to?.slice(0, 2) >= 12
                                        ? "PM"
                                        : "AM"
                                    } to ${OutData?.night_time?.from?.slice(
                                      0,
                                      2
                                    )} ${
                                      OutData?.night_time?.from?.slice(0, 2) >=
                                      12
                                        ? "PM"
                                        : "AM"
                                    })`}
                              </div>
                              <div className="spanfonttow">
                                <img
                                  src="/assets/rupee.png"
                                  alt=""
                                  className="imgmiddile"
                                />
                                <span>
                                  {typeData.TypeTravel == "hourly"
                                    ? hourData?.night_charge
                                    : OutData?.night_charge}
                                </span>
                              </div>
                            </p>
                          </ListGroup.Item>
                        )}

                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">Extra Pickup/ Drop</div>
                            <div className="spanfonttow">
                              <img
                                src="/assets/rupee.png"
                                alt=""
                                className="imgmiddile"
                              />
                              <span>
                                {typeData.TypeTravel == "hourly"
                                  ? ` ${hourData?.extra_pickup}`
                                  : ` ${OutData?.extra_pickup}`}
                              </span>
                            </div>
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>

                    <Card className="card-md">
                      <Card.Header>
                        <span className="heading header_gone">
                          Extra km Charges
                        </span>
                      </Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item className="listitem">
                          <p className="cardPara">
                            <div className="spanfont">Per Km Charge</div>
                            <div className="spanfonttow">
                              <img
                                src="/assets/rupee.png"
                                alt=""
                                className="imgmiddile"
                              />
                              <span>
                                {typeData.TypeTravel == "hourly"
                                  ? ` ${hourData?.per_km_charge}`
                                  : ` ${OutData?.per_km_charge}`}
                              </span>
                            </div>
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>
                  </div>
                ))}

              <br></br>

              {/* confirm Password */}
              <div className="flex-md">
                <div className="w-full  order-2">
                  <Card className="card-md">
                    <Card.Header>
                      <CurrencyRupeeOutlinedIcon className="logo" />
                      <span className="heading header_gone"> Book Now </span>
                    </Card.Header>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="listitem">
                        <input
                          type="checkbox"
                          className="inputTernabdcondition"
                          checked={checkTermCheckbox}
                          onChange={(e) => {
                            settermcheckBox(e.target.checked)
                          }}
                        />
                        <span className="spanternAndcontitin">
                          I agree to the Terms & Conditions and Cancellation
                          policy
                        </span>
                      </ListGroup.Item>
                      {data?.senddata?.data?.TypeTravel == "hourly" ? (
                        <ListGroup.Item className="listitem">
                          <span className="headingsideGrand">
                            Grand Total :
                          </span>
                          <span className="amountsidegrand finalAmountDispaly">
                            <img
                              src="/assets/rupee.png"
                              alt=""
                              className="imgFinalAmount"
                            />
                            <span>{total?.hourInitial + total?.hourTax}</span>
                          </span>
                        </ListGroup.Item>
                      ) : (
                        <ListGroup.Item className="listitem">
                          <span className="headingsideGrand">Grand Total:</span>
                          <span className="amountsidegrand finalAmountDispaly">
                            <img
                              src="/assets/rupee.png"
                              alt=""
                              className="imgFinalAmount"
                            />
                            <span>
                              {total?.outInitial +
                                total?.outtax +
                                total?.outDriver}
                            </span>
                          </span>
                        </ListGroup.Item>
                      )}

                      <ListGroup.Item>
                        <Button
                          variant="primary"
                          disabled={loading}
                          onClick={() => {
                            BookBtnClick()
                          }}
                        >
                          {loading ? "loading ..." : "Book Now"}
                        </Button>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </div>

                {/* side Icons  */}
                <div className="w-full">
                  <Card className="card-md">
                    <Card.Header className="heading header_gone">
                      Why Sign up or Login?
                    </Card.Header>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <Row>
                          <Col sm={3}>
                            {" "}
                            <img className="w-md-30" src={one} />
                          </Col>
                          <Col sm={9}>
                            <Card.Title>Unlock exclusive benefits</Card.Title>
                            <Card.Text>
                              Get amazing discounts on the first ride and
                              additional on others
                            </Card.Text>
                          </Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col sm={3}>
                            <img className="w-md-30" src={two} />
                          </Col>
                          <Col sm={9}>
                            <Card.Title>
                              Track & Manage your bookings from one place
                            </Card.Title>
                            <Card.Text>
                              You can keep a track of your cab & manage all
                              bookings from one place
                            </Card.Text>
                          </Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col sm={3}>
                            <img className="w-md-30" src={three} />
                          </Col>
                          <Col sm={9}>
                            <Card.Title>
                              Book faster with the saved details
                            </Card.Title>
                            <Card.Text>
                              The saved details can be used again to book your
                              cab ride
                            </Card.Text>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
