import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, ListItem, Divider, List, ListItemText } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useRideValue } from "../../context/rideContext";
import api from "../../api/pmlAPI.js";

import "./style.css";

export default function HeaderNew() {
  const navigate = useNavigate();
  const [{ pickup, dropoff, date, passengers, distance }, dispatch] =
    useRideValue();
  const location = useLocation();
  const [key, setkey] = useState("");
  const [userdata, setuserData] = useState({
    name: "",
  });
  const [refresh, setrefresh] = useState("hiiii");
  const matches = useMediaQuery("(max-width:1264px)");
  const [drawerOpen, setdrawer] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [currrentActive, setCurrentActive] = useState("");

  const vvvv = localStorage.getItem("userData");
  const ggg = vvvv;
  const jsonnn = JSON.parse(ggg);

  useEffect(() => {
    let data = {
      name: "",
    };
    if (jsonnn?.name) {
      setuserData(jsonnn);
    } else {
      console.log("noooo");
      localStorage.setItem("userData", JSON.stringify(data));
      setuserData(data);
    }
  }, []);

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  const setDrawerOpen = (isDrawerOpen) => {
    setdrawer({
      drawerOpen: isDrawerOpen,
    });
  };

  const toggleDrawer = () => {
    setdrawer(!drawerOpen);
  };

  let tokenCheck = localStorage.getItem("userData");

  useEffect(() => {
    if (tokenCheck == "") {
      setkey("");
    }
  }, [tokenCheck]);

  useEffect(() => {
    console.log(location.pathname);

    if (location.pathname == "/") {
      setCurrentActive("home");
    } else if (location.pathname == "/aboutus") {
      setCurrentActive("about");
    } else if (location.pathname == "/changepsd") {
      setCurrentActive("password");
    } else if (location.pathname == "/login") {
      setCurrentActive("login");
    } else if (location.pathname == "/signup") {
      setCurrentActive("signup");
    } else if (location.pathname.split("/")[1] == "dashboard") {
      setCurrentActive("dashbord");
    } else {
      setCurrentActive("");
    }
  }, [location]);
  return (
    <>
      <div className="main_div_container">
        <div>
          <div className="internal_div_img_check">
            <img
              src="/assets/newlogo.svg"
              alt=""
              className="img_logo_main"
              onClick={() => navigate("/")}
            />
            <span className="main_heading_check">A Unit Of Paul Merchants</span>
          </div>
        </div>

        {matches ? (
          <>
            <div className="div_second_internal_for_drawer">
              <MenuIcon
                className="svg_icons"
                onClick={() => {
                  toggleDrawer();
                }}
              />
            </div>

            <Drawer anchor="right" openSecondary={true} open={drawerOpen}>
              {/* //drawerOpen */}
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "#000000",
                  height: "100%",
                }}
                component="nav"
                aria-label="mailbox folders"
              >
                <ListItem>
                  <HighlightOffIcon
                    className="svg_iconsminus"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  />
                </ListItem>
                <ListItem>
                  <h3 className="drawer_css_item_csss">
                    {userdata?.name || jsonnn?.name}
                  </h3>
                </ListItem>
                <Divider />
                <ListItem button divider>
                  <Link
                    to="/"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h4
                      className="drawer_css_item_csss"
                      style={
                        currrentActive == "home"
                          ? { color: "yellow" }
                          : { color: "" }
                      }
                    >
                      Home
                    </h4>
                  </Link>
                </ListItem>

                <ListItem button>
                  <Link
                    to="/aboutus"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <h4
                      className="drawer_css_item_csss"
                      style={
                        currrentActive == "about"
                          ? { color: "yellow" }
                          : { color: "" }
                      }
                    >
                      About us
                    </h4>
                  </Link>
                </ListItem>
                <Divider light />
                {userdata?.token || jsonnn?.token ? (
                  <ListItem button>
                    <Link
                      to="/dashboard"
                      onClick={() => {
                        toggleDrawer();
                      }}
                    >
                      <h4
                        className="drawer_css_item_csss"
                        style={
                          currrentActive == "dashbord"
                            ? { color: "yellow" }
                            : { color: "" }
                        }
                      >
                        Dashboard
                      </h4>
                    </Link>
                  </ListItem>
                ) : (
                  ""
                )}

                {userdata?.name == "" && jsonnn?.name == "" ? (
                  <>
                    <Divider light />
                    <ListItem button>
                      <Link
                        to="/login"
                        onClick={() => {
                          let data = {
                            name: "",
                          };

                          localStorage.setItem(
                            "userData",
                            JSON.stringify(data)
                          );
                          setuserData(data);
                          setkey("");
                          toggleDrawer();
                        }}
                      >
                        <h4
                          className="drawer_css_item_csss"
                          style={
                            currrentActive == "login"
                              ? { color: "yellow" }
                              : { color: "" }
                          }
                        >
                          Login
                        </h4>
                      </Link>
                    </ListItem>
                    <Divider light />
                    <ListItem button>
                      <Link
                        to="/signup"
                        onClick={() => {
                          toggleDrawer();
                        }}
                      >
                        <h4
                          className="drawer_css_item_csss"
                          style={
                            currrentActive == "signup"
                              ? { color: "yellow" }
                              : { color: "" }
                          }
                        >
                          Sign Up
                        </h4>
                      </Link>
                    </ListItem>
                    <Divider light />
                  </>
                ) : (
                  <>
                    <Divider light />
                    <ListItem button>
                      <Link
                        to="/changepsd"
                        onClick={() => {
                          toggleDrawer();
                        }}
                      >
                        <h4
                          className="drawer_css_item_csss"
                          style={
                            currrentActive == "password"
                              ? { color: "yellow" }
                              : { color: "" }
                          }
                        >
                          Change Password
                        </h4>
                      </Link>
                    </ListItem>
                    <Divider light />
                    <ListItem
                      button
                      onClick={() => {
                        let data = {
                          name: "",
                        };

                        localStorage.setItem("userData", JSON.stringify(data));
                        setuserData(data);
                        setkey("");
                      }}
                    >
                      <Link
                        to="/"
                        onClick={() => {
                          toggleDrawer();
                        }}
                      >
                        <h4 className="drawer_css_item_csss">Logout</h4>
                      </Link>
                    </ListItem>
                    <Divider light />
                  </>
                )}
              </List>
            </Drawer>
          </>
        ) : (
          <div className="div_second_internal">
            <div
              className="internal_span_right_side"
              onClick={() => navigate("/")}
              style={
                currrentActive == "home" ? { color: "yellow" } : { color: "" }
              }
            >
              Home
            </div>
            {userdata?.token || jsonnn?.token ? (
              <div
                className="internal_span_right_side"
                onClick={() => navigate("/dashboard")}
                style={
                  currrentActive == "dashbord"
                    ? { color: "yellow" }
                    : { color: "" }
                }
              >
                Dashboard
              </div>
            ) : (
              ""
            )}
            <div
              className="internal_span_right_side"
              onClick={() => navigate("/aboutus")}
              style={
                currrentActive == "about" ? { color: "yellow" } : { color: "" }
              }
            >
              About Us
            </div>

            {userdata?.name == "" && jsonnn?.name == "" ? (
              <div className="div_innner_css_login_signup">
                <div
                  className="internal_span_right_side_log_in "
                  onClick={() => navigate("/login")}
                  style={
                    currrentActive == "login"
                      ? { color: "yellow" }
                      : { color: "" }
                  }
                >
                  LogIn
                </div>
                <div className="login_inner_side_css"> </div>
                <div
                  className="internal_span_right_side_log_in"
                  onClick={() => navigate("/signup")}
                  style={
                    currrentActive == "signup"
                      ? { color: "yellow" }
                      : { color: "" }
                  }
                >
                  Sign Up
                </div>
              </div>
            ) : (
              <>
                <div
                  className="internal_span_right_side"
                  onClick={() => navigate("/changepsd")}
                  style={
                    currrentActive == "password"
                      ? { color: "yellow" }
                      : { color: "" }
                  }
                >
                  Change Password
                </div>
                <div
                  className="internal_span_right_side"
                  onClick={() => {
                    let data = {
                      name: "",
                    };

                    localStorage.setItem("userData", JSON.stringify(data));
                    setuserData(data);
                    setkey("");
                    navigate("/");
                  }}
                >
                  Logout
                </div>
              </>
            )}

            <div className="internal_span_right_side">
              Toll Free No. : 1800 137 1444
            </div>
          </div>
        )}
      </div>
      <div className="end_header_div_is_here"></div>
    </>
  );
}
