let ariportJson = [
  {
    Id: "1722",
    Name: "Visakhapatnam Airport ,Terminal 1",
    Type: "IATA",
    Code: "VTZ",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 17.7281513214111,
    longitude: 83.2267532348633,
    countryCode: "IN",
    city: "Visakhapatnam",
  },
  {
    Id: "1722",
    Name: "Visakhapatnam Airport ,Terminal 2",
    Type: "IATA",
    Code: "VTZ",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 17.7281513214111,
    longitude: 83.2267532348633,
    countryCode: "IN",
    city: "Visakhapatnam",
  },
  {
    Id: "596",
    Name: "Chandigarh International Airport ,Terminal 1",
    Type: "IATA",
    Code: "IXC",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 30.6749210357666,
    longitude: 76.7973861694336,
    countryCode: "IN",
    city: "Chandigarh",
  },
  {
    Id: "596",
    Name: "Chandigarh International Airport ,Terminal 2",
    Type: "IATA",
    Code: "IXC",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 30.6749210357666,
    longitude: 76.7973861694336,
    countryCode: "IN",
    city: "Chandigarh",
  },
  {
    Id: "321",
    Name: "Indira Gandhi International Airport, Terminal 1",
    Type: "IATA",
    Code: "DEL",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 28.5631,
    longitude: 77.1207,
    countryCode: "IN",
    city: "Delhi",
  },
  {
    Id: "157997",
    Name: "Indira Gandhi International Airport, Terminal 2",
    Type: "IATA",
    Code: "DEL",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 28.55897,
    longitude: 77.087638,
    countryCode: "IN",
    city: "Delhi",
  },
  {
    Id: "157998",
    Name: "Indira Gandhi International Airport, Terminal 3",
    Type: "IATA",
    Code: "DEL",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 28.5456733,
    longitude: 77.1091989,
    countryCode: "IN",
    city: "Delhi",
  },
  {
    Id: "482",
    Name: "Goa Dabolim Airport , Terminal 1",
    Type: "IATA",
    Code: "GOI",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 15.3845338821411,
    longitude: 73.8398284912109,
    countryCode: "IN",
    city: "Goa",
  },
  {
    Id: "482",
    Name: "Goa Dabolim Airport , Terminal 2",
    Type: "IATA",
    Code: "GOI",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 15.3845338821411,
    longitude: 73.8398284912109,
    countryCode: "IN",
    city: "Goa",
  },
  {
    Id: "53",
    Name: "Sardar Vallabhbhai Patel International Airport,Terminal 1",
    Type: "IATA",
    Code: "AMD",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 23.0663890838623,
    longitude: 72.6241683959961,
    countryCode: "IN",
    city: "Ahmedabad",
  },
  {
    Id: "157995",
    Name: "Sardar Vallabhbhai Patel International Airport,Terminal 2",
    Type: "IATA",
    Code: "AMD",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 23.0663890838623,
    longitude: 72.6241683959961,
    countryCode: "IN",
    city: "Ahmedabad",
  },
  {
    Id: "119",
    Name: "Vadodara Airport ,Terminal 2 ",
    Type: "IATA",
    Code: "BDQ",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 22.3290767669678,
    longitude: 73.2156982421875,
    countryCode: "IN",
    city: "Vadodara",
  },
  {
    Id: "119",
    Name: "Vadodara Airport ,Terminal 1 ",
    Type: "IATA",
    Code: "BDQ",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 22.3290767669678,
    longitude: 73.2156982421875,
    countryCode: "IN",
    city: "Vadodara",
  },
  {
    Id: "149583",
    Name: "Jammu Airport",
    Type: "IATA",
    Code: "IXJ",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 0.0,
    longitude: 0.0,
    countryCode: "",
    city: "",
  },
  {
    Id: "150",
    Name: "Kempegowda International Airport Bengaluru",
    Type: "IATA",
    Code: "BLR",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 13.2007713317871,
    longitude: 77.7102279663086,
    countryCode: "IN",
    city: "Bangalore",
  },
  {
    Id: "275",
    Name: "Cochin International Airport, Domestic Terminal 1",
    Type: "IATA",
    Code: "COK",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 10.1556444168091,
    longitude: 76.3905334472656,
    countryCode: "IN",
    city: "Kochi",
  },
  {
    Id: "275",
    Name: "Cochin International Airport, Domestic Terminal 2",
    Type: "IATA",
    Code: "COK",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 10.1556444168091,
    longitude: 76.3905334472656,
    countryCode: "IN",
    city: "Kochi",
  },
  {
    Id: "158001",
    Name: "Cochin International Airport, International Terminal",
    Type: "IATA",
    Code: "COK",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 10.1556444168091,
    longitude: 76.3905334472656,
    countryCode: "IN",
    city: "Kochi",
  },
  {
    Id: "1612",
    Name: "Trivandrum International Airport , Terminal 1",
    Type: "IATA",
    Code: "TRV",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 8.47612571716309,
    longitude: 76.9190673828125,
    countryCode: "IN",
    city: "Thiruvananthapuram",
  },
  {
    Id: "1612",
    Name: "Trivandrum International Airport , Terminal 2",
    Type: "IATA",
    Code: "TRV",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 8.47612571716309,
    longitude: 76.9190673828125,
    countryCode: "IN",
    city: "Thiruvananthapuram",
  },

  {
    Id: "542",
    Name: "Khajuraho Airport",
    Type: "IATA",
    Code: "HJR",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 24.8187465667725,
    longitude: 79.9164199829102,
    countryCode: "IN",
    city: "Khajuraho",
  },
  {
    Id: "577",
    Name: "Devi Ahilya Bai Holkar Airport ,Terminal 1 ",
    Type: "IATA",
    Code: "IDR",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 22.7271499633789,
    longitude: 75.8082275390625,
    countryCode: "IN",
    city: "Indore",
  },
  {
    Id: "577",
    Name: "Devi Ahilya Bai Holkar Airport ,Terminal 2 ",
    Type: "IATA",
    Code: "IDR",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 22.7271499633789,
    longitude: 75.8082275390625,
    countryCode: "IN",
    city: "Indore",
  },
  {
    Id: "166",
    Name: "Chattrapathi Shivaji International Airport, Terminal 1",
    Type: "IATA",
    Code: "BOM",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 19.0898914337158,
    longitude: 72.868049621582,
    countryCode: "IN",
    city: "Mumbai",
  },
  {
    Id: "157996",
    Name: "Chattrapathi Shivaji International Airport, Terminal 2",
    Type: "IATA",
    Code: "BOM",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 19.0898914337158,
    longitude: 72.868049621582,
    countryCode: "IN",
    city: "Mumbai",
  },
  {
    Id: "597",
    Name: "Aurangabad Airport",
    Type: "IATA",
    Code: "IXU",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 19.8664646148682,
    longitude: 75.3972091674805,
    countryCode: "IN",
    city: "Aurangabad",
  },

  {
    Id: "598",
    Name: "Jaipur International Airport ,Terminal 1 ",
    Type: "IATA",
    Code: "JAI",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 26.8220043182373,
    longitude: 75.8009948730469,
    countryCode: "IN",
    city: "Jaipur",
  },
  {
    Id: "598",
    Name: "Jaipur International Airport ,Terminal 2",
    Type: "IATA",
    Code: "JAI",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 26.8220043182373,
    longitude: 75.8009948730469,
    countryCode: "IN",
    city: "Jaipur",
  },
  {
    Id: "605",
    Name: "Jodhpur Airport",
    Type: "IATA",
    Code: "JDH",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 26.2639484405518,
    longitude: 73.0505523681641,
    countryCode: "IN",
    city: "Jodhpur",
  },
  {
    Id: "1657",
    Name: "Maharana Pratap Airport",
    Type: "IATA",
    Code: "UDR",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 24.6191902160645,
    longitude: 73.8912658691406,
    countryCode: "IN",
    city: "Udaipur",
  },
  {
    Id: "802",
    Name: "Chennai International Airport, Terminal 1",
    Type: "IATA",
    Code: "MAA",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 12.9822673797607,
    longitude: 80.1637802124023,
    countryCode: "IN",
    city: "Chennai",
  },
  {
    Id: "157999",
    Name: "Chennai International Airport, Terminal 3",
    Type: "IATA",
    Code: "MAA",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 12.994112,
    longitude: 80.170867,
    countryCode: "IN",
    city: "Chennai",
  },
  {
    Id: "157999",
    Name: "Chennai International Airport, Terminal 2",
    Type: "IATA",
    Code: "MAA",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 12.994112,
    longitude: 80.170867,
    countryCode: "IN",
    city: "Chennai",
  },
  {
    Id: "158000",
    Name: "Chennai International Airport, Terminal 4",
    Type: "IATA",
    Code: "MAA",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 12.980974,
    longitude: 80.160972,
    countryCode: "IN",
    city: "Chennai",
  },
  {
    Id: "568",
    Name: "Rajiv Gandhi International Airport",
    Type: "IATA",
    Code: "HYD",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 17.2337017059326,
    longitude: 78.4304275512695,
    countryCode: "IN",
    city: "Hyderabad",
  },
  {
    Id: "320",
    Name: "Jolly Grant Airport",
    Type: "IATA",
    Code: "DED",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 30.1951385,
    longitude: 78.1899085,
    countryCode: "IN",
    city: "Dehradun",
  },
  {
    Id: "1711",
    Name: "Lal Bahadur Shastri Airport",
    Type: "IATA",
    Code: "VNS",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 25.4496974945068,
    longitude: 82.8537292480469,
    countryCode: "IN",
    city: "Varanasi",
  },

  {
    Id: "217",
    Name: "Netaji Subhash Chandra Bose International Airport",
    Type: "IATA",
    Code: "CCU",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 22.6453094482422,
    longitude: 88.4393081665039,
    countryCode: "IN",
    city: "Kolkata",
  },
  {
    Id: "2123",
    Name: "Veer Savarkar International Airport",
    Type: "IATA",
    Code: "IXZ",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 11.6502,
    longitude: 92.7331,
    countryCode: "IN",
    city: "Port Blair",
  },
  {
    Id: "2125",
    Name: "Lokpriya Gopinath Bordoloi International Airport , Terminal 1",
    Type: "IATA",
    Code: "GAU",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 26.1065,
    longitude: 91.586,
    countryCode: "IN",
    city: "Guwahati",
  },
  {
    Id: "2126",
    Name: "Lokpriya Gopinath Bordoloi International Airport , Terminal 2",
    Type: "IATA",
    Code: "GAU",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 26.1065,
    longitude: 91.586,
    countryCode: "IN",
    city: "Guwahati",
  },
  {
    Id: "2128",
    Name: "Gaya Airport",
    Type: "IATA",
    Code: "GAY",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 24.7488,
    longitude: 84.9437,
    countryCode: "IN",
    city: "Gaya",
  },
  {
    Id: "2129",
    Name: "Jayprakash Narayan Airport",
    Type: "IATA",
    Code: "PAT",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 25.595,
    longitude: 85.0908,
    countryCode: "IN",
    city: "Patna",
  },
  {
    Id: "2130",
    Name: "Swami Vivekananda International Airport",
    Type: "IATA",
    Code: "RPR",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 21.186,
    longitude: 81.7404,
    countryCode: "IN",
    city: "Raipur",
  },
  {
    Id: "2130",
    Name: "Atal Bihari Vajpai International Airport",
    Type: "IATA",
    Code: "DGH",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 24.446667,
    longitude: 86.706944,
    countryCode: "IN",
    city: "Deoghar",
  },
  {
    Id: "2130",
    Name: "Imphal International Airport",
    Type: "IATA",
    Code: "IMF",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 24.7647,
    longitude: 93.8971,
    countryCode: "IN",
    city: "Malom Tulihal",
  },
  {
    Id: "2130",
    Name: "Shillong Airport",
    Type: "IATA",
    Code: "SHL",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 25.7062,
    longitude: 91.9751,
    countryCode: "IN",
    city: "Umroi",
  },
  {
    Id: "2130",
    Name: "Dimapur Airport",
    Type: "IATA",
    Code: "DMU",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 25.88,
    longitude: 93.773,
    countryCode: "IN",
    city: "Dimapur",
  },
  {
    Id: "2130",
    Name: "Biju Patnaik International Airport",
    Type: "IATA",
    Code: "BBI",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 20.2507,
    longitude: 85.8145,
    countryCode: "IN",
    city: "Bhubaneswar",
  },
  {
    Id: "2130",
    Name: "Pakyong Airport",
    Type: "IATA",
    Code: "PYG",
    ShowType: "AIRPORT",
    Address: "",
    latitude: 27.2267,
    longitude: 88.5877,
    countryCode: "IN",
    city: "Pakyong",
  },
];

export default ariportJson;
