import React from "react";
import "./style.css";

export default function Whyfleet() {
  return (
    <>
      <div className="div_main_container_whyFleet">
        <h1 className="heading_why_fleet_see_here">Why fleet on rent?</h1>
        <div className="inner_div_view_is_here">
          <img src="/assets/Header/one.png" className="img_inner_check_here" />
          <img src="/assets/Header/two.png" className="img_inner_check_here" />
          <img
            src="/assets/Header/three.png"
            className="img_inner_check_here"
          />
          <img src="/assets/Header/four.png" className="img_inner_check_here" />
        </div>
        <div className="inner_div_view_is_here_two">
          <div className="div_check_second">
            <img
              src="/assets/Header/one.png"
              className="img_inner_check_here"
            />
            <img
              src="/assets/Header/two.png"
              className="img_inner_check_here"
            />
          </div>
          <div className="div_check_second">
            <img
              src="/assets/Header/three.png"
              className="img_inner_check_here"
            />
            <img
              src="/assets/Header/four.png"
              className="img_inner_check_here"
            />
          </div>
        </div>
      </div>
    </>
  );
}
