import "./RateModifier.css";
import React, { useState, useEffect } from "react";
import { RichTextEditor } from "@mantine/rte";
import indianCitiesDatabase from "indian-cities-database";
import pmlAPI from "../../../api/pmlAPI";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const RateModifier = () => {
  const navigate = useNavigate();
  const citess = indianCitiesDatabase.cities;
  let allstate = [
    {
      abbreviation: "nn",
      name: "Please Select State",
    },
    {
      abbreviation: "AN",
      name: "Andaman and Nicobar Islands",
    },
    {
      abbreviation: "AP",
      name: "Andhra Pradesh",
    },
    {
      abbreviation: "AR",
      name: "Arunachal Pradesh",
    },
    {
      abbreviation: "AS",
      name: "Assam",
    },
    {
      abbreviation: "BR",
      name: "Bihar",
    },
    {
      abbreviation: "CG",
      name: "Chandigarh",
    },
    {
      abbreviation: "CH",
      name: "Chhattisgarh",
    },
    {
      abbreviation: "DH",
      name: "Dadra and Nagar Haveli",
    },
    {
      abbreviation: "DD",
      name: "Daman and Diu",
    },
    {
      abbreviation: "DL",
      name: "Delhi",
    },
    {
      abbreviation: "GA",
      name: "Goa",
    },
    {
      abbreviation: "GJ",
      name: "Gujarat",
    },
    {
      abbreviation: "HR",
      name: "Haryana",
    },
    {
      abbreviation: "HP",
      name: "Himachal Pradesh",
    },
    {
      abbreviation: "JK",
      name: "Jammu and Kashmir",
    },
    {
      abbreviation: "JH",
      name: "Jharkhand",
    },
    {
      abbreviation: "KA",
      name: "Karnataka",
    },
    {
      abbreviation: "KL",
      name: "Kerala",
    },
    {
      abbreviation: "LD",
      name: "Lakshadweep",
    },
    {
      abbreviation: "MP",
      name: "Madhya Pradesh",
    },
    {
      abbreviation: "MH",
      name: "Maharashtra",
    },
    {
      abbreviation: "MN",
      name: "Manipur",
    },
    {
      abbreviation: "ML",
      name: "Meghalaya",
    },
    {
      abbreviation: "MZ",
      name: "Mizoram",
    },
    {
      abbreviation: "NL",
      name: "Nagaland",
    },
    {
      abbreviation: "OR",
      name: "Odisha",
    },
    {
      abbreviation: "PY",
      name: "Puducherry",
    },
    {
      abbreviation: "PB",
      name: "Punjab",
    },
    {
      abbreviation: "RJ",
      name: "Rajasthan",
    },
    {
      abbreviation: "SK",
      name: "Sikkim",
    },
    {
      abbreviation: "TN",
      name: "Tamil Nadu",
    },
    {
      abbreviation: "TS",
      name: "Telangana",
    },
    {
      abbreviation: "TR",
      name: "Tripura",
    },
    {
      abbreviation: "UP",
      name: "Uttar Pradesh",
    },
    {
      abbreviation: "UK",
      name: "Uttarakhand",
    },
    {
      abbreviation: "WB",
      name: "West Bengal",
    },
  ];

  const [cabData, setcabData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).id,
    carModel: "",
    luggage: "",
    seats: "",
    cabImage: "",
    region: "",
    city: "",
    fule_type: "",
    cab_type: "",
    oneway: true,
    onewaydata: {},
  });

  const [addoneway, setAddOneWay] = useState({
    base_fare: "",
    gst: "",
    term_and_condition: "",
    cancellation: "",
    pickupState: "",
    pickupCity: "",
    dropoffState: "",
    dropoffCity: "",
    pickup: "",
    dropoff: "",
  });

  const [citeies, setciteies] = useState([]);
  const [citiesTwo, setCitiesTwo] = useState([]);
  const [AllCabPool, setAllCabPool] = useState([]);
  const [cabName, setCabName] = useState([]);
  const [dataArrary, setDataArrary] = useState([]);

  const [pickupData, setpickUpData] = useState({
    pickupState: "",
    pickupCity: "",
    dropoffState: "",
    dropoffCity: "",
    pickup: "",
    dropoff: "",
  });

  const handleSearchScityOne = (value) => {
    let data = citess?.filter((w) => {
      return w.state == value;
    });

    if (data?.length > 0) {
      let addvalue = {
        city: "Please Select City ",
        state: "Please Select City",
      };
      data?.unshift(addvalue);
    }

    setciteies(data);
  };
  const handleSearchScityTwo = (value) => {
    let data = citess?.filter((w) => {
      return w.state == value;
    });

    if (data?.length > 0) {
      let addvalue = {
        city: "Please Select City ",
        state: "Please Select City",
      };
      data?.unshift(addvalue);
    }

    setCitiesTwo(data);
  };

  useEffect(() => {
    pmlAPI
      .get(`/api/cabpool`)
      .then((res) => {
        setAllCabPool(res.data);
        let selectData = res?.data?.map((w) => {
          return {
            carName: w?.carModel,
            id: w?._id,
          };
        });

        setCabName(selectData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const SelectCarModal = (id) => {
    let singleCabFormPool = AllCabPool?.filter((value) => {
      return value._id == id;
    });
    let singleData = singleCabFormPool[0];

    setcabData({
      ...cabData,
      carModel: singleData?.carModel,
      luggage: singleData?.luggage,
      seats: singleData?.seats,
      cabImage: singleData?.cabImage,
      fule_type: singleData?.fule_type,
      cab_type: singleData?.cab_type,
    });
  };

  const MainCheckBox = (e) => {
    const { name, checked } = e.target;
    setcabData({
      ...cabData,
      [name]: checked,
    });
  };

  //One Way

  const SingleFiledOneWay = (e) => {
    const { name, value } = e.target;
    setAddOneWay({
      ...addoneway,
      [name]: value,
    });
  };
  ///// handle Sweet Alert
  const handleSweeyAlert = (title, text, icon) => {
    swal({
      title: title,
      text: text,
      icon: icon,
    });
  };

  const pickSubButton = () => {
    let dataPush = {
      base_fare: "",
      gst: "",
      term_and_condition: "",
      cancellation: "",
      pickupState: pickupData?.pickupState,
      pickupCity: pickupData?.pickupCity,
      dropoffState: pickupData?.dropoffState,
      dropoffCity: pickupData?.dropoffCity,
      pickup: pickupData?.pickup,
      dropoff: pickupData?.dropoff,
    };

    if (pickupData?.pickup !== "" && pickupData?.dropoff !== "") {
      let Arrayspread = [...dataArrary];
      Arrayspread.push(dataPush);

      if (
        pickupData?.pickupState !== "" &&
        citeies?.length > 0 &&
        pickupData?.pickupCity == ""
      ) {
        handleSweeyAlert("Error", "Please Select Pick Up City", "error");
      } else if (
        pickupData?.dropoffState !== "" &&
        citiesTwo?.length > 0 &&
        pickupData?.dropoffCity == ""
      ) {
        handleSweeyAlert("Error", "Please Select Drop Off City", "error");
      } else {
        setDataArrary(Arrayspread);
        setpickUpData({
          ...pickupData,
          pickupState: "",
          pickupCity: "",
          dropoffState: "",
          dropoffCity: "",
          pickup: "",
          dropoff: "",
        });
      }
    } else {
      let errmassage = "";
      if (pickupData?.pickup == "") {
        errmassage = "Please Select Pick Up State";
      } else {
        errmassage = "Please Select Drop off Up State";
      }
      handleSweeyAlert("Error", errmassage, "error");
    }
  };

  const HandleFinalPage = async () => {
    if (cabData?.carModel == "") {
      handleSweeyAlert("Error", `Please Select Cab`, "error");
    } else {
      try {
        let arraIndd = [...dataArrary];
        let dataSetArray = [];
        await arraIndd?.map(async (w, i) => {
          cabData.city = w?.pickupCity || w?.pickupState;
          cabData.region = w?.pickupState;
          cabData.onewaydata = w;

          if (
            w?.base_fare !== "" &&
            w?.gst !== "" &&
            w?.term_and_condition !== "" &&
            w?.cancellation !== ""
          ) {
            await pmlAPI.post("/api/cabs/", cabData);
          } else {
            dataSetArray.push(w);
          }
        });

        if (dataSetArray?.length > 0) {
          handleSweeyAlert(
            "Warning",
            `Please Add Data To remain Cabs`,
            "warning"
          );
        } else {
          handleSweeyAlert("Success", `Cab Added Successfully`, "success");
        }

        setDataArrary(dataSetArray);
        if (dataSetArray?.length == 0) {
          navigate("/dashboard/oneway-cabs");
        }
      } catch (error) {
        console.log(error);
        handleSweeyAlert("Error", `There Is Error Try again`, "error");
      }
    }
  };

  useEffect(() => {
    let array = [1, 2, 3, 4, 5, 6, 7];
    array.splice(1, 2);

    for (var i = 0; i <= array?.length; i++) {
      if (array[i] == 4) {
        console.log("yes break ");
        break;
      } else {
        console.log("gogogogog");
      }
    }
  }, []);
  return (
    <div className="modifyWrapper">
      <div className="filter_section">
        {/* Car */}
        <div>
          <label>Car</label>
          <select
            onChange={(e) => {
              SelectCarModal(e.target.value);
            }}
          >
            <option value="none">Please Select Cab</option>;
            {cabName?.map((w) => {
              return <option value={w.id}>{w.carName}</option>;
            })}
          </select>
        </div>

        {/* State */}
        <div></div>

        {/* City */}
        <div></div>

        {/* Button */}
        <div className="buildBtn">
          <button
            onClick={() => {
              HandleFinalPage();
            }}
          >
            Build Changes
          </button>
        </div>
      </div>

      <div className="div_oneway_view_is_here">
        <div className="div_main_autocomplete_two">
          {/* State */}
          <div className="div_innner_state_wise">
            <label className="lable_inner_auto_comaple">State</label>
            <select
              className="select_btn_csss_is_here"
              value={pickupData.pickupState}
              onChange={(e) => {
                handleSearchScityOne(e.target.value);
                setpickUpData({
                  ...pickupData,
                  pickupState: e.target.value,
                  pickup: e.target.value,
                });
              }}
            >
              {allstate?.map((w) => {
                return <option value={w.name}>{w.name}</option>;
              })}
            </select>
          </div>

          {/* City */}

          {citeies?.length > 0 && (
            <div className="div_innner_state_wise">
              <label className="lable_inner_auto_comaple">City</label>

              <select
                className="select_btn_csss_is_here"
                value={pickupData.pickupCity}
                onChange={(e) => {
                  console.log(e.target.value);
                  setpickUpData({
                    ...pickupData,
                    pickupCity: e.target.value,
                    pickup: e.target.value,
                  });
                }}
              >
                {citeies?.map((w) => {
                  return (
                    <option value={`${w.city}  ${w.state}`}>{w.city}</option>
                  );
                })}
              </select>
            </div>
          )}
        </div>

        <div className="div_main_autocomplete_two">
          {/* State */}
          <div className="div_innner_state_wise">
            <label className="lable_inner_auto_comaple">State</label>
            <select
              className="select_btn_csss_is_here"
              value={pickupData.dropoffState}
              onChange={(e) => {
                handleSearchScityTwo(e.target.value);
                setpickUpData({
                  ...pickupData,
                  dropoffState: e.target.value,
                  dropoff: e.target.value,
                });
              }}
            >
              {allstate?.map((w) => {
                return <option value={w.name}>{w.name}</option>;
              })}
            </select>
          </div>

          {/* City */}

          {citiesTwo?.length > 0 && (
            <div className="div_innner_state_wise">
              <label className="lable_inner_auto_comaple">City</label>

              <select
                className="select_btn_csss_is_here"
                value={pickupData.dropoffCity}
                onChange={(e) => {
                  setpickUpData({
                    ...pickupData,
                    dropoffCity: e.target.value,
                    dropoff: e.target.value,
                  });
                }}
              >
                {citiesTwo?.map((w) => {
                  return (
                    <option value={`${w.city}  ${w.state}`}>{w.city}</option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
        <div className="div_main_autocomplete_two">
          <div className="div_innner_state_wise">
            <button
              onClick={pickSubButton}
              className="btn_inner_class_name_two"
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="name_view_div_here">
        <div className="div_for_zindex">
          {dataArrary?.length > 0 && (
            <>
              {dataArrary?.map((w, i) => {
                return (
                  <Accordion key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        {w?.pickup} - {w?.dropoff}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="form_box div_form_acord_innner_color">
                          <form>
                            <div className="div_inner_main_div">
                              <div className="base_fare div_form_inner_inpt_flex ">
                                <label>Base Fare :-</label>

                                <input
                                  type="number"
                                  id="basefare"
                                  name="base_fare"
                                  value={dataArrary[i]?.base_fare}
                                  onChange={(e) => {
                                    let arra = [...dataArrary];
                                    arra[i].base_fare = e.target.value;
                                    setDataArrary(arra);
                                  }}
                                />
                              </div>

                              <div className="base_fare div_form_inner_inpt_flex">
                                <label>GST :-</label>
                                <div className="inner_button_gst">
                                  <p className="percentage">% age</p>
                                </div>
                                <input
                                  type="number"
                                  id="gst"
                                  name="gst"
                                  min="0"
                                  max="100"
                                  value={dataArrary[i]?.gst}
                                  onChange={(e) => {
                                    let arra = [...dataArrary];
                                    arra[i].gst = e.target.value;
                                    setDataArrary(arra);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="div_inner_div_for_term_cancle">
                              <div className="userUpdateItem">
                                <label className="user__label">
                                  Terms & Conditions
                                </label>

                                <RichTextEditor
                                  style={{
                                    width: "450px",
                                  }}
                                  value={dataArrary[i]?.term_and_condition}
                                  controls={[
                                    ["bold", "italic", "underline"],
                                    ["unorderedList"],
                                  ]}
                                  onChange={(e) => {
                                    let arra = [...dataArrary];
                                    console.log(e);
                                    arra[i].term_and_condition = e;
                                    setDataArrary(arra);
                                  }}
                                />
                              </div>

                              <div className="userUpdateItem">
                                <label className="user__label">
                                  Cancellation Policy
                                </label>

                                <RichTextEditor
                                  style={{
                                    width: "450px",
                                  }}
                                  value={dataArrary[i]?.cancellation}
                                  controls={[
                                    ["bold", "italic", "underline"],
                                    ["unorderedList"],
                                  ]}
                                  onChange={(e) => {
                                    let arra = [...dataArrary];
                                    arra[i].cancellation = e;
                                    setDataArrary(arra);
                                  }}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RateModifier;
