import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import "../scss/components/international.css"

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  List,
  ListItemText,
  ListItem,
  Divider,
} from "@mui/material"
import pmlAPI from "../api/pmlAPI"
import { red } from "@mui/material/colors"

const International = () => {
  const navigate = useNavigate()
  const [data, setdata] = useState([])
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
    paddingTop: "0px",
    paddingBottom: "0px",
  }

  useEffect(async () => {
    await pmlAPI
      .get("/api/siteseen")
      .then((res) => {
        setdata(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const navigationlink = (value) => {
    navigate(`/sights/${value}`)
  }

  return (
    <>
      <div className="div_main_footer_inside_main">
        <Row>
          <Col>
            <main className="main">
              <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                  <h1 className="heading_why_fleet_see_here">Local Packages</h1>
                </Col>
                <Col sm={4}></Col>
              </Row>
              <div className="container">
                <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  swipeable={true}
                  infinite={true}
                >
                  {data?.map((w) => {
                    return (
                      <Card sx={{ maxWidth: 345 }} key={w._id}>
                        <CardMedia
                          component="img"
                          sx={{ height: 300 }}
                          image={w.stateImglink}
                          alt=""
                        />
                        <CardContent>
                          <Typography
                            marginLeft={"18px"}
                            color={"#ee0014"}
                            fontWeight={"bold"}
                            variant="h5"
                            component="div"
                          >
                            {w.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <List
                              sx={style}
                              //component="nav"
                              aria-label="mailbox folders"
                            >
                              <ListItem>
                                <span>Location :-</span>{" "}
                                <span>{w.sightLocation} </span>
                              </ListItem>
                              <Divider />
                              <ListItem>
                                <span>Type :-</span> <span>{w.typePark} </span>
                              </ListItem>
                            </List>
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            className="btn_tour"
                            variant="contained"
                            sx={{
                              fontSize: 13,
                              bgcolor: "#ee0014",
                              marginLeft: "20px",
                            }}
                            onClick={() => {
                              navigationlink(w.stateName)
                            }}
                          >
                            Book Now
                          </Button>
                        </CardActions>
                      </Card>
                    )
                  })}
                </Carousel>
              </div>
            </main>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default International
