import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
// Router
import { useNavigate } from "react-router-dom";

// Contes
import { useRideValue } from "../../context/rideContext";
import Footer from "../Footer";

// API
import pmlAPI from "../../api/pmlAPI";

import AirCard from "./AirCab";
import "../Allcss/checkout.css";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import one from "../../imges/one.svg";
import two from "../../imges/two.svg";
import three from "../../imges/three.svg";
import PinDropIcon from "@mui/icons-material/PinDrop";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import airposrt from "../airport";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Geocoder from "../Geocoder";
import { nanoid } from "nanoid";
import swal from "sweetalert";

const CabsScreen = () => {
  const [
    {
      pickup,
      dropoff,
      passengers,
      date,
      TypeTravel,
      distance,
      duration,
      hourlyRate,
      check,
    },
    dispatch,
  ] = useRideValue();
  const [cabs, setCabs] = useState([]);
  const Airportyy = JSON.parse(localStorage.getItem("AirPort"));
  const [loading, setLoading] = useState(true);
  const [travel, settravel] = useState([]);
  const [airPort, setairPort] = useState(Airportyy);
  const [fueltype, setfueltype] = useState([]);
  const [backupCab, setbackupCab] = useState([]);
  const [fuleTypeCheckBoxArray, setfuleTypeCheckBoxArray] = useState([]);
  const [cabTypeCheckBoxArray, setCabTypeCheckBoxArray] = useState([]);
  const [traveltype, settraveltype] = useState(TypeTravel);
  const [airportPickup, setairportPickup] = useState([]);
  const [AirPortData, setAirPortData] = useState([]);
  const [showdropOff, setShowDropOff] = useState(false);
  const [passangers, setPassangers] = useState({
    adults: passengers.adults,
    children: passengers.children,
    infants: passengers.infants,
  });
  const [refresh, setRefresh] = useState();
  const [datevale, setdatevle] = useState({
    to: "",
    toWithTime: "",
    from: "",
    minFrom: "",
  });
  const [showEdit, setShowEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState({
    Diesel: false,
    Petrol: false,
    CNG: false,
    Electric: false,
    Any: false,
    Hatchback: true,
    Sedan: false,
    SUV: false,
  });

  const stylesClick = {
    position: "absolute",
    top: 24,
    right: 0,
    left: -38,
    zIndex: 1,
    border: "1px solid",
    p: 1,
    bgcolor: "white",
    width: "fit-content",
  };
  const [cabType, setCabType] = useState(["Hatchback", "Sedan", "SUV"]);

  const navigate = useNavigate();

  useEffect(() => {
    const tra = JSON.parse(localStorage.getItem("travelDetail"));
    settraveltype(tra.TypeTravel);
    settravel(tra);
  }, []);

  const FilterByFuelType = (type, data) => {
    if (String(data) == "Any" && type) {
      setCabs(backupCab);
    } else {
      let Array = [];
      backupCab?.map((value) => {
        return fuleTypeCheckBoxArray?.map((w) => {
          if (w == value.fuel_type) {
            console.log(value);
            Array.push(value);
          }
        });
      });
      if (fuleTypeCheckBoxArray.length == 0) {
        console.log("fff");
        setCabs(backupCab);
      } else {
        console.log("qqq");
        console.log(Array);
        setCabs(Array);
      }
    }
  };

  const FuelTypeFunction = (data) => {
    let fuleArray = data?.map((value) => {
      return value?.fuel_type;
    });

    var filtered = fuleArray.filter(function (x) {
      return x !== undefined;
    });

    filtered.push("Any");

    let uniqueChars = [...new Set(filtered)];

    setfueltype(uniqueChars);
  };
  useEffect(() => {
    let value = airposrt
      ?.filter((w) => {
        return w?.country == "India";
      })
      .map((e, i) => {
        return {
          id: i,
          city: e?.city,
          name: e?.name,
          _geoloc: e?._geoloc,
          iata_code: e?.iata_code,
          value: ` ${e?.name} ${e?.city}`,
        };
      });

    setAirPortData(value);
  }, []);

  const onPageLoad = useCallback(() => {
    if (travel?.TypeTravel == "Airport") {
      let airportData = JSON.parse(localStorage.getItem("AirPort")).city;
      let localdata = JSON.parse(localStorage.getItem("travelDetail"));

      let typeoftravel = airportData.toLowerCase();
      let totalpassanger =
        Number(localdata?.passengers?.adults) +
        Number(localdata?.passengers?.children) +
        Number(localdata?.passengers?.infants);

      pmlAPI
        .get("/api/allcabuser")
        .then((res) => {
          let data = res?.data?.filter((e) => {
            let city = e.city?.toLowerCase();
            let region = e.region?.toLowerCase();

            if (
              city.includes(typeoftravel) ||
              (region.includes(typeoftravel) &&
                totalpassanger <= Number(e.seats))
            ) {
              return true;
            } else {
              return false;
            }
          });

          if (data?.length == 0) {
          } else {
            // console.log(data);
            setCabs(data);
            setbackupCab(data);
            FuelTypeFunction(data);
          }

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      let localdata = JSON.parse(localStorage.getItem("travelDetail"));

      let typeoftravel = localdata?.pickup?.text?.toLowerCase();
      let totalpassanger =
        Number(localdata?.passengers?.adults) +
        Number(localdata?.passengers?.children) +
        Number(localdata?.passengers?.infants);

      pmlAPI
        .get("/api/allcabuser")
        .then((res) => {
          const myArray = typeoftravel.split(" ");

          for (let i = 0; i < myArray?.length; i++) {
            let arrfi = myArray[i]?.toLowerCase();

            let data = res?.data?.filter((e) => {
              let city = e.city?.toLowerCase();
              let arr = [];

              if (city.includes(arrfi) && totalpassanger <= Number(e.seats)) {
                return true;
              } else {
                return false;
              }
            });

            if (data?.length == 0) {
            } else {
              // console.log(data);
              setCabs(data);
              setbackupCab(data);
              FuelTypeFunction(data);
            }
          }

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [travel]);

  useEffect(() => {
    if (!pickup || !date) navigate("/");
    onPageLoad();
  }, [pickup, date, navigate, onPageLoad]);

  const renderCabs = () =>
    cabs?.map((cab) => (
      <AirCard
        carModel={cab?.carModel}
        seats={cab?.seats}
        luggage={cab?.luggage}
        price={cab?.price}
        cabImage={cab?.cabImage}
        cabId={cab?.userId}
        distance={travel?.distance}
        region={cab?.region}
        pricePerHour={cab?.pricePerHour}
      />
    ));

  const ResetAllFunction = (data) => {
    let Array = [];
    backupCab?.map((value) => {
      return cabTypeCheckBoxArray?.map((w) => {
        if (w == value.cab_type) {
          Array.push(value);
        }
      });
    });
    if (cabTypeCheckBoxArray.length == 0) {
      setCabs(backupCab);
    } else {
      setCabs(Array);
    }
  };
  const handleOnSearch = (string, results) => {
    console.log({ string }, { results });
  };

  const handleOnSelect = (item) => {
    localStorage.setItem("AirPort", JSON.stringify(item));
    setairportPickup(item);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const sawlForAllError = (data) => {
    swal({
      title: "Error!",
      text: data,
      icon: "error",
    });
  };
  const setDistance = (data) => {
    console.log(data);
    localStorage.setItem("airPort_Distance", JSON.stringify(data));
    dispatch({
      type: "ADD_DISTANCE",
      distance: data,
    });
  };

  const handleSearchClick = async () => {
    let localvalu = {
      pickup: pickup,
      dropoff: dropoff,
      date: date,
      passengers: passengers,
      duration: duration,
      distance: distance,
      TypeTravel: traveltype,
      hourlyRate: hourlyRate,
      check: check,
    };

    localStorage.setItem("travelDetail", JSON.stringify(localvalu));
    if (traveltype == "Airport") {
      if (airportPickup && Object.keys(dropoff).length !== 0) {
        // first is longitude and then latitude

        fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${airportPickup?._geoloc?.lng},${airportPickup?._geoloc?.lat};${dropoff?.center[0]},${dropoff?.center[1]}?access_token=pk.eyJ1IjoiaGVjdG9yZzIyMTEiLCJhIjoiY2t0eWtxbmhtMDhwMTJwcG1jZXd0b3VhMSJ9.8XhBErdMP3PqsR-xN-NkMA`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.routes) {
              dispatch({
                type: "ADD_DURATION",
                duration: data.routes[0]?.duration,
              });
              setDistance(data.routes[0]?.distance);
            }
          });
      }

      if (Object.keys(airportPickup) == 0) {
        sawlForAllError("Select Pick Up Location");
      } else if (Object.keys(dropoff).length == 0) {
        sawlForAllError("Select Drop Off Location ");
      } else if (date?.length == 0) {
        sawlForAllError("Select PickUp Date  And Time");
      } else if (
        Number(passengers.adults) +
          Number(passengers.children) +
          Number(passengers.infants) ==
        0
      ) {
        sawlForAllError("Select Passengers");
      } else {
        window.location.reload(false);
      }

      // if (airportPickup && date) navigate("/airportCab");
    } else {
      if (traveltype === 3) {
        dispatch({
          type: "ADD_DROPOFF",
          dropoff: {},
        });
      }

      if (Object.keys(pickup).length == 0) {
        sawlForAllError("Select Pick Up Location ");
      } else if (
        traveltype == "outstation" &&
        Object.keys(dropoff).length == 0
      ) {
        sawlForAllError("Select Drop Off Location ");
      } else if (date?.length == 0) {
        sawlForAllError("Select PickUp Date  And Time");
      } else if (traveltype == "hourly" && hourlyRate == "") {
        dispatch({
          type: "HOURLY_RATE",
          hourlyRate: "2",
        });
      } else if (
        Number(passengers.adults) +
          Number(passengers.children) +
          Number(passengers.infants) ==
        0
      ) {
        sawlForAllError("Select Passengers");
      } else {
        navigate("/cabs");
      }
    }
  };

  return (
    <>
      <div className="cab-screen">
        <div className="cab-grid">
          <div className="cab_card_leftside">
            <div className="ride-info">
              <div
                className="ride-info__title "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "default",
                  fontSize: "12px",
                  alignItems: "center",
                }}
              >
                <h2 className="h2--2"> Your Transfer</h2>
                <div
                  className="divEditAndShow"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    background: "bule",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowEdit(!showEdit);
                  }}
                >
                  <span
                    style={{
                      color: "white",
                    }}
                  >
                    {showEdit ? "Show Trip" : "Edit Trip"}
                  </span>
                </div>
              </div>

              {showEdit ? (
                <>
                  <div className="ride-info__pickup">
                    <div
                      className="divtravelOfType"
                      style={
                        traveltype == "outstation"
                          ? { backgroundColor: "#fbe729", color: "#003566" }
                          : { backgroundColor: "white" }
                      }
                      onClick={() => {
                        let divtype = "outstation";
                        settraveltype("outstation");
                        dispatch({
                          type: "TypeTravel",
                          divtype,
                        });
                      }}
                    >
                      Outstation/Other
                    </div>
                    <div
                      className="divtravelOfType"
                      style={
                        traveltype == "hourly"
                          ? { backgroundColor: "#fbe729", color: "#003566" }
                          : { backgroundColor: "white" }
                      }
                      onClick={() => {
                        let divtype = "hourly";
                        settraveltype("hourly");
                        dispatch({
                          type: "TypeTravel",
                          divtype,
                        });
                      }}
                    >
                      Hourly
                    </div>
                    <div
                      className="divtravelOfType"
                      style={
                        traveltype == "Airport"
                          ? { backgroundColor: "#fbe729", color: "#003566" }
                          : { backgroundColor: "white" }
                      }
                      onClick={() => {
                        let divtype = "hourly";
                        settraveltype("Airport");
                        dispatch({
                          type: "TypeTravel",
                          divtype,
                        });
                      }}
                    >
                      Airport Pickup
                    </div>
                  </div>

                  <div className="booking__pickup borderNoneForDiv">
                    <div
                      style={{
                        display: "flex",
                        width: "calc(100%)",
                        color: "white",
                      }}
                    >
                      <PinDropIcon
                        sx={{
                          position: "relative",
                          top: "-5px",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                      <h2 className="h5" style={{ color: "white" }}>
                        Pick-up location
                      </h2>
                    </div>
                    {traveltype == "Airport" ? (
                      <div style={{ width: "100%" }}>
                        <ReactSearchAutocomplete
                          placeholder="Enter airport, hotel, address..."
                          items={AirPortData}
                          fuseOptions={{
                            keys: ["name", "city", "iata_code"],
                          }} // Search on both fields
                          resultStringKeyName="value" // String to display in the results
                          onSearch={handleOnSearch}
                          onSelect={handleOnSelect}
                          showIcon={false}
                          styling={{
                            height: "25px",
                            borderRadius: "3px",
                            backgroundColor: "white",
                            fontSize: "12px",
                            clearIconMargin: "3px 8px 0 0",
                          }}
                        />
                      </div>
                    ) : (
                      <Geocoder number={1} />
                    )}
                  </div>

                  {traveltype !== "hourly" && (
                    <div className="booking__dropoff borderNoneForDiv">
                      <div
                        style={{
                          display: "flex",
                          width: "calc(100% + 80%)",
                          color: "white",
                        }}
                      >
                        <PinDropIcon
                          sx={{
                            position: "relative",
                            top: "-5px",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        <h2 className="h5 " style={{ color: "white" }}>
                          Drop-off location
                        </h2>
                      </div>
                      <Geocoder number={2} />
                    </div>
                  )}

                  <div className="booking__date borderNoneForDiv">
                    <div
                      style={{
                        display: "flex",
                        width: "calc(100% )",
                        gap: "6px",
                      }}
                    >
                      <CalendarTodayIcon
                        sx={{
                          position: "relative",
                          top: "-5px",
                          width: "25px",
                          height: "25px",
                          color: "white",
                        }}
                      />
                      <h2 className="h5" style={{ color: "white" }}>
                        Pick-up Date & Time
                      </h2>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <input
                        type="date"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        style={{
                          padding: "5px",
                          textAlign: "center",
                          color: "#686868",
                          fontWeight: "600",
                          border: "1px solid #ccc",
                          height: "25px",
                          borderRadius: "3px",
                          backgroundColor: "white",
                          fontSize: "12px",
                          clearIconMargin: "3px 8px 0 0",
                          outline: "none",
                        }}
                        onChange={(e) => {
                          let todate = new Date(e.target.value);
                          let sendData = {
                            to: moment(todate).format(
                              "MMMM DD YYYY, h:mm:ss a"
                            ),
                            form: moment(todate)
                              .add(1, "days")
                              .format("MMMM DD YYYY, h:mm:ss a"),
                          };
                          setdatevle({
                            ...datevale,
                            to: moment(todate).format(
                              "MMMM DD YYYY, h:mm:ss a"
                            ),
                            from: moment(todate)
                              .add(1, "days")
                              .format("MMMM DD YYYY, h:mm:ss a"),
                          });
                          dispatch({
                            type: "ADD_DATE",
                            date: sendData,
                          });
                        }}
                      />
                      <input
                        type="time"
                        style={{
                          padding: "5px",
                          textAlign: "center",
                          color: "#686868",
                          fontWeight: "600",
                          border: "1px solid #ccc",
                          height: "25px",
                          borderRadius: "3px",
                          backgroundColor: "white",
                          fontSize: "12px",
                          clearIconMargin: "3px 8px 0 0",
                          outline: "none",
                        }}
                        onChange={(e) => {
                          let toTime = e.target.value;

                          let wholeTo = new Date(
                            `${moment(datevale.to).format(
                              "MMM DD YYYY"
                            )}  ${toTime}`
                          );
                          let dateAll = {
                            to: wholeTo,
                            from: datevale.from,
                          };

                          setdatevle({
                            ...datevale,
                            to: wholeTo,
                          });
                          dispatch({
                            type: "ADD_DATE",
                            date: dateAll,
                          });
                        }}
                      />
                    </div>
                  </div>

                  {traveltype !== "hourly" ? (
                    <>
                      {showdropOff ? (
                        <div className="booking__date borderNoneForDiv">
                          <div
                            style={{
                              display: "flex",
                              width: "calc(100% )",
                              gap: "6px",
                            }}
                          >
                            <CalendarTodayIcon
                              sx={{
                                position: "relative",
                                top: "-5px",
                                width: "25px",
                                height: "25px",
                                color: "white",
                              }}
                            />
                            <h2 className="h5" style={{ color: "white" }}>
                              Drop Off Date & Time
                            </h2>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <input
                              type="date"
                              min={moment(datevale.to || new Date())
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                color: "#686868",
                                fontWeight: "600",
                                border: "1px solid #ccc",
                                height: "25px",
                                borderRadius: "3px",
                                backgroundColor: "white",
                                fontSize: "12px",
                                clearIconMargin: "3px 8px 0 0",
                                outline: "none",
                              }}
                              onChange={(e) => {
                                let todate = new Date(e.target.value);
                                let sendData = {
                                  to: datevale.to,
                                  form: moment(todate).format(
                                    "MMMM DD YYYY, h:mm:ss a"
                                  ),
                                };

                                setdatevle({
                                  ...datevale,
                                  from: moment(todate).format(
                                    "MMMM DD YYYY, h:mm:ss a"
                                  ),
                                });
                                dispatch({
                                  type: "ADD_DATE",
                                  date: sendData,
                                });
                              }}
                            />
                            <input
                              type="time"
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                color: "#686868",
                                fontWeight: "600",
                                border: "1px solid #ccc",
                                height: "25px",
                                borderRadius: "3px",
                                backgroundColor: "white",
                                fontSize: "12px",
                                clearIconMargin: "3px 8px 0 0",
                                outline: "none",
                              }}
                              onChange={(e) => {
                                let toTime = e.target.value;

                                let wholeFrom = new Date(
                                  `${moment(datevale.from).format(
                                    "MMM DD YYYY"
                                  )}  ${toTime}`
                                );
                                let dateAll = {
                                  from: wholeFrom,
                                  to: datevale.from,
                                };

                                setdatevle({
                                  ...datevale,
                                  from: wholeFrom,
                                });
                                dispatch({
                                  type: "ADD_DATE",
                                  date: dateAll,
                                });
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="booking__date borderNoneForDiv">
                        <div
                          style={{
                            display: "flex",
                            width: "calc(100% )",
                            gap: "6px",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setShowDropOff(e.target.checked);
                              dispatch({
                                type: "ADD_RETURN",
                                check: e.target.checked,
                              });
                            }}
                          />
                          <h2 className="h5" style={{ color: "white" }}>
                            Add Return
                          </h2>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="booking__date borderNoneForDiv">
                      <select
                        style={{
                          padding: "5px",
                          textAlign: "center",
                          color: "#7e7e7e",
                          border: "1px solid #ccc",
                          height: "25px",
                          borderRadius: "3px",
                          backgroundColor: "white",
                          fontSize: "12px",
                          clearIconMargin: "3px 8px 0 0",
                          outline: "none",
                          width: "100%",
                        }}
                        onChange={(e) => {
                          dispatch({
                            type: "HOURLY_RATE",
                            hourlyRate: e.target.value,
                          });
                        }}
                      >
                        <option value="2">2hr/20km</option>
                        <option value="4">4hr/40km</option>
                        <option value="6">6hr/60km</option>
                        <option value="8">8hr/80km</option>
                        <option value="10">10hr/100km</option>
                        <option value="12">12hr/120km</option>
                      </select>
                    </div>
                  )}

                  <div className="booking__date borderNoneForDiv">
                    <div
                      style={{
                        display: "flex",
                        width: "calc(100% )",
                        color: "white",
                      }}
                    >
                      <PermIdentityIcon
                        sx={{
                          position: "relative",
                          top: "-5px",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                      <h2 className="h5 " style={{ color: "white" }}>
                        Passengers
                      </h2>
                    </div>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Box sx={{ position: "relative", width: "100%" }}>
                          <button
                            type="button"
                            className="passenger-btn"
                            onClick={handleClick}
                            style={{
                              border: "none",
                              padding: "5px 10px",
                              background: "white",
                              outline: "none",
                              width: "100%",
                            }}
                          >
                            <h2
                              className="h2 very_high"
                              style={{
                                border: "none",
                                background: "white",
                                outline: "none",
                                fontSize: "12px",
                                margin: "0px",
                              }}
                            >
                              {passangers.adults} Adults,
                              {passangers.children} Children,
                              {passangers.infants} Infant(s)
                            </h2>
                          </button>
                          {open ? (
                            <Box sx={stylesClick}>
                              <div style={{ display: "flex", gap: "5px" }}>
                                <div style={{ display: "flex", gap: "2px" }}>
                                  <h6
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Adults
                                  </h6>
                                  <span
                                    className="passangeSpan"
                                    onClick={() => {
                                      if (Number(passangers.adults) > 0) {
                                        setPassangers({
                                          ...passangers,
                                          adults: Number(passangers.adults) - 1,
                                        });
                                        dispatch({
                                          type: "SUBTRACT_PASSENGERS_ADULTS",
                                          passengers,
                                        });
                                      }
                                    }}
                                  >
                                    -
                                  </span>
                                  <span className="passNumber">
                                    {passangers.adults}
                                  </span>
                                  <span
                                    className="passangeSpan"
                                    onClick={() => {
                                      dispatch({
                                        type: "ADD_PASSENGERS_ADULTS",
                                        passengers,
                                      });
                                      setPassangers({
                                        ...passangers,
                                        adults: Number(passangers.adults) + 1,
                                      });
                                    }}
                                  >
                                    +
                                  </span>
                                </div>
                                <div style={{ display: "flex", gap: "2px" }}>
                                  <h6
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Children
                                  </h6>
                                  <span
                                    className="passangeSpan"
                                    onClick={() => {
                                      if (Number(passangers.children) > 0) {
                                        dispatch({
                                          type: "SUBTRACT_PASSENGERS_CHILDREN",
                                          passengers,
                                        });
                                        setPassangers({
                                          ...passangers,
                                          children:
                                            Number(passangers.children) - 1,
                                        });
                                      }
                                    }}
                                  >
                                    -
                                  </span>
                                  <span className="passNumber">
                                    {passangers.children}
                                  </span>
                                  <span
                                    className="passangeSpan"
                                    onClick={() => {
                                      dispatch({
                                        type: "ADD_PASSENGERS_CHILDREN",
                                        passengers,
                                      });
                                      setPassangers({
                                        ...passangers,
                                        children:
                                          Number(passangers.children) + 1,
                                      });
                                    }}
                                  >
                                    +
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "2px",
                                  }}
                                >
                                  <h6
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Infants
                                  </h6>
                                  <span
                                    className="passangeSpan"
                                    onClick={() => {
                                      if (Number(passangers.infants) > 0) {
                                        dispatch({
                                          type: "SUBTRACT_PASSENGERS_INFANTS",
                                          passengers,
                                        });
                                        setPassangers({
                                          ...passangers,
                                          infants:
                                            Number(passangers.infants) - 1,
                                        });
                                      }
                                    }}
                                  >
                                    -
                                  </span>
                                  <span className="passNumber">
                                    {passangers.infants}
                                  </span>
                                  <span
                                    className="passangeSpan"
                                    onClick={() => {
                                      dispatch({
                                        type: "ADD_PASSENGERS_INFANTS",
                                        passengers,
                                      });
                                      setPassangers({
                                        ...passangers,
                                        infants: Number(passangers.infants) + 1,
                                      });
                                    }}
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                            </Box>
                          ) : null}
                        </Box>
                      </ClickAwayListener>
                    </div>
                  </div>

                  <div className="booking__date borderNoneForDiv endSubmit">
                    <button
                      onClick={() => {
                        handleSearchClick();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {travel?.TypeTravel == "Airport" ? (
                    <>
                      <div
                        className="ride-info__pickup"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <PinDropIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "white",
                              marginRight: "2px",
                            }}
                          />
                          <h3 className="h3 h3--1 super_head">
                            Pick-up location
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">{airPort?.value}</h3>
                        </div>
                      </div>
                      {travel?.dropoff?.place_name && (
                        <div
                          className="ride-info__dropoff"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ display: "flex" }}>
                            <PinDropIcon
                              sx={{
                                position: "relative",
                                top: "-5px",
                                width: "25px",
                                height: "22px",
                                color: "white",
                                marginRight: "2px",
                              }}
                            />
                            <h3 className="h3 h3--1 super_head">
                              Drop-off location
                            </h3>
                          </div>
                          <div style={{ marginLeft: "25px" }}>
                            <h3 className="h3">
                              {travel?.dropoff?.place_name}
                            </h3>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="ride-info__pickup"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <PinDropIcon
                            sx={{
                              position: "relative",
                              top: "-5px",
                              width: "25px",
                              height: "22px",
                              color: "white",
                              marginRight: "2px",
                            }}
                          />
                          <h3 className="h3 h3--1 super_head">
                            Pick-up location
                          </h3>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <h3 className="h3">{travel?.pickup?.place_name}</h3>
                        </div>
                      </div>
                      {travel?.dropoff?.place_name && (
                        <div className="ride-info__dropoff">
                          <h3 className="h3 h3--1 super_head">
                            Drop-off location
                          </h3>
                          <h3 className="h3">{airPort?.value}</h3>
                        </div>
                      )}
                    </>
                  )}

                  <div
                    className="ride-info__date"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ display: "flex" }}>
                      <CalendarTodayIcon
                        sx={{
                          position: "relative",
                          top: "-5px",
                          width: "25px",
                          height: "22px",
                          color: "white",
                          marginRight: "2px",
                        }}
                      />
                      <h3 className="h3 h3--1 super_head ">Pick-up Date </h3>
                    </div>
                    <div style={{ marginLeft: "25px" }}>
                      {" "}
                      <h3 className="h3">
                        {moment(travel?.date?.to).format(
                          "MMMM Do YYYY, h:mm a"
                        )}{" "}
                        {travel?.date?.from ? "To" : ""}{" "}
                        {moment(travel?.date?.from).format(
                          "MMMM Do YYYY, h:mm a"
                        )}
                      </h3>
                    </div>
                  </div>
                  <div
                    className="ride-info__passengers"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ display: "flex" }}>
                      <PermIdentityIcon
                        sx={{
                          position: "relative",
                          top: "-5px",
                          width: "25px",
                          height: "22px",
                          color: "white",
                          marginRight: "2px",
                        }}
                      />
                      <h3 className="h3 h3--1 super_head">Passengers</h3>
                    </div>
                    <div style={{ marginLeft: "25px" }}>
                      <h3 className="h3">
                        {travel?.passengers?.adults} adt,{" "}
                        {travel?.passengers?.children} chd,{" "}
                        {travel?.passengers?.infants} inf
                      </h3>
                    </div>
                  </div>
                </>
              )}

              <div className="right_banner">
                <Card style={{ width: "30rem" }}>
                  <Card.Header className="heading ">
                    Why Sign up or Login?
                  </Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Row>
                        <Col sm={3}>
                          {" "}
                          <img src={one} />
                        </Col>
                        <Col sm={9}>
                          <Card.Title>Unlock exclusive benefits</Card.Title>
                          <Card.Text>
                            Get amazing discounts on the first ride and
                            additional on others
                          </Card.Text>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col sm={3}>
                          <img src={two} />
                        </Col>
                        <Col sm={9}>
                          <Card.Title>
                            Track & Manage your bookings from one place
                          </Card.Title>
                          <Card.Text>
                            You can keep a track of your cab & manage all
                            bookings from one place
                          </Card.Text>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col sm={3}>
                          <img src={three} />
                        </Col>
                        <Col sm={9}>
                          <Card.Title>
                            Book faster with the saved details
                          </Card.Title>
                          <Card.Text>
                            The saved details can be used again to book your cab
                            ride
                          </Card.Text>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </div>
            </div>
            <div>
              <div className=" filter_card">
                <div className="ttl_sec">
                  <h3>Filter</h3>
                  {/* <button>Reset All</button> */}
                </div>
                <form>
                  <div className="panel">
                    <div className="hx1">Cab Type</div>
                    {cabType?.map((w, i) => {
                      return (
                        <div className="fuel_check">
                          <input
                            type="checkbox"
                            id="vehicle2"
                            name="vehicle2"
                            value={w}
                            onChange={(e) => {
                              setShow({
                                ...show,
                                [w]: e.target.checked,
                              });
                              let chackBoxarr = cabTypeCheckBoxArray;
                              if (e.target.checked) {
                                chackBoxarr.push(e.target.value);
                              } else {
                                let myArray = chackBoxarr.indexOf(
                                  e.target.value
                                );
                                chackBoxarr.splice(myArray, 1);
                              }
                              setCabTypeCheckBoxArray(chackBoxarr);

                              ResetAllFunction();
                            }}
                          />
                          <label for="vehicle2">{w}</label>
                          {/* <span className="fltr">{i+4}</span> */}
                        </div>
                      );
                    })}
                  </div>

                  <div className="panel">
                    <div className="hx1">Fuel Type</div>
                    {fueltype?.map((w) => {
                      return (
                        <>
                          <div className="fuel_check" key={w}>
                            <input
                              type="checkbox"
                              id="vehicle"
                              name="vehicle"
                              value={w}
                              // checked={AllShow?true:show.w}
                              onClick={(e) => {
                                console.log(show);
                                let chackBoxarr = fuleTypeCheckBoxArray;
                                if (e.target.checked) {
                                  chackBoxarr.push(e.target.value);

                                  console.log(chackBoxarr);
                                } else {
                                  let myArray = chackBoxarr.indexOf(
                                    e.target.value
                                  );
                                  chackBoxarr.splice(myArray, 1);
                                  console.log(chackBoxarr);
                                }
                                setfuleTypeCheckBoxArray(chackBoxarr);

                                FilterByFuelType(
                                  e.target.checked,
                                  e.target.value
                                );
                              }}
                            />
                            <label for="vehicle1">{w}</label>
                            {/* <span className="fltr">4</span> */}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="cab-info">
            <div className="banner">
              <div className="banner__info">
                <div className="banner__img">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/126/126473.png"
                    alt="like"
                  />
                </div>
                <div className="banner__text ">
                  <h3 className="h3">Save up to 30%</h3>
                  <p className="p p--2">
                    We will find you the cheapest airport transfers with our
                    verified partners!
                  </p>
                </div>
              </div>
              <div className="banner__info">
                <div className="banner__img">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/4634/4634905.png"
                    alt="like"
                  />
                </div>
                <div className="banner__text">
                  <h3 className="h3">Professional drivers</h3>
                  <p className="p p--2">
                    Experienced and polite drivers with well-serviced,
                    comfortable cabs.
                  </p>
                </div>
              </div>
              <div className="banner__info">
                <div className="banner__img">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/912/912316.png"
                    alt="like"
                  />
                </div>
                <div className="banner__text">
                  <h3 className="h3">24 / 7 Customer Support</h3>
                  <p className="p p--2">
                    We are always available to help you with your transfer
                  </p>
                </div>
              </div>
            </div>

            {!loading && renderCabs()}
            {!loading && cabs.length === 0 && <h1>No cabs found</h1>}
            {loading && (
              <>
                <div className="cab-card">
                  <div className="cab-card__image">
                    <Skeleton height="200px" width="200px" />
                  </div>

                  <div className="cab-card__details">
                    <h2 className="h2--2">
                      <Skeleton count={3} />
                    </h2>
                  </div>

                  <div className="cab-card__price">
                    <h2 className="h2--2">
                      <Skeleton count={2} />
                    </h2>
                  </div>
                </div>
                <div className="cab-card">
                  <div className="cab-card__image">
                    <Skeleton height="200px" width="200px" />
                  </div>

                  <div className="cab-card__details">
                    <h2 className="h2--2">
                      <Skeleton count={3} />
                    </h2>
                  </div>

                  <div className="cab-card__price">
                    <h2 className="h2--2">
                      <Skeleton count={2} />
                    </h2>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CabsScreen;
