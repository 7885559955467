import React, { useState, useEffect } from "react";
import "./Allcss/Allcab.css";
import pmlAPI from "../api/pmlAPI";
import nodataimg from "../imges/nodataFound.png";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

export default function AllCabs() {
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [mainData, setDataMain] = useState([]);
  const [status, setStatus] = useState(false);
  const [forrefresh, setforrefresh] = useState();
  const [selectvalu, setselectvalu] = useState([]);
  const [fillsel, setfillsel] = useState();

  useEffect(async () => {
    const id = JSON.parse(localStorage.getItem("userData")).id;

    await pmlAPI
      .get(`/api/cabs`)
      .then((res) => {
        let resdataFinal = res?.data?.cab?.filter((w) => {
          return w.airport;
        });
        let resdata = resdataFinal?.map((w) => {
          return w.city;
        });

        let uniqueChars = [...new Set(resdata)];

        setselectvalu(uniqueChars);
        setdata(resdataFinal);

        setStatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(async () => {
    try {
      const id = JSON.parse(localStorage.getItem("userData")).id;

      let res = await pmlAPI("/api/cabs/");
      let resdataFinal = res?.data?.cab?.filter((w) => {
        return w.airport;
      });

      setdata(resdataFinal);
      setDataMain(resdataFinal);
      setStatus(true);
    } catch (error) {
      console.error(error);
    }
  }, [forrefresh]);

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("userData")).id;

    pmlAPI
      .get(`/api/cabs`)
      .then((res) => {
        let fildata = res?.data?.cab?.filter((w) => {
          let selcity = fillsel?.toLowerCase();
          let tolowercity = w?.city?.toLowerCase();
          return selcity == tolowercity;
        });

        //setdata(fildata)

        setStatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fillsel]);

  const handleCabEdit = (id) => {
    navigate(`/dashboard/aiport-edit/${id}`);
  };

  const handleChangeCab = (value) => {
    if (value == "All") {
      setdata(mainData);
    } else if (value == "pick") {
      let datafilpick = mainData?.filter((w) => {
        return w?.airportdata?.airType == value;
      });

      setdata(datafilpick);
    } else if (value == "drop") {
      let datafilpick = mainData?.filter((w) => {
        return w?.airportdata?.airType == value;
      });

      setdata(datafilpick);
    } else {
      setdata(mainData);
    }
  };

  return (
    <>
      <div className="row ">
        {status ? (
          <>
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-8"></div>
                <div className="col-sm-2">
                  <select
                    style={{
                      fontSize: "15px",
                      height: "23px",
                      width: "10vw",
                    }}
                    onChange={(e) => {
                      handleChangeCab(e.target.value);
                    }}
                  >
                    <option value="All">Select Cab Type </option>
                    <option value="All"> All Cab </option>
                    <option value="pick"> Airport Pickup </option>
                    <option value="drop"> Airport DropOff </option>
                  </select>
                </div>
                <div className="col-sm-2">
                  <select
                    style={{
                      fontSize: "15px",
                      height: "23px",
                      width: "10vw",
                    }}
                    onClick={(e) => {
                      const id = JSON.parse(
                        localStorage.getItem("userData")
                      ).id;
                      pmlAPI
                        .get(`/api/cabs`)
                        .then((res) => {
                          //console.log(res.data.cab)
                          let resdataFinal = res?.data?.cab?.filter((w) => {
                            return w.airport;
                          });
                          let fildata = resdataFinal?.filter((w) => {
                            let selcity = e.target.value?.toLowerCase();
                            let tolowercity = w?.city?.toLowerCase();
                            return selcity.includes(tolowercity);
                          });

                          if (e.target.value == "none") {
                            setdata(resdataFinal);
                          } else {
                            setdata(fildata);
                          }

                          setStatus(true);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    <option value="none">Select City</option>

                    {selectvalu?.map((w) => {
                      return (
                        <option value={w} key={w}>
                          {w}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {data &&
              data?.map((w) => {
                return (
                  <>
                    <div className="col-sm-9 mainallcabdiv" key={w._id}>
                      <div className="row secondallcabdiv">
                        <div className="col-sm-5">
                          <img
                            src={w.cabImage}
                            alt="user"
                            className="imgallcabs"
                          />
                        </div>
                        <div className="col-sm-3">
                          <div>
                            <label className="text_inrease_lable">
                              Car Model
                            </label>
                            <h4 className="allcabsheading">{w?.carModel}</h4>
                          </div>
                          <div>
                            <label className="text_inrease_lable">
                              Available Seats
                            </label>
                            <h4> {w?.seats} </h4>
                          </div>
                          <div>
                            <label className="text_inrease_lable">
                              Luggage Space
                            </label>
                            <h4> {w?.luggage} </h4>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div>
                            <label className="text_inrease_lable">City </label>
                            <h4> {w?.city} </h4>
                          </div>
                          <div>
                            <label className="text_inrease_lable">
                              Created At{" "}
                            </label>
                            <h4> {w?.createdAt} </h4>
                          </div>
                        </div>
                        <div className="col-sm-1">
                          <div className="icondiv">
                            <a
                              onClick={() => {
                                handleCabEdit(w?._id);
                              }}
                            >
                              <EditIcon
                                className="svg_icons"
                                style={{
                                  color: "black",
                                }}
                              />
                            </a>
                          </div>
                          <div className="icondiv">
                            <a
                              onClick={() => {
                                navigate(
                                  `/dashboard/view-cab-air-oneway/${w?._id}`
                                );
                              }}
                            >
                              <RemoveRedEyeIcon
                                className="svg_icons"
                                style={{
                                  color: "black",
                                }}
                              />
                            </a>
                          </div>

                          <div className="icondiv">
                            <a
                              onClick={() => {
                                swal({
                                  title: "Are you sure?",
                                  text: "Want To delete Cab",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    pmlAPI
                                      .delete(`/api/cabs/${w._id}`)
                                      .then((res) => {
                                        setforrefresh(nanoid());
                                        swal(
                                          "Success!",
                                          "Cab Deleted Succcessfully",
                                          "success"
                                        );
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  } else {
                                    swal("Cab  is safe!");
                                  }
                                });
                              }}
                            >
                              <RemoveCircleOutlineIcon
                                className="svg_icons"
                                style={{
                                  color: "black",
                                }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </>
        ) : (
          <div className="col-sm-12 mainallcabdiv">
            <div className="row secondallcabdiv">
              <div className="col-sm-2">
                <img src={nodataimg} alt="user" className="imgallcabs" />
              </div>
              <div className="col-sm-5">
                <div>
                  <label className="text_inrease_lable">Car Model</label>
                  <h4 className="allcabsheading"> No Data Found</h4>
                </div>
                <div>
                  <label className="text_inrease_lable">Available Seats</label>
                  <h4> No Data Found </h4>
                </div>
                <div>
                  <label className="text_inrease_lable">Luggage Space</label>
                  <h4> No Data Found</h4>
                </div>
              </div>
              <div className="col-sm-5">
                <div>
                  <label className="text_inrease_lable">Price Per KM</label>
                  <h4> No Data Found </h4>
                </div>
                <div>
                  <label className="text_inrease_lable">Car ID </label>
                  <h4> No Data Found</h4>
                </div>
                <div>
                  <label className="text_inrease_lable">Created At </label>
                  <h4> No Data Found </h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
