import React, { useState, useEffect } from "react";
import "./Allcss/Allcab.css";
import pmlAPI from "../api/pmlAPI";
import nodataimg from "../imges/nodataFound.png";
import EditIcon from "@mui/icons-material/Edit";
import { Modal, Button, Form } from "react-bootstrap";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { height } from "@mui/system";

export default function AllPoolCab() {
  const [data, setdata] = useState([]);
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [editcab, seteditcab] = useState([]);
  const [forrefresh, setforrefresh] = useState();
  const [selectvalu, setselectvalu] = useState([]);
  const [fillsel, setfillsel] = useState();

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("userData")).id;

    pmlAPI
      .get(`/api/cabpool`)
      .then((res) => {
        console.log(res.data);

        //  setselectvalu(uniqueChars);
        setdata(res.data);

        setStatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("userData")).id;

    pmlAPI
      .get(`/api/cabpool`)
      .then((res) => {
        setdata(res.data);
        setStatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [forrefresh]);

  const handleClose = () => {
    setShow(false);
  };

  const editcabFunction = () => {
    const data = {
      carModel: editcab.carModel,
      luggage: editcab.luggage,
      seats: editcab.seats,
      cab_type: editcab.cab_type,
      fule_type: editcab.fule_type,
      cabImage: editcab.cabImage,
    };

    const id = editcab._id;

    pmlAPI
      .put(`/api/cabpool/${id}`, data)
      .then((res) => {
        setforrefresh(Math.random());
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function encodeImageFileAsURL(element) {
    var file = element.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      seteditcab({
        ...editcab,
        cabImage: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text_inrease_lable">
            Add Master Cab
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="cab-form">
            <div className="cab-form__container">
              <div className="cab-form__input">
                <label htmlFor="carModel">
                  <p className="p p--1">Car Model</p>
                </label>
                <input
                  className="inputAllcab"
                  type="text"
                  id="carModel"
                  placeholder="Car model..."
                  value={editcab.carModel}
                  onChange={(e) => {
                    seteditcab({
                      ...editcab,
                      carModel: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="cab-form__container">
              <div className="cab-form__input">
                <label htmlFor="carLuggage">
                  <p className="p p--1">Luggage space</p>
                </label>
                <input
                  className="inputAllcab"
                  type="number"
                  id="carLuggage"
                  min="0"
                  value={editcab.luggage}
                  onChange={(e) => {
                    seteditcab({
                      ...editcab,
                      luggage: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="cab-form__container">
              <div className="cab-form__input">
                <label htmlFor="carSeats">
                  <p className="p p--1">Available seats</p>
                </label>
                <input
                  className="inputAllcab"
                  type="number"
                  id="carSeats"
                  min="0"
                  value={editcab.seats}
                  onChange={(e) => {
                    seteditcab({
                      ...editcab,
                      seats: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="cab-form__container">
              <div className="cab-form__input">
                <label htmlFor="cabType">
                  <p className="p p--1">Cab Type</p>
                </label>

                <select
                  className="inputAllcab"
                  value={editcab.cab_type}
                  onChange={(e) => {
                    seteditcab({
                      ...editcab,
                      cab_type: e.target.value,
                    });
                  }}
                >
                  <option value="Hatchback">Hatchback</option>
                  <option value="Sedan">Sedan</option>
                  <option value="SUV">SUV</option>
                </select>
              </div>
            </div>
            <div className="cab-form__container">
              <div className="cab-form__input">
                <label htmlFor="fuelType">
                  <p className="p p--1">Fuel Type</p>
                </label>
                <select
                  className="inputAllcab"
                  value={editcab.fule_type}
                  onChange={(e) => {
                    seteditcab({
                      ...editcab,
                      fule_type: e.target.value,
                    });
                  }}
                >
                  <option value="Diesel">Diesel</option>
                  <option value="Petrol">Petrol</option>
                  <option value="CNG">CNG</option>
                  <option value="Electric">Electric</option>
                </select>
              </div>
            </div>

            <div className="cab-form__container">
              <div className="cab-form__input">
                <label htmlFor="carImage">
                  <p className="p p--1">Car image </p>
                </label>
                <div>
                  <img
                    src={editcab.cabImage}
                    style={{
                      height: "100px",
                      width: "100%",
                      maxWidth: "500px",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="cab-form__container">
              <div className="cab-form__input">
                <label htmlFor="carImage">
                  <p className="p p--1">Car image URL</p>
                </label>
                <input
                  className="inputAllcab"
                  type="file"
                  id="carImage"
                  accept="image/x-png,image/gif,image/jpeg ,image/jpg,image/png"
                  placeholder="Car image..."
                  onChange={(e) => {
                    encodeImageFileAsURL(e);
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              editcabFunction();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row ">
        {status ? (
          <>
            {data?.map((w) => {
              return (
                <>
                  <div className="col-sm-9 mainallcabdiv" key={w._id}>
                    <div className="row secondallcabdiv">
                      <div className="col-sm-5">
                        <img
                          src={w.cabImage}
                          alt="user"
                          className="imgallcabs"
                        />
                      </div>
                      <div className="col-sm-3">
                        <div>
                          <label className="text_inrease_lable">
                            Car Model
                          </label>
                          <h4 className="allcabsheading">{w?.carModel}</h4>
                        </div>
                        <div>
                          <label className="text_inrease_lable">
                            Available Seats
                          </label>
                          <h4> {w?.seats} </h4>
                        </div>
                        <div>
                          <label className="text_inrease_lable">
                            Luggage Space
                          </label>
                          <h4> {w?.luggage} </h4>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div>
                          <label className="text_inrease_lable">Cab Type</label>
                          <h4> {w?.cab_type} </h4>
                        </div>
                        <div>
                          <label className="text_inrease_lable">
                            Fuel Type
                          </label>
                          <h4> {w?.fule_type} </h4>
                        </div>

                        <div>
                          <label className="text_inrease_lable">City </label>
                          <h4> {w?.city} </h4>
                        </div>
                        <div>
                          <label className="text_inrease_lable">
                            Created At{" "}
                          </label>
                          <h4> {w?.createdAt} </h4>
                        </div>
                      </div>
                      <div className="col-sm-1">
                        <div className="icondiv">
                          <a
                            onClick={() => {
                              setShow(true);
                              console.log(w);
                              seteditcab(w);
                              console.log(w);
                            }}
                          >
                            <EditIcon className="svg_icons" />
                          </a>
                        </div>

                        <div className="icondiv">
                          <a
                            onClick={() => {
                              swal({
                                title: "Are you sure?",
                                text: "Want To delete Cab",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((willDelete) => {
                                if (willDelete) {
                                  pmlAPI
                                    .delete(`/api/cabpool/${w._id}`)
                                    .then((res) => {
                                      console.log(res);
                                      setforrefresh(nanoid());
                                      swal(
                                        "Success!",
                                        "Cab Deleted Succcessfully",
                                        "success"
                                      );
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                } else {
                                  swal("Cab  is safe!");
                                }
                              });
                            }}
                          >
                            <RemoveCircleOutlineIcon className="svg_icons" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <div className="col-sm-12 mainallcabdiv">
            <div className="row secondallcabdiv">
              <div className="col-sm-2">
                <img src={nodataimg} alt="user" className="imgallcabs" />
              </div>
              <div className="col-sm-5">
                <div>
                  <label className="text_inrease_lable">Car Model</label>
                  <h4 className="allcabsheading"> No Data Found</h4>
                </div>
                <div>
                  <label className="text_inrease_lable">Available Seats</label>
                  <h4> No Data Found </h4>
                </div>
                <div>
                  <label className="text_inrease_lable">Luggage Space</label>
                  <h4> No Data Found</h4>
                </div>
              </div>
              <div className="col-sm-5">
                <div>
                  <label className="text_inrease_lable">Car ID </label>
                  <h4> No Data Found</h4>
                </div>
                <div>
                  <label className="text_inrease_lable">Created At </label>
                  <h4> No Data Found </h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
