import React, { useState } from "react";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import "../components/Allcss/calendar.css";

const styles = {
  position: "absolute",
  top: "30px",
  left: "0%",
  zIndex: 11,
};

function DatePicker({ value, onChangeDatePickTo, minDate }) {
  const [show, setShow] = useState(false);
  return (
    <div style={styles}>
      {!show ? (
        <></>
      ) : (
        <Calendar
          onChange={(e) => onChangeDatePickTo(e)}
          value={value}
          minDate={minDate}
        />
      )}
    </div>
  );
}
export default DatePicker;
