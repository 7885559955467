import React, { useEffect, useCallback, useState } from "react";

import CabPoolForm from "./CabPoolForm";

// API
import pmlAPI from "../api/pmlAPI";

const CabPool = () => {
  const [cabCreation, setCabCreation] = useState(false);
  const [cabs, setCabs] = useState([]);

  const fetchCabs = useCallback(async () => {
    const cabs = await pmlAPI.get(`/api/cab`);
    console.log(cabs);
    setCabs(cabs.data);
    // TODO: Change in production;
    // setCabs(cabs.data.data);
  }, []);

  useEffect(() => {
    fetchCabs();
  }, [fetchCabs, cabCreation]);

  return (
    <div className="dashboard-cabs">
      <div className="dashboard-cabs__add-cabs">
       

       <CabPoolForm setCabCreation={setCabCreation} />
      </div>
    </div>
  );
};

export default CabPool;
