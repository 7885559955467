import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Markup } from "interweave";
const InfoCard = ({ data, type }) => {
  return (
    <div>
      <Card>
        <Card.Header>
          <ArticleOutlinedIcon className="logo" />{" "}
          <span className="heading header_gone">Read before you book!</span>
        </Card.Header>
        <Card.Body className="cardbody">
          <Card.Title className="ulpadding ulheading">
            Terms & Conditions
          </Card.Title>
          <Card.Text className="ulpadding textfontli">
            {type == "hourly" ? (
              <div
                style={{
                  marginLeft: "20px",
                }}
              >
                <Markup content={data?.hourlydata?.term_and_condition} />
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "20px",
                }}
              >
                <Markup
                  content={
                    data?.outstationdata?.term_and_condition ||
                    data?.airportdata?.term_and_condition ||
                    data?.onewaydata?.term_and_condition
                  }
                />
              </div>
            )}
          </Card.Text>
          <br></br>
          <Card.Title className="ulpadding ulheading">
            Cancellation policy
          </Card.Title>
          <Card.Text className="ulpadding textfontli">
            {type == "hourly" ? (
              <div
                style={{
                  marginLeft: "20px",
                }}
              >
                <Markup content={data?.hourlydata?.cancellation} />
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "20px",
                }}
              >
                <Markup
                  content={
                    data?.outstationdata?.cancellation ||
                    data?.airportdata?.cancellation ||
                    data?.onewaydata?.cancellation
                  }
                />
              </div>
            )}
          </Card.Text>
        </Card.Body>

        {/* <Card.Body>
          <Card.Header>
            {" "}
            <span>Important Information</span>
          </Card.Header>
          <Row>
            <Col sm={6}>
              <br></br>

              <Card.Title className="ulpadding ulheading">
                Extra kilometers
              </Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>
                    Once you exceed your kms limits, you will be charged extra
                    kms as applicable.
                  </li>
                </ul>
              </Card.Text>
              <br></br>

              <Card.Title className="ulpadding ulheading">Cab type</Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>
                    The booking will be for cab type (HATCHBACK, SUV or SEDAN)
                    and we do not commit on providing any preferred cab model.
                    In case any preferred model (like dzire only) is provided in
                    listing than it will be provided.
                  </li>
                </ul>
              </Card.Text>
              <br></br>

              <Card.Title className="ulpadding ulheading">
                Waiting and night charges
              </Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>
                    Driver shall wait for 45 minutes at pickup location. Post
                    that, your cab will be cancelled without making any refund.
                  </li>
                  <li>
                    Night Charge (applicable between 11 PM to 6 AM only):Rs 250.
                    These charges should be directly paid to the driver.
                  </li>
                </ul>
              </Card.Text>
              <br></br>

              <Card.Title className="ulpadding ulheading">
                Cab detail
              </Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>
                    Cab details will be shared up to 1 hours prior to departure.
                  </li>
                </ul>
              </Card.Text>
              <br></br>

              <Card.Title className="ulpadding ulheading">
                Hilly areas
              </Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>
                    Car air-conditioner will not be working in hilly routes &
                    also when the vehicle is not in motion.
                  </li>
                </ul>
              </Card.Text>
              <br></br>

              <Card.Title className="ulpadding ulheading">
                Other charges
              </Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>
                    In case you are carrying your pet Along INR 300 Extra
                    Charges
                  </li>
                  <li>
                    You need to pay toll tax, state tax or other similar taxes
                    directly to the driver if not mentioned in
                    Inclusions/Highlights.
                  </li>
                </ul>
              </Card.Text>
            </Col>
            <Col sm={6}>
              <br></br>

              <Card.Title className="ulpadding ulheading">
                Baggage policy
              </Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>
                    For Compact maximum sitting capacity is 4 and number of Bags
                    allowed 1.
                  </li>
                </ul>
              </Card.Text>
              <br></br>

              <Card.Title className="ulpadding ulheading">
                Additional information
              </Card.Title>
              <Card.Text className="ulpadding textfontli">
                <ul>
                  <li>Cab will be provided on the basis of availability.</li>
                  <li>
                    We do not commit on particular fuel type vehicle. It will be
                    as per mentioned in name by cab provider.
                  </li>
                  <li>
                    If a CNG refill has to be done, the turnaround time may be
                    over 30 minutes due to less availability of stations and
                    long queues.
                  </li>
                  <li>
                    Due to traffic or any other unavoidable reason, pickup may
                    be delayed by 30 mins.
                  </li>
                  <li>Distance will be calculated from point to point.</li>
                  <li>
                    You are solely responsible for managing your own travel
                    schedule. Ease My Trip will not be liable for any
                    compensation if you happen to miss your scheduled flight,
                    train or bus due to delay pickup, traffic jam or delay due
                    to any other reason during the trip.
                  </li>
                  <li>
                    In case of partial payment, the balance payment of trip
                    needs to be paid in advance at the time of pick-up.
                  </li>
                  <li>
                    You need to collect the receipts from driver for any toll
                    tax, state tax, night charges or extra km paid directly to
                    the driver during the trip. PML is not liable to provide
                    invoices for such amount.
                  </li>
                  <li>
                    Any grievances or claims related to the cab travel should be
                    reported to PML within 48 hours of travel time.
                  </li>
                </ul>
              </Card.Text>
            </Col>
          </Row>
        </Card.Body> */}
      </Card>
    </div>
  );
};

export default InfoCard;
