import "./RateModifier.css";
import React, { useState, useEffect } from "react";
// import RichTextEditor from "react-rte";
import indianCitiesDatabase from "indian-cities-database";
import pmlAPI from "../../../api/pmlAPI";
import swal from "sweetalert";
import { useParams, useNavigate } from "react-router-dom";
import { RichTextEditor } from "@mantine/rte";
import { includes } from "indian-cities-database/cities";

const RateModifier = () => {
  const params = useParams();
  const navigate = useNavigate();
  const citess = indianCitiesDatabase.cities;
  let allstate = [
    {
      abbreviation: "AN",
      name: "Andaman and Nicobar Islands",
    },
    {
      abbreviation: "AP",
      name: "Andhra Pradesh",
    },
    {
      abbreviation: "AR",
      name: "Arunachal Pradesh",
    },
    {
      abbreviation: "AS",
      name: "Assam",
    },
    {
      abbreviation: "BR",
      name: "Bihar",
    },
    {
      abbreviation: "CG",
      name: "Chandigarh",
    },
    {
      abbreviation: "CH",
      name: "Chhattisgarh",
    },
    {
      abbreviation: "DH",
      name: "Dadra and Nagar Haveli",
    },
    {
      abbreviation: "DD",
      name: "Daman and Diu",
    },
    {
      abbreviation: "DL",
      name: "Delhi",
    },
    {
      abbreviation: "GA",
      name: "Goa",
    },
    {
      abbreviation: "GJ",
      name: "Gujarat",
    },
    {
      abbreviation: "HR",
      name: "Haryana",
    },
    {
      abbreviation: "HP",
      name: "Himachal Pradesh",
    },
    {
      abbreviation: "JK",
      name: "Jammu and Kashmir",
    },
    {
      abbreviation: "JH",
      name: "Jharkhand",
    },
    {
      abbreviation: "KA",
      name: "Karnataka",
    },
    {
      abbreviation: "KL",
      name: "Kerala",
    },
    {
      abbreviation: "LD",
      name: "Lakshadweep",
    },
    {
      abbreviation: "MP",
      name: "Madhya Pradesh",
    },
    {
      abbreviation: "MH",
      name: "Maharashtra",
    },
    {
      abbreviation: "MN",
      name: "Manipur",
    },
    {
      abbreviation: "ML",
      name: "Meghalaya",
    },
    {
      abbreviation: "MZ",
      name: "Mizoram",
    },
    {
      abbreviation: "NL",
      name: "Nagaland",
    },
    {
      abbreviation: "OR",
      name: "Odisha",
    },
    {
      abbreviation: "PY",
      name: "Puducherry",
    },
    {
      abbreviation: "PB",
      name: "Punjab",
    },
    {
      abbreviation: "RJ",
      name: "Rajasthan",
    },
    {
      abbreviation: "SK",
      name: "Sikkim",
    },
    {
      abbreviation: "TN",
      name: "Tamil Nadu",
    },
    {
      abbreviation: "TS",
      name: "Telangana",
    },
    {
      abbreviation: "TR",
      name: "Tripura",
    },
    {
      abbreviation: "UP",
      name: "Uttar Pradesh",
    },
    {
      abbreviation: "UK",
      name: "Uttarakhand",
    },
    {
      abbreviation: "WB",
      name: "West Bengal",
    },
  ];

  const [checkCabStatus, setCabStatus] = useState("none");

  const [cabData, setcabData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).id,
    cabpoolId: "",
    carModel: "",
    luggage: "",
    seats: "",
    cabImage: "",
    region: "",
    city: "",
    fule_type: "",
    cab_type: "",
    outstation: false,
    outstationdata: {},
    hourly: false,
    hourlydata: {},
  });

  const [addOutstation, setAddOutstation] = useState({
    base_fare: "",
    include_km: "0",
    gst: "5",
    state_toll: "not include",
    minkm: "",
    per_km_charge: "",
    vechile_and_fuel: "not include",
    waiting_charge: {
      type: "min",
      value: "0",
    },
    night_time: {
      to: "",
      from: "",
    },
    night_charge: "",
    extra_pickup: "",
    term_and_condition: "",
    cancellation: "",
    driver_allowance: "",
  });

  const [addHourly, setAddHourly] = useState({
    two: "",
    four: "",
    six: "",
    eight: "",
    ten: "",
    tewlev: "",

    gst: "5",
    state_toll: "not include",
    per_km_charge: "",
    vechile_and_fuel: "not include",
    waiting_charge: {
      type: "min",
      value: "0",
    },
    term_and_condition: "",
    cancellation: "",
    extra_pickup: "",
  });

  const [citeies, setciteies] = useState([]);
  const [AllCabPool, setAllCabPool] = useState([]);
  const [cabName, setCabName] = useState([]);
  const [allCabName, setAllCabName] = useState([]);

  useEffect(() => {
    let data = citess?.filter((w) => {
      return w.state == cabData?.region;
    });

    if (data?.length > 0) {
      let addvalue = {
        city: "Please Select City ",
        state: "Please Select City",
      };
      data?.unshift(addvalue);
    }

    setciteies(data);
  }, [cabData]);

  useEffect(() => {
    pmlAPI
      .get(`/api/cabpool`)
      .then((res) => {
        setAllCabPool(res.data);
        let selectData = res?.data?.map((w) => {
          return {
            carName: w?.carModel,
            id: w?._id,
          };
        });

      
        setCabName(selectData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(async () => {
    try {
      let res = await pmlAPI.get("/api/cabs/");
      setAllCabName(res.data.cab);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const SelectCarModal = (id) => {
    let singleCabFormPool = AllCabPool?.filter((value) => {
      return value._id == id;
    });
    let singleData = singleCabFormPool[0];

    setcabData({
      ...cabData,
      carModel: singleData?.carModel,
      luggage: singleData?.luggage,
      seats: singleData?.seats,
      cabImage: singleData?.cabImage,
      fule_type: singleData?.fule_type,
      cab_type: singleData?.cab_type,
      cabpoolId: singleData?._id,
    });
  };

  const MainCheckBox = (e) => {
    const { name, checked } = e.target;
    setcabData({
      ...cabData,
      [name]: checked,
    });
  };

  /// Outstation ///////////////////
  const SingleFiled = (e) => {
    const { name, value } = e.target;
    setAddOutstation({
      ...addOutstation,
      [name]: value,
    });
  };

  ///Hourly
  const SingleFiledAHourly = (e) => {
    const { name, value } = e.target;
    setAddHourly({
      ...addHourly,
      [name]: value,
    });
  };

  ///// handle Sweet Alert
  const handleSweeyAlert = (title, text, icon) => {
    swal({
      title: title,
      text: text,
      icon: icon,
    });
  };

  ////Handle Submit

  const onSubmitCab = async () => {
    let id = params.id;

    let checkCabByCity = allCabName?.filter((w) => {
      return w.carModel == cabData.carModel && w.city == cabData.city;
    });

    if (cabData?.outstation == false && cabData?.hourly == false) {
      handleSweeyAlert("Error", "Please Select Hourly Or Outstation", "error");
    } else if (checkCabByCity?.length > 0 && id == undefined) {
      handleSweeyAlert(
        "Error",
        `This Cab is already added in ${cabData.city}`,
        "error"
      );
    } else {
      cabData.outstationdata = addOutstation;
      cabData.hourlydata = addHourly;

      let res = null;
      try {
        if (id) {
          res = await pmlAPI.put(`/api/cabs/${id}`, cabData);
        } else {
          res = await pmlAPI.post("/api/cabs/", cabData);
        }
        if (res.data?.success) {
          handleSweeyAlert("Success", res.data?.massage, "success");
          navigate("/dashboard/allcabs");
        } else {
          handleSweeyAlert("Error", res.data?.massage, "error");
        }

        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //handle Section
  useEffect(async () => {
    let id = params?.id;

    if (id) {
      let res = await pmlAPI.get(`/api/cabs/${id}`);

      const {
        userId,
        cabpoolId,
        carModel,
        luggage,
        seats,
        cabImage,
        region,
        city,
        fule_type,
        cab_type,
        outstation,
        outstationdata,
        hourly,
        hourlydata,
      } = res?.data?.data;
      let cabinitial = {
        userId: userId,
        cabpoolId: cabpoolId,
        carModel: carModel,
        luggage: luggage,
        seats: seats,
        cabImage: cabImage,
        region: region,
        city: city,
        fule_type: fule_type,
        cab_type: cab_type,
        outstation: outstation,
        outstationdata: {},
        hourly: hourly,
        hourlydata: {},
      };

      setCabStatus(cabpoolId);

      setcabData(cabinitial);

      setAddOutstation((prevState) => {
        return {
          ...prevState,
          base_fare: outstationdata.base_fare,
          include_km: outstationdata.include_km,
          gst: outstationdata.gst,
          state_toll: outstationdata.state_toll,
          minkm: outstationdata.minkm,
          per_km_charge: outstationdata.per_km_charge,
          vechile_and_fuel: outstationdata.vechile_and_fuel,
          waiting_charge: {
            type: outstationdata.waiting_charge.type,
            value: outstationdata.waiting_charge.value,
          },
          night_time: {
            to: outstationdata.night_time.to,
            from: outstationdata.night_time.from,
          },
          night_charge: outstationdata.night_charge,
          extra_pickup: outstationdata.extra_pickup,

          driver_allowance: outstationdata.driver_allowance,
        };
      });
      setAddHourly((prevState) => {
        return {
          ...prevState,
          two: hourlydata.two,
          four: hourlydata.four,
          six: hourlydata.six,
          eight: hourlydata.eight,
          ten: hourlydata.ten,
          tewlev: hourlydata.tewlev,

          gst: hourlydata.gst,
          state_toll: hourlydata.state_toll,
          per_km_charge: hourlydata.per_km_charge,
          vechile_and_fuel: hourlydata.vechile_and_fuel,
          waiting_charge: {
            type: hourlydata.waiting_charge.type,
            value: hourlydata.waiting_charge.value,
          },
          extra_pickup: hourlydata.extra_pickup,
        };
      });
      setAddOutstation((prevState) => {
        return {
          ...prevState,
          term_and_condition: outstationdata.term_and_condition,
        };
      });
      setAddOutstation((prevState) => {
        return {
          ...prevState,
          cancellation: outstationdata.cancellation,
        };
      });

      setAddHourly((prevState) => {
        return {
          ...prevState,
          term_and_condition: hourlydata.term_and_condition,
        };
      });
      setAddHourly((prevState) => {
        return {
          ...prevState,
          cancellation: hourlydata.cancellation,
        };
      });
    } else {
      setcabData({
        ...cabData,
        userId: JSON.parse(localStorage.getItem("userData")).id,
        cabpoolId: "",
        carModel: "",
        luggage: "",
        seats: "",
        cabImage: "",
        region: "",
        city: "",
        fule_type: "",
        cab_type: "",
        outstation: false,
        outstationdata: {},
        hourly: false,
        hourlydata: {},
      });

      setAddOutstation({
        ...addOutstation,
        base_fare: "",
        include_km: "0",
        gst: "5",
        state_toll: "not include",
        minkm: "",
        per_km_charge: "",
        vechile_and_fuel: "not include",
        waiting_charge: {
          type: "min",
          value: "0",
        },
        night_time: {
          to: "",
          from: "",
        },
        night_charge: "",
        extra_pickup: "",
        term_and_condition: "",
        cancellation: "",
        driver_allowance: "",
      });
      setAddHourly({
        ...addHourly,
        two: "",
        four: "",
        six: "",
        eight: "",
        ten: "",
        tewlev: "",

        gst: "5",
        state_toll: "not include",
        per_km_charge: "",
        vechile_and_fuel: "not include",
        waiting_charge: {
          type: "min",
          value: "0",
        },
        term_and_condition: "",
        cancellation: "",
        extra_pickup: "",
      });
    }
  }, [params]);

  return (
    <div className="modifyWrapper">
      <div className="filter_section">
        {/* Car */}
        <div>
          <label>Car</label>
          <select
            disabled={params?.id}
            value={cabData?.cabpoolId}
            onChange={(e) => {
              setCabStatus(e.target.value);
              SelectCarModal(e.target.value);
            }}
          >
            <option value="none">Select Cab</option>
            {cabName?.map((w) => {
              return (
                <option value={w.id} key={w.id}>
                  {w.carName}
                </option>
              );
            })}
          </select>
        </div>

        {/* State */}
        <div>
          <label>State</label>
          <select
            value={cabData.region}
            onChange={(e) => {
              setcabData({
                ...cabData,
                region: e.target.value,
                city: e.target.value,
              });
            }}
          >
            <option value="none">Select state</option>
            {allstate?.map((w) => {
              return (
                <option value={w.name} key={w.name}>
                  {w.name}
                </option>
              );
            })}
          </select>
        </div>

        {/* City */}
        <div>
          <label>City</label>
          <select
            value={cabData.city}
            onChange={(e) => {
              setcabData({
                ...cabData,
                city: e.target.value,
              });
            }}
          >
            {citeies?.map((w) => {
              return (
                <option value={`${w.city}  ${w.state}`} key={w.city}>
                  {w.city}
                </option>
              );
            })}
          </select>
        </div>

        {/* Button */}
        <div className="buildBtn">
          <button
            type="button"
            onClick={() => {
              if (checkCabStatus == "none") {
                handleSweeyAlert("Error", "Please Select Cab First", "error");
              } else {
                onSubmitCab();
              }
            }}
          >
            Save
          </button>
        </div>
      </div>

      <div className="allThree">
        {/* Outstation */}
        <div className="outstation_section">
          <div className="outstation_head">
            <input
              type="checkbox"
              id="outstation"
              name="outstation"
              value={cabData?.outstation}
              checked={cabData?.outstation}
              onChange={MainCheckBox}
            />
            <label for="vehicle1">Outstation</label>
          </div>
          <div className="form_box">
            <form>
              <div className="h2 head_title">One Way</div>
              <div className="base_fare">
                <label>Base Fare</label>
                <input
                  type="number"
                  id="basefare"
                  disabled={!cabData?.outstation}
                  name="base_fare"
                  value={addOutstation?.base_fare}
                  onChange={SingleFiled}
                />
              </div>

              <div className="base_fare">
                <label>GST</label>
                <div className="inner_button_gst">
                  <p className="percentage">%age</p>
                </div>
                <input
                  type="number"
                  id="gst"
                  name="gst"
                  min="0"
                  max="100"
                  disabled={!cabData?.outstation}
                  value={addOutstation?.gst}
                  onChange={SingleFiled}
                />
              </div>

              <div className="base_fare">
                <label>State, Toll Tax</label>
                <div className="inner_button">
                  <p className="flat">Not Include</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={!cabData?.outstation}
                      checked={
                        addOutstation?.state_toll == "include" ? true : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAddOutstation({
                            ...addOutstation,
                            state_toll: "include",
                          });
                        } else {
                          setAddOutstation({
                            ...addOutstation,
                            state_toll: "not include",
                          });
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="percentage"> Include</p>
                </div>
              </div>

              <div className="base_fare">
                <label>Min KMs</label>
                <input
                  type="number"
                  id="minkm"
                  name="minkm"
                  disabled={!cabData?.outstation}
                  value={addOutstation?.minkm}
                  onChange={SingleFiled}
                />
              </div>

              <div className="base_fare">
                <label>Per KM Charges(Extra)</label>
                <input
                  type="number"
                  id="kmextracharge"
                  disabled={!cabData?.outstation}
                  name="per_km_charge"
                  value={addOutstation?.per_km_charge}
                  onChange={SingleFiled}
                />
              </div>

              <div className="base_fare">
                <label>Vehicle And Fuel Charges</label>
                <div className="inner_button">
                  <p className="flat"> Not Include</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={!cabData?.outstation}
                      checked={
                        addOutstation?.vechile_and_fuel == "include"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAddOutstation({
                            ...addOutstation,
                            vechile_and_fuel: "include",
                          });
                        } else {
                          setAddOutstation({
                            ...addOutstation,
                            vechile_and_fuel: "not include",
                          });
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="percentage"> Include</p>
                </div>
              </div>

              <div className="base_fare">
                <label>Waiting Charges</label>
                <div className="inner_button">
                  <p className="flat">Min.</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={!cabData?.outstation}
                      value={
                        addOutstation?.waiting_charge.type == "hour"
                          ? true
                          : false
                      }
                      checked={
                        addOutstation?.waiting_charge.type == "hour"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAddOutstation({
                            ...addOutstation,
                            waiting_charge: {
                              ...addOutstation.waiting_charge,
                              type: "hour",
                            },
                          });
                        } else {
                          setAddOutstation({
                            ...addOutstation,
                            waiting_charge: {
                              ...addOutstation.waiting_charge,
                              type: "min",
                            },
                          });
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="percentage">Hrs.</p>
                </div>
                <input
                  type="number"
                  id="waitcharge"
                  name="waitcharge"
                  min="0"
                  max="100"
                  disabled={!cabData?.outstation}
                  value={addOutstation?.waiting_charge?.value}
                  onChange={(e) => {
                    const valueNo = e.target.value;

                    setAddOutstation({
                      ...addOutstation,
                      waiting_charge: {
                        ...addOutstation.waiting_charge,
                        value: valueNo,
                      },
                    });
                  }}
                />
              </div>

              <div className="base_fare">
                <label>Night Timing</label>
                <div className="slab">
                  <input
                    type="time"
                    id="nightcharge"
                    disabled={!cabData?.outstation}
                    name="nightcharge"
                    value={addOutstation?.night_time?.to}
                    onChange={(e) => {
                      setAddOutstation({
                        ...addOutstation,
                        night_time: {
                          ...addOutstation?.night_time,
                          to: e.target.value,
                        },
                      });
                    }}
                  />
                  <span>to</span>
                  <input
                    type="time"
                    id="nightcharge"
                    disabled={!cabData?.outstation}
                    name="nightcharge"
                    value={addOutstation?.night_time?.from}
                    onChange={(e) => {
                      setAddOutstation({
                        ...addOutstation,
                        night_time: {
                          ...addOutstation?.night_time,
                          from: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="base_fare">
                <label>Night Charge</label>
                <input
                  type="number"
                  id="night_charge"
                  disabled={!cabData?.outstation}
                  name="night_charge"
                  value={addOutstation?.night_charge}
                  onChange={SingleFiled}
                />
              </div>

              <div className="base_fare">
                <label>Extra Pick Up/Drop</label>
                <input
                  type="number"
                  disabled={!cabData?.outstation}
                  id="pickdrop"
                  name="extra_pickup"
                  value={addOutstation?.extra_pickup}
                  onChange={SingleFiled}
                />
              </div>

              <div className="base_fare">
                <label>Driver Allowance</label>
                <input
                  type="number"
                  disabled={!cabData?.outstation}
                  id="driver"
                  name="driver_allowance"
                  value={addOutstation?.driver_allowance}
                  onChange={SingleFiled}
                />
              </div>

              <div className="userUpdateItem">
                <label className="user__label">Terms & Conditions</label>
                <RichTextEditor
                  style={{
                    width: "450px",
                  }}
                  disabled={!cabData?.outstation}
                  value={addOutstation.term_and_condition}
                  controls={[
                    ["bold", "italic", "underline"],
                    ["unorderedList"],
                  ]}
                  onChange={(value) => {
                    setAddOutstation({
                      ...addOutstation,
                      term_and_condition: value,
                    });
                  }}
                />
                {/* <RichTextEditor
                  disabled={!cabData?.outstation}
                  className="desc_area"
                  value={reachTextOutstation?.term}
                  onChange={ReacttextTermOut}
                /> */}
              </div>

              <div className="userUpdateItem">
                <label className="user__label">Cancellation Policy</label>

                <RichTextEditor
                  style={{
                    width: "450px",
                  }}
                  disabled={!cabData?.outstation}
                  value={addOutstation.cancellation}
                  controls={[
                    ["bold", "italic", "underline"],
                    ["unorderedList"],
                  ]}
                  onChange={(value) => {
                    setAddOutstation({
                      ...addOutstation,
                      cancellation: value,
                    });
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        {/* hourly */}
        <div className="allThree">
          <div className="outstation_section">
            <div className="outstation_head">
              <input
                type="checkbox"
                id="outstation"
                name="hourly"
                value={cabData?.hourly}
                checked={cabData?.hourly}
                onChange={MainCheckBox}
              />
              <label for="vehicle1">Hourly</label>
            </div>

            <div className="form_box">
              <form>
                <div className="base_fare">
                  <label>2Hour/20km</label>
                  <input
                    type="number"
                    disabled={!cabData?.hourly}
                    id="two"
                    name="two"
                    value={addHourly?.two}
                    onChange={SingleFiledAHourly}
                  />
                </div>
                <div className="base_fare">
                  <label>4Hour/40km</label>
                  <input
                    type="number"
                    disabled={!cabData?.hourly}
                    id="four"
                    name="four"
                    value={addHourly?.four}
                    onChange={SingleFiledAHourly}
                  />
                </div>
                <div className="base_fare">
                  <label>6Hour/60km</label>
                  <input
                    type="number"
                    disabled={!cabData?.hourly}
                    id="six"
                    name="six"
                    value={addHourly?.six}
                    onChange={SingleFiledAHourly}
                  />
                </div>
                <div className="base_fare">
                  <label>8Hour/80km</label>
                  <input
                    type="number"
                    disabled={!cabData?.hourly}
                    id="eight"
                    name="eight"
                    value={addHourly?.eight}
                    onChange={SingleFiledAHourly}
                  />
                </div>
                <div className="base_fare">
                  <label>10Hour/100km</label>
                  <input
                    type="number"
                    disabled={!cabData?.hourly}
                    id="ten"
                    name="ten"
                    value={addHourly?.ten}
                    onChange={SingleFiledAHourly}
                  />
                </div>
                <div className="base_fare">
                  <label>12Hour/120km</label>
                  <input
                    type="number"
                    disabled={!cabData?.hourly}
                    id="tewlev"
                    name="tewlev"
                    value={addHourly?.tewlev}
                    onChange={SingleFiledAHourly}
                  />
                </div>

                <div className="base_fare">
                  <label>GST</label>
                  <div className="inner_button_gst">
                    <p className="percentage">% age</p>
                  </div>
                  <input
                    type="number"
                    id="gst"
                    disabled={!cabData?.hourly}
                    name="gst"
                    min="0"
                    max="100"
                    value={addHourly?.gst}
                    onChange={SingleFiledAHourly}
                  />
                </div>

                <div className="base_fare">
                  <label>State, Toll Tax</label>
                  <div className="inner_button">
                    <p className="flat">Not Include</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={!cabData?.hourly}
                        checked={
                          addHourly?.state_toll == "include" ? true : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAddHourly({
                              ...addHourly,
                              state_toll: "include",
                            });
                          } else {
                            setAddHourly({
                              ...addHourly,
                              state_toll: "not include",
                            });
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <p className="percentage">Include</p>
                  </div>
                </div>

                <div className="base_fare">
                  <label>Per KM Charges(Extra)</label>
                  <input
                    type="number"
                    id="per_km_charge"
                    disabled={!cabData?.hourly}
                    name="per_km_charge"
                    value={addHourly?.per_km_charge}
                    onChange={SingleFiledAHourly}
                  />
                </div>

                <div className="base_fare">
                  <label>Vehicle And Fuel Charges</label>
                  <div className="inner_button">
                    <p className="flat">Not Include</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={!cabData?.hourly}
                        checked={
                          addHourly?.vechile_and_fuel == "include"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAddHourly({
                              ...addHourly,
                              vechile_and_fuel: "include",
                            });
                          } else {
                            setAddHourly({
                              ...addHourly,
                              vechile_and_fuel: "not include",
                            });
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <p className="percentage">Include</p>
                  </div>
                </div>

                <div className="base_fare">
                  <label>Waiting Charges</label>
                  <div className="inner_button">
                    <p className="flat">Min.</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={!cabData?.hourly}
                        value={
                          addHourly?.waiting_charge.type == "hour"
                            ? true
                            : false
                        }
                        checked={
                          addHourly?.waiting_charge.type == "hour"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAddHourly({
                              ...addHourly,
                              waiting_charge: {
                                ...addHourly.waiting_charge,
                                type: "hour",
                              },
                            });
                          } else {
                            setAddHourly({
                              ...addHourly,
                              waiting_charge: {
                                ...addHourly.waiting_charge,
                                type: "min",
                              },
                            });
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <p className="percentage">Hrs.</p>
                  </div>
                  <input
                    type="number"
                    id="waitcharge"
                    name="waitcharge"
                    disabled={!cabData?.hourly}
                    min="0"
                    max="100"
                    value={addHourly?.waiting_charge?.value}
                    onChange={(e) => {
                      const valueNo = e.target.value;

                      setAddHourly({
                        ...addHourly,
                        waiting_charge: {
                          ...addHourly.waiting_charge,
                          value: valueNo,
                        },
                      });
                    }}
                  />
                </div>

                <div className="base_fare">
                  <label>Extra Pick Up/Drop</label>
                  <input
                    type="number"
                    disabled={!cabData?.hourly}
                    id="extra_pickup"
                    name="extra_pickup"
                    value={addHourly?.extra_pickup}
                    onChange={SingleFiledAHourly}
                  />
                </div>

                <div className="userUpdateItem">
                  <label className="user__label">Terms & Conditions</label>

                  <RichTextEditor
                    style={{
                      width: "450px",
                    }}
                    disabled={!cabData?.hourly}
                    value={addHourly.term_and_condition}
                    controls={[
                      ["bold", "italic", "underline"],
                      ["unorderedList"],
                    ]}
                    onChange={(value) => {
                      setAddHourly({
                        ...addHourly,
                        term_and_condition: value,
                      });
                    }}
                  />
                </div>

                <div className="userUpdateItem">
                  <label className="user__label">Cancellation Policy</label>

                  <RichTextEditor
                    style={{
                      width: "450px",
                    }}
                    disabled={!cabData?.hourly}
                    value={addHourly.cancellation}
                    controls={[
                      ["bold", "italic", "underline"],
                      ["unorderedList"],
                    ]}
                    onChange={(value) => {
                      setAddHourly({
                        ...addHourly,
                        cancellation: value,
                      });
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateModifier;
